import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { setDataListLimit } from 'logic/actions/settingsActions';

const CHOICES = [
  10,
  20,
  50,
];

const DataListLimitSelector = () => {
  const { t } = useTranslation('dataListLimitSelector');
  const { dataListLimit } = useSelector(({ persistent: { settingsReducer } = {} }) => (settingsReducer));
  const dispatch = useDispatch();
  const doSetDataListLimit = useCallback((limit) => dispatch(setDataListLimit(limit)), [dispatch]);

  return (
    <DropdownButton
      title={t('limit', { limit: dataListLimit })}
      variant="outline-primary"
      size="sm"
      onSelect={doSetDataListLimit}
    >
      {CHOICES.map((limit) => (<Dropdown.Item key={limit} eventKey={limit}>{limit}</Dropdown.Item>))}
    </DropdownButton>
  );
};

export default DataListLimitSelector;
