import { createReducer } from '@reduxjs/toolkit';
import { saveAs } from 'file-saver';
import {
  success,
  shouldResetState,
} from 'logic/reducers/helpers';
import {
  GET_TEMPLATES,
  EXPORT_TEMPLATES,
  GET_TEMPLATE,
  DISABLE_TEMPLATE,
  GET_TEMPLATE_TRANSACTIONS,
} from 'logic/actions/templatesActions';

const initialState = {
  pages: [],
  nbPages: 1,
  templates: [],
};

const handleGetTemplates = (
  state,
  {
    payload: {
      data: {
        page = 0,
        nbPages = 1,
        items = [],
      } = {},
    } = {},
  },
) => {
  if (page) {
    state.pages[page] = items;
  }
  state.nbPages = nbPages;
};

const handleExportTemplates = (
  state,
  { payload: { data } = {} },
) => {
  saveAs(data, 'templates.csv');
};

const handleGetTemplate = (
  { templates },
  {
    payload: { data = {} } = {},
    meta: { previousAction: { templateId = 0 } = {} } = {},
  },
) => {
  if (templateId) {
    templates[templateId] = {
      ...templates[templateId], ...data,
    };
  }
};

const handleGetTemplateTransactions = (
  { templates },
  {
    payload: { data: transactions = {} } = {},
    meta: { previousAction: { templateId = 0 } = {} } = {},
  },
) => {
  if (templateId) {
    if (!templates[templateId]) {
      templates[templateId] = {};
    }
    templates[templateId].transactions = transactions;
  }
};

export default createReducer(initialState, {
  [success(GET_TEMPLATES)]: handleGetTemplates,
  [success(EXPORT_TEMPLATES)]: handleExportTemplates,
  [success(GET_TEMPLATE)]: handleGetTemplate,
  [success(DISABLE_TEMPLATE)]: handleGetTemplate,
  [success(GET_TEMPLATE_TRANSACTIONS)]: handleGetTemplateTransactions,
}, [{
  matcher: (action) => (shouldResetState(action)),
  reducer: () => (initialState),
}]);
