import React, {
  useEffect,
  useState,
} from 'react';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import {
  getUsersStats,
  getFeesStats,
  getAmountsStats,
  getTransactionsStats,
} from 'logic/actions/statsActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import DashboardGraph from 'components/DashboardGraph';
import DateIntervalSelector from 'components/DateIntervalSelector';
import {
  tomorrow,
  oneWeekAgo,
} from 'helpers/dates';

const Home = ({
  usersStats = {},
  feesStats = {},
  amountsStats = {},
  transactionsStats = {},
  getUsersStats,
  getFeesStats,
  getAmountsStats,
  getTransactionsStats,
}) => {
  const { t } = useTranslation('home');

  const [usersStatsLoading, setUsersStatsLoading] = useState(isEmpty(usersStats));
  const [feesStatsLoading, setFeesStatsLoading] = useState(isEmpty(feesStats));
  const [amoutsStatsLoading, setAmountsStatsLoading] = useState(isEmpty(amountsStats));
  const [transactionsStatsLoading, setTransactionsStatsLoading] = useState(isEmpty(transactionsStats));
  const [datesInterval, setDatesInterval] = useState({
    dateFrom: oneWeekAgo,
    dateTo: tomorrow,
  });

  const formatter = new Intl.NumberFormat('fr', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  useEffect(() => {
    setUsersStatsLoading(true);
    setFeesStatsLoading(true);
    setAmountsStatsLoading(true);
    setTransactionsStatsLoading(true);
    getUsersStats(datesInterval).finally(() => { setUsersStatsLoading(false); });
    getFeesStats(datesInterval).finally(() => { setFeesStatsLoading(false); });
    getAmountsStats(datesInterval).finally(() => { setAmountsStatsLoading(false); });
    getTransactionsStats(datesInterval).finally(() => { setTransactionsStatsLoading(false); });
  }, [
    datesInterval,
    getAmountsStats,
    getFeesStats,
    getTransactionsStats,
    getUsersStats,
  ]);

  return (
    <div className="d-flex flex-column mw-100">
      <DateIntervalSelector onSelect={setDatesInterval} />
      <div className="d-flex flex-column align-items-center">
        <DashboardGraph
          label={t('signups.title')}
          datesInterval={datesInterval}
          loading={usersStatsLoading}
          stats={usersStats}
          renderNames={(key) => (t(`signups.${key}`))}
        />

        <DashboardGraph
          label={t('fees.title')}
          datesInterval={datesInterval}
          loading={feesStatsLoading}
          stats={feesStats}
          renderValues={(total) => (formatter.format(total / 100))}
        />

        <DashboardGraph
          label={t('volume.title')}
          datesInterval={datesInterval}
          loading={amoutsStatsLoading}
          stats={amountsStats}
          renderValues={(total) => (formatter.format(total / 100))}
        />

        <DashboardGraph
          label={t('transactions.title')}
          datesInterval={datesInterval}
          loading={transactionsStatsLoading}
          stats={transactionsStats}
          renderNames={(key) => (t(`transactions.${key}`))}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({ ...state.persistent.statsReducer });

const mapDispatchToProps = (dispatch) => ({
  getUsersStats: bindActionCreators(getUsersStats, dispatch),
  getFeesStats: bindActionCreators(getFeesStats, dispatch),
  getAmountsStats: bindActionCreators(getAmountsStats, dispatch),
  getTransactionsStats: bindActionCreators(getTransactionsStats, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Home);
