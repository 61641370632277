import omitBy from 'lodash/omitBy';

/* eslint-disable import/no-unused-modules */
export const GET_USERS = 'app/users/GET_USERS';
export const EXPORT_USERS = 'app/users/EXPORT_USERS';
export const GET_USER = 'app/users/GET_USER';
export const UPDATE_USER = 'app/users/UPDATE_USER';
export const UPDATE_USER_ADDRESS = 'app/users/UPDATE_USER_ADDRESS';
export const CHECK_EMAIL = 'app/users/CHECK_EMAIL';
export const BLOCK_USER = 'app/users/BLOCK_USER';
export const UNBLOCK_USER = 'app/users/UNBLOCK_USER';
export const TAG_SUSPICIOUS_USER = 'app/users/TAG_SUSPICIOUS_USER';
export const UNTAG_SUSPICIOUS_USER = 'app/users/UNTAG_SUSPICIOUS_USER';
export const DELETE_USER = 'app/users/DELETE_USER';
export const GET_USER_WALLETS = 'app/users/GET_USER_WALLETS';
export const GET_USER_TRANSACTIONS = 'app/users/GET_USER_TRANSACTIONS';
export const UPLOAD_KYC = 'app/users/UPLOAD_KYC';
export const ADD_BANK_ACCOUNT = 'app/users/ADD_BANK_ACCOUNT';
export const ADD_WALLET = 'app/users/ADD_WALLET';
export const CASHOUT_WALLET = 'app/users/CASHOUT_WALLET';
export const DELETE_CARD = 'app/users/DELETE_CARD';
export const DELETE_BANK_ACCOUNT = 'app/users/DELETE_BANK_ACCOUNT';
/* eslint-enable import/no-unused-modules */

export const getUsers = ({
  page = 1, limit = 10, ...params
} = {}) => ({
  type: GET_USERS,
  payload: {
    request: {
      method: 'GET',
      url: '/api/dashboard/users/',
      params: {
        page,
        limit,
        ...params,
      },
    },
  },
});

export const exportUsers = (params, columns) => ({
  type: EXPORT_USERS,
  payload: {
    request: {
      method: 'GET',
      url: '/api/dashboard/users/export/',
      params: {
        ...params,
        columns: Object.keys(omitBy(columns, (col) => (!col))),
      },
      responseType: 'blob',
      timeout: 0,
    },
  },
});

export const getUser = (userId) => ({
  type: GET_USER,
  userId,
  payload: {
    request: {
      method: 'GET',
      url: `/api/dashboard/users/${userId}/`,
    },
  },
});

export const updateUser = (userId, data) => ({
  type: UPDATE_USER,
  userId,
  payload: {
    request: {
      method: 'PATCH',
      url: `/api/dashboard/users/${userId}/`,
      data,
    },
  },
});

export const updateUserAddress = (userId, data) => ({
  type: UPDATE_USER_ADDRESS,
  userId,
  payload: {
    request: {
      method: 'PATCH',
      url: `/api/dashboard/users/${userId}/address/`,
      data,
    },
  },
});

export const checkEmail = (email) => ({
  type: CHECK_EMAIL,
  payload: {
    request: {
      method: 'POST',
      url: '/api/dashboard/users/check/email/',
      data: { email },
    },
  },
});

export const blockUser = (userId) => ({
  type: BLOCK_USER,
  userId,
  payload: {
    request: {
      method: 'PATCH',
      url: `/api/dashboard/users/${userId}/block/`,
    },
  },
});

export const unblockUser = (userId) => ({
  type: UNBLOCK_USER,
  userId,
  payload: {
    request: {
      method: 'PATCH',
      url: `/api/dashboard/users/${userId}/unblock/`,
    },
  },
});

export const tagSuspiciousUser = (userId) => ({
  type: TAG_SUSPICIOUS_USER,
  userId,
  payload: {
    request: {
      method: 'PATCH',
      url: `/api/dashboard/users/${userId}/tag-suspicious/`,
    },
  },
});

export const untagSuspiciousUser = (userId) => ({
  type: UNTAG_SUSPICIOUS_USER,
  userId,
  payload: {
    request: {
      method: 'PATCH',
      url: `/api/dashboard/users/${userId}/untag-suspicious/`,
    },
  },
});

export const deleteUser = (userId) => ({
  type: DELETE_USER,
  userId,
  payload: {
    request: {
      method: 'DELETE',
      url: `/api/dashboard/users/${userId}/`,
    },
  },
});

export const getUserWallets = (userId) => ({
  type: GET_USER_WALLETS,
  userId,
  payload: {
    request: {
      method: 'GET',
      url: `/api/dashboard/users/${userId}/wallets/`,
    },
  },
});

export const getUserTransactions = (userId) => ({
  type: GET_USER_TRANSACTIONS,
  userId,
  payload: {
    request: {
      method: 'GET',
      url: `/api/dashboard/users/${userId}/transactions/`,
    },
  },
});

export const uploadKyc = (userId, { documentType, pages }) => (dispatch) => {
  const data = new FormData();

  data.append('type', documentType);
  pages.forEach((page) => {
    data.append('pages[]', page);
  });

  return dispatch({
    type: UPLOAD_KYC,
    userId,
    payload: {
      request: {
        method: 'POST',
        url: `/api/dashboard/users/${userId}/kyc-documents/`,
        data,
      },
    },
  });
};

export const addBankAccount = (userId, data) => ({
  type: ADD_BANK_ACCOUNT,
  userId,
  payload: {
    request: {
      method: 'POST',
      url: `/api/dashboard/users/${userId}/bank-accounts/`,
      data,
    },
  },
});

export const addWallet = (userId, currency) => ({
  type: ADD_WALLET,
  userId,
  payload: {
    request: {
      method: 'POST',
      url: `/api/dashboard/users/${userId}/wallets/`,
      data: { currency },
    },
  },
});

export const cashoutWallet = (userId, data) => ({
  type: CASHOUT_WALLET,
  userId,
  payload: {
    request: {
      method: 'PATCH',
      url: `/api/dashboard/users/${userId}/cashout/`,
      data,
    },
  },
});

export const deleteCard = (userId, cardId) => ({
  type: DELETE_CARD,
  userId,
  payload: {
    request: {
      method: 'DELETE',
      url: `/api/dashboard/users/${userId}/payment-cards/${cardId}/`,
    },
  },
});

export const deleteBankAccount = (userId, bankAccountId) => ({
  type: DELETE_BANK_ACCOUNT,
  userId,
  payload: {
    request: {
      method: 'DELETE',
      url: `/api/dashboard/users/${userId}/bank-accounts/${bankAccountId}/`,
    },
  },
});
