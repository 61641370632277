import React, {
  useState,
  useEffect, useCallback, useMemo,
} from 'react';
import {
  FormattedDate,
  FormattedNumber,
} from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom';
import { saveAs } from 'file-saver';
import {
  LEMONWAY_CHF,
  LEMONWAY_EUR,
  MANGOPAY,
  PREREGISTRATION,
} from 'helpers/constants';
import {
  isApi,
  mangoPayBaseUrl,
  lemonwayEurBaseUrl,
  lemonwayChfBaseUrl,
} from 'helpers/env';
import {
  DataCard,
  DataCardLine,
} from 'components/DataCard';
import Button from 'components/Button';
import ButtonWithConfirmationModal from 'components/ButtonWithConfirmationModal';
import {
  getTransaction,
  cancelTransaction,
  setDeliveryAddress,
  setReturnAddress,
  generateShippingLabel,
} from 'logic/actions/transactionsActions';
import { fetchMediaFile } from 'logic/actions/mediaActions';
import PauseTransactionComponent from 'components/PauseTransactionComponent';
import ForceTransactionByAdminComponent from 'components/ForceTransactionByAdminComponent';
import EditAddressModal from 'components/EditAddressModal';

const TransactionDetails = ({
  isTripartie,
  isSuperAdmin,
  isSupport,
  transactions,
  getTransaction,
  cancelTransaction,
  setDeliveryAddress,
  setReturnAddress,
  generateShippingLabel,
  fetchMediaFile,
  match: { params: { transactionId } = {} } = {},
  history,
  canSeeTransactionsTab,
}) => {
  useEffect(() => {
    if (!canSeeTransactionsTab) {
      history.push('/');
    }
  }, [canSeeTransactionsTab, history]);

  const { t } = useTranslation([
    'transactionDetails',
    '_transactions',
    '_templates',
    '_payments',
    '_shippings',
    '_psp',
  ]);
  const transaction = transactions[transactionId]?.id ? transactions[transactionId] : { id: transactionId };
  const [isLoading, setLoading] = useState(!transaction.title);
  const [refetch, setRefetch] = useState(true);

  const [showEditDeliveryAddressModal, setShowEditDeliveryAddressModal] = useState(false);
  const [showEditSenderAddressModal, setShowEditSenderAddressModal] = useState(false);

  const refetchTransaction = useCallback(() => setRefetch(true), [setRefetch]);

  const {
    id,
    title,
    description,
    templateId,
    flow,
    deliveryMethod,
    createdAt,
    currency = 'EUR',
    pspSlug,
    subTotal = 0,
    initialSubTotal = 0,
    totalFees = 0,
    buyerFees = 0,
    sellerFees = 0,
    buyerTotal = 0,
    sellerTotal = 0,
    shippingFees = 0,
    lastEventSlug,
    lastEventCreatedAt,
    deliveryAddress = {},
    returnDeliveryAddress: senderAddress = {},
    canBeCanceled,
    isPaused,
    buyer: {
      id: buyerId,
      accountStatus: buyerAccountStatus,
      userProfile: {
        firstName: buyerFirstName,
        lastName: buyerLastName,
      } = {},
      email: buyerEmail,
      formattedPhoneNumber: buyerFormattedPhoneNumber,
      averageReviewScore: buyerAverageReviewScore,
      nbTransactionsAsBuyer: buyerNbTransactionsAsBuyer,
      onGoingPurchaseCount: buyerOnGoingPurchaseCount,
      nbTransactionsAsSeller: buyerNbTransactionsAsSeller,
      onGoingSellCount: buyerOnGoingSellCount,
      successfulSellCount: buyerSuccessfulSellCount,
      cancelledSellCount: buyerCancelledSellCount,
      disputedSellCount: buyerDisputedSellCount,
      successfulPurchaseCount: buyerSuccessfulPurchaseCount,
      cancelledPurchaseCount: buyerCancelledPurchaseCount,
      disputedPurchaseCount: buyerDisputedPurchaseCount,
    } = {},
    seller: {
      id: sellerId,
      accountStatus: sellerAccountStatus,
      userProfile: {
        firstName: sellerFirstName,
        lastName: sellerLastName,
      } = {},
      email: sellerEmail,
      formattedPhoneNumber: sellerFormattedPhoneNumber,
      averageReviewScore: sellerAverageReviewScore,
      nbTransactionsAsBuyer: sellerNbTransactionsAsBuyer,
      onGoingPurchaseCount: sellerOnGoingPurchaseCount,
      nbTransactionsAsSeller: sellerNbTransactionsAsSeller,
      onGoingSellCount: sellerOnGoingSellCount,
      successfulSellCount: sellerSuccessfulSellCount,
      cancelledSellCount: sellerCancelledSellCount,
      disputedSellCount: sellerDisputedSellCount,
      successfulPurchaseCount: sellerSuccessfulPurchaseCount,
      cancelledPurchaseCount: sellerCancelledPurchaseCount,
      disputedPurchaseCount: sellerDisputedPurchaseCount,
    } = {},
    payment: {
      status: paymentStatus,
      paymentMethodType,
      cardProvider,
      cardNumber,
      cardExpirationDate,
      iban,
      pspEntityId: paymentPspEntityId,
    } = {},
    shipping: {
      status: shippingStatus,
      trackingNumber,
      trackingId,
      shippingProvider,
      labelGeneration,
      labelId,
    } = {},
    buyerReview: {
      score: buyerReviewScore,
      comment: buyerReviewComment,
    } = {},
    sellerReview: {
      score: sellerReviewScore,
      comment: sellerReviewComment,
    } = {},
    events = [],
    dispute: {
      id: disputeId,
      status: disputeStatus,
    } = {},
  } = transaction;

  useEffect(() => {
    if (refetch) {
      setLoading(true);
      getTransaction(id)
        .finally(() => {
          setLoading(false);
          setRefetch(false);
        });
    }
  }, [refetch, getTransaction, id]);

  const pspBaseUrl = useMemo(() => {
    if (pspSlug === MANGOPAY) {
      return mangoPayBaseUrl;
    }
    if (pspSlug === LEMONWAY_EUR) {
      return lemonwayEurBaseUrl;
    }
    if (pspSlug === LEMONWAY_CHF) {
      return lemonwayChfBaseUrl;
    }
    return '';
  }, [pspSlug]);

  const canSeePspEntityIds = isApi ? isSuperAdmin : isTripartie && isSupport;
  const buyerIsRegistered = buyerAccountStatus !== PREREGISTRATION;
  const sellerIsRegistered = sellerAccountStatus !== PREREGISTRATION;

  const downloadLabel = useCallback((labelId) => {
    fetchMediaFile(labelId).then(({ payload: { data: media } = {} }) => {
      saveAs(media, `Shipping label #${id}`);
    });
  }, [
    fetchMediaFile,
    id,
  ]);

  let mangoPaymentUrl;
  switch (paymentMethodType) {
    case 'card':
      if (['preauthorized', 'preauth_canceled'].includes(paymentStatus)) {
        mangoPaymentUrl = `${mangoPayBaseUrl}/PreAuthorizations/${paymentPspEntityId}`;
      } else {
        mangoPaymentUrl = `${mangoPayBaseUrl}/PayIn/${paymentPspEntityId}`;
      }
      break;

    case 'wallet':
      mangoPaymentUrl = `${mangoPayBaseUrl}/Transfer/${paymentPspEntityId}`;
      break;

    default:
      mangoPaymentUrl = `${mangoPayBaseUrl}/PayIn/${paymentPspEntityId}`;
  }

  return (
    <div className="d-flex flex-column align-items-center mt-3">
      {isLoading && (<Spinner as="span" animation="border" />)}

      <EditAddressModal
        address={deliveryAddress}
        show={showEditDeliveryAddressModal}
        showPhoneNumberField
        onHide={() => { setShowEditDeliveryAddressModal(false); }}
        onSubmit={(data) => (
          setDeliveryAddress(id, data)
            .then(() => {
              setShowEditDeliveryAddressModal(false);
            })
            .catch(() => {
              toast.error(t('errors.submitError'));
            })
        )}
      />

      <EditAddressModal
        address={senderAddress}
        show={showEditSenderAddressModal}
        onHide={() => { setShowEditSenderAddressModal(false); }}
        onSubmit={(data) => (
          setReturnAddress(id, data)
            .then(() => {
              setShowEditSenderAddressModal(false);
            })
            .catch(() => {
              toast.error(t('errors.submitError'));
            })
        )}
      />

      {isSupport && (
        <DataCard title={t('actions.cardTitle')}>
          {isSuperAdmin && lastEventSlug
            && (
            <ForceTransactionByAdminComponent
              transactionId={id}
              lastEventSlug={lastEventSlug}
              disputeStatus={disputeStatus}
              disputeId={disputeId}
            />
            )}

          {!disputeStatus && (
            <div className="d-flex">
              {isTripartie && (
                <PauseTransactionComponent
                  transactionId={id}
                  isPaused={isPaused}
                  title={title}
                  refetchTransaction={refetchTransaction}
                />
              )}
              <ButtonWithConfirmationModal
                variant="outline-danger"
                disabled={!canBeCanceled}
                onConfirm={() => (cancelTransaction(id))}
              >
                {t('actions.cancel')}
              </ButtonWithConfirmationModal>
            </div>
          )}
        </DataCard>
      )}

      <DataCard
        title={t('main.cardTitle')}
        headerButtons={templateId && (
          <Link
            to={`/templates/${templateId}`}
          >
            {t('main.seeTemplate')}
          </Link>
        )}
      >
        <DataCardLine title={t('main.id')}>{id}</DataCardLine>
        <DataCardLine title={t('main.title')}>{title}</DataCardLine>
        <DataCardLine title={t('main.description')}>{description}</DataCardLine>
        <DataCardLine title={t('main.flow')}>{t(`_templates:flow.${flow}`)}</DataCardLine>
        <DataCardLine title={t('main.deliveryMethod')}>{t(`_transactions:deliveryMethod.${deliveryMethod}`)}</DataCardLine>
        <DataCardLine title={t('main.createdOn')}>{createdAt && <FormattedDate value={new Date(createdAt)} dateStyle="short" timeStyle="long" />}</DataCardLine>
        <DataCardLine title={t('main.subTotal')}><FormattedNumber style="currency" value={subTotal / 100} currency={currency} /></DataCardLine>
        <DataCardLine title={t('main.initialSubTotal')}><FormattedNumber style="currency" value={initialSubTotal / 100} currency={currency} /></DataCardLine>
        <DataCardLine title={t('main.totalFees')}><FormattedNumber style="currency" value={totalFees / 100} currency={currency} /></DataCardLine>
        <DataCardLine title={t('main.buyerFees')}><FormattedNumber style="currency" value={buyerFees / 100} currency={currency} /></DataCardLine>
        <DataCardLine title={t('main.sellerFees')}><FormattedNumber style="currency" value={sellerFees / 100} currency={currency} /></DataCardLine>
        <DataCardLine title={t('main.shippingFees')}><FormattedNumber style="currency" value={shippingFees / 100} currency={currency} /></DataCardLine>
        <DataCardLine title={t('main.buyerTotal')}><FormattedNumber style="currency" value={buyerTotal / 100} currency={currency} /></DataCardLine>
        <DataCardLine title={t('main.sellerTotal')}><FormattedNumber style="currency" value={sellerTotal / 100} currency={currency} /></DataCardLine>
        <DataCardLine title={t('main.lastEvent')}>
          {t(`_transactions:status.${lastEventSlug}`)}
          {' '}
          {lastEventCreatedAt && (
          <span>
            (
            <FormattedDate value={new Date(lastEventCreatedAt)} dateStyle="short" timeStyle="long" />
            )
          </span>
          )}
        </DataCardLine>
        {canSeePspEntityIds && (
          <DataCardLine title={t('main.psp')} noTooltip>
            <a
              href={pspBaseUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t(`_psp:${pspSlug}`)}
            </a>
          </DataCardLine>
        )}
      </DataCard>

      <DataCard
        title={t('buyer.cardTitle')}
        headerButtons={buyerIsRegistered && (
          <Link
            to={`/users/${buyerId}`}
          >
            {t('buyer.seeProfile')}
          </Link>
        )}
      >
        <DataCardLine title={t('buyer.id')}>{buyerId}</DataCardLine>
        <DataCardLine title={t('buyer.firstName')}>{buyerFirstName}</DataCardLine>
        <DataCardLine title={t('buyer.lastName')}>{buyerLastName}</DataCardLine>
        <DataCardLine title={t('buyer.email')}>{buyerEmail}</DataCardLine>
        <DataCardLine title={t('buyer.formattedPhoneNumber')}>{buyerFormattedPhoneNumber}</DataCardLine>
        <DataCardLine title={t('buyer.averageReviewScore')}>
          <FormattedNumber value={buyerAverageReviewScore} maximumFractionDigits={2} />
          {' '}
          / 5
        </DataCardLine>
        <DataCardLine title={t('buyer.nbTransactionsAsBuyer')}>{buyerNbTransactionsAsBuyer}</DataCardLine>
        <DataCardLine title={t('buyer.buyerOnGoingPurchaseCount')}>{buyerOnGoingPurchaseCount}</DataCardLine>
        <DataCardLine title={t('buyer.successfulPurchaseCount')}>{buyerSuccessfulPurchaseCount}</DataCardLine>
        <DataCardLine title={t('buyer.cancelledPurchaseCount')}>{buyerCancelledPurchaseCount}</DataCardLine>
        <DataCardLine title={t('buyer.disputedPurchaseCount')}>{buyerDisputedPurchaseCount}</DataCardLine>
        <DataCardLine title={t('buyer.nbTransactionsAsSeller')}>{buyerNbTransactionsAsSeller}</DataCardLine>
        <DataCardLine title={t('buyer.buyerOnGoingSellCount')}>{buyerOnGoingSellCount}</DataCardLine>
        <DataCardLine title={t('buyer.successfulSellCount')}>{buyerSuccessfulSellCount}</DataCardLine>
        <DataCardLine title={t('buyer.cancelledSellCount')}>{buyerCancelledSellCount}</DataCardLine>
        <DataCardLine title={t('buyer.disputedSellCount')}>{buyerDisputedSellCount}</DataCardLine>
      </DataCard>

      <DataCard
        title={t('seller.cardTitle')}
        headerButtons={sellerIsRegistered && (
          <Link
            to={`/users/${sellerId}`}
          >
            {t('seller.seeProfile')}
          </Link>
        )}
      >
        <DataCardLine title={t('seller.id')}>{sellerId}</DataCardLine>
        <DataCardLine title={t('seller.firstName')}>{sellerFirstName}</DataCardLine>
        <DataCardLine title={t('seller.lastName')}>{sellerLastName}</DataCardLine>
        <DataCardLine title={t('seller.email')}>{sellerEmail}</DataCardLine>
        <DataCardLine title={t('seller.formattedPhoneNumber')}>{sellerFormattedPhoneNumber}</DataCardLine>
        <DataCardLine title={t('seller.averageReviewScore')}>
          <FormattedNumber value={sellerAverageReviewScore} maximumFractionDigits={2} />
          {' '}
          / 5
        </DataCardLine>
        <DataCardLine title={t('seller.nbTransactionsAsBuyer')}>{sellerNbTransactionsAsBuyer}</DataCardLine>
        <DataCardLine title={t('seller.buyerOnGoingPurchaseCount')}>{sellerOnGoingPurchaseCount}</DataCardLine>
        <DataCardLine title={t('seller.successfulPurchaseCount')}>{sellerSuccessfulPurchaseCount}</DataCardLine>
        <DataCardLine title={t('seller.cancelledPurchaseCount')}>{sellerCancelledPurchaseCount}</DataCardLine>
        <DataCardLine title={t('seller.disputedPurchaseCount')}>{sellerDisputedPurchaseCount}</DataCardLine>
        <DataCardLine title={t('seller.nbTransactionsAsSeller')}>{sellerNbTransactionsAsSeller}</DataCardLine>
        <DataCardLine title={t('seller.buyerOnGoingSellCount')}>{sellerOnGoingSellCount}</DataCardLine>
        <DataCardLine title={t('seller.successfulSellCount')}>{sellerSuccessfulSellCount}</DataCardLine>
        <DataCardLine title={t('seller.cancelledSellCount')}>{sellerCancelledSellCount}</DataCardLine>
        <DataCardLine title={t('seller.disputedSellCount')}>{sellerDisputedSellCount}</DataCardLine>
      </DataCard>

      <DataCard title={t('payment.cardTitle')}>
        <DataCardLine title={t('payment.status')}>{t(`_payments:status.${paymentStatus}`)}</DataCardLine>
        <DataCardLine title={t('payment.paymentMethodType')}>{t(`_payments:paymentMethodType.${paymentMethodType}`)}</DataCardLine>
        {cardProvider && (
          <DataCardLine title={t('payment.cardProvider')}>{cardProvider}</DataCardLine>
        )}
        {cardNumber && (
          <DataCardLine title={t('payment.obfuscatedNumber')}>{cardNumber}</DataCardLine>
        )}
        {cardExpirationDate && (
          <DataCardLine title={t('payment.expirationDate')}>{`${cardExpirationDate.replace(/([0-9]{2})/, '$1/')}`}</DataCardLine>
        )}
        {iban && (
          <DataCardLine title={t('payment.iban')}>{iban}</DataCardLine>
        )}
        {canSeePspEntityIds && pspSlug === MANGOPAY && (
          <DataCardLine title={t('payment.mangoId')} noTooltip>
            <a href={mangoPaymentUrl} target="_blank" rel="noopener noreferrer">{paymentPspEntityId}</a>
          </DataCardLine>
        )}
      </DataCard>

      {deliveryAddress?.formattedAddress && (
        <DataCard title={t('shipping.cardTitle')}>
          <DataCardLine
            title={t('shipping.deliveryAddress')}
            onEdit={() => {
              setShowEditDeliveryAddressModal(true);
            }}
          >
            {deliveryAddress?.formattedAddress}
          </DataCardLine>
          <DataCardLine
            title={t('shipping.senderAddress')}
            onEdit={() => {
              setShowEditSenderAddressModal(true);
            }}
          >
            {senderAddress?.formattedAddress || t('shipping.nc')}
          </DataCardLine>
          <DataCardLine title={t('shipping.status')}>{t(`_shippings:status.${shippingStatus}`)}</DataCardLine>
          <DataCardLine title={t('shipping.provider')} noTooltip>{shippingProvider}</DataCardLine>
          <DataCardLine title={t('shipping.label')} noTooltip>
            {labelId && (
              <Button
                variant="link"
                className="p-0 shadow-none"
                onClick={() => {
                  downloadLabel(labelId);
                }}
              >
                {t('shipping.downloadLabel')}
              </Button>
            )}
            {!labelId && labelGeneration && (
              <>
                <span className="mr-1">
                  {t('shipping.pendingLabel')}
                </span>
                {senderAddress?.formattedAddress && (
                  <Button
                    variant="link"
                    className="p-0 shadow-none"
                    onClick={() => {
                      generateShippingLabel(id)
                        .then(({ payload: { data: { shipping: { labelId } = {} } = {} } = {} }) => {
                          downloadLabel(labelId);
                        })
                        .catch(() => {
                          toast.error(t('errors.submitError'));
                        });
                    }}
                  >
                    {t('shipping.generateLabel')}
                  </Button>
                )}
              </>
            )}
            {!labelGeneration && (
              <span>{t('shipping.noLabel')}</span>
            )}
          </DataCardLine>
          <DataCardLine title={t('shipping.trackingNumber')} noTooltip>
            {trackingNumber ? (
              <a
                href={`https://tripartie.aftership.com/${trackingNumber}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {trackingNumber}
              </a>
            ) : (
              <span>{labelGeneration ? t('shipping.na') : t('shipping.nc')}</span>
            )}
          </DataCardLine>
          <DataCardLine title={t('shipping.trackingProvider')} noTooltip>
            {trackingId ? (
              <a
                href={`https://admin.aftership.com/shipments/${trackingId}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Aftership
              </a>
            ) : (
              <span>{t('shipping.na')}</span>
            )}
          </DataCardLine>
        </DataCard>
      )}

      {(buyerReviewScore || sellerReviewScore) && (
        <DataCard title={t('reviews.cardTitle')}>
          <DataCardLine title={t('reviews.buyerReview')} popoverContent={buyerReviewComment}>
            {buyerReviewScore}
            {' '}
            / 5
            {buyerReviewComment && (
            <span className="text-truncate">
              (
              {buyerReviewComment}
              )
            </span>
            )}
          </DataCardLine>
          <DataCardLine title={t('reviews.sellerReview')} popoverContent={sellerReviewComment}>
            {sellerReviewScore}
            {' '}
            / 5
            {sellerReviewComment && (
            <span className="text-truncate">
              (
              {sellerReviewComment}
              )
            </span>
            )}
          </DataCardLine>
        </DataCard>
      )}

      {events && (
        <DataCard title={t('events.cardTitle')}>
          {events.map(({ date, slug }) => (
            <DataCardLine key={slug} title={t(`_transactions:status.${slug}`)} className="justify-content-between">
              <FormattedDate value={new Date(date)} dateStyle="short" timeStyle="long" />
            </DataCardLine>
          ))}
        </DataCard>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isTripartie: state.persistent.meReducer.isTripartie,
  canSeeTransactionsTab: state.persistent.meReducer.canSeeTransactionsTab,
  isSuperAdmin: state.persistent.meReducer.isSuperAdmin,
  isSupport: state.persistent.meReducer.isSupport,
  transactions: state.persistent.transactionsReducer.transactions,
});

const mapDispatchToProps = (dispatch) => ({
  getTransaction: bindActionCreators(getTransaction, dispatch),
  cancelTransaction: bindActionCreators(cancelTransaction, dispatch),
  setDeliveryAddress: bindActionCreators(setDeliveryAddress, dispatch),
  setReturnAddress: bindActionCreators(setReturnAddress, dispatch),
  generateShippingLabel: bindActionCreators(generateShippingLabel, dispatch),
  fetchMediaFile: bindActionCreators(fetchMediaFile, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TransactionDetails);
