import * as Sentry from '@sentry/react';
import { createReducer } from '@reduxjs/toolkit';
import {
  ROLE_SUPPORT,
  ROLE_ADMIN,
  ROLE_OWNER,
  ROLE_SUPER_ADMIN,
  ROLE_READ_ONLY,
} from 'helpers/adminRolesHelpers';
import {
  success,
  shouldResetState,
} from 'logic/reducers/helpers';
import { GET_TOKEN } from 'logic/actions/authActions';
import {
  SYNC_LOGGED_IN,
  SYNC_REFRESH_TOKEN,
  SYNC_LOGGED_OUT,
} from 'logic/actions/syncActions';
import {
  GET_ME,
  UPDATE_ME,
  UPDATE_CLIENT,
} from 'logic/actions/meActions';

const initialState = {
  me: {},
  isTripartie: false,
  isSuperAdmin: false,
  isOwner: false,
  isAdmin: false,
  isSupport: false,
  isReadOnly: false,
  loggedIn: false,
  canSeeUsersTab: false,
  canSeeTransactionsTab: false,
  canSeeTransactionLinksTab: false,
  canSeeDisputesTab: false,
};

const handleLoggedIn = (state) => ({
  ...state,
  loggedIn: !!state.me?.email,
});

const handleLoggedOut = (state) => ({
  ...state,
  loggedIn: false,
});

const handleGetMe = (
  state,
  { payload: { data: me = {} } = {} },
) => {
  const {
    email,
    role,
    client: {
      checkoutUrl,
      disputeUrl,
    } = {},
    isTripartieAdmin = false,
  } = me;

  if (email) {
    state.loggedIn = true;
    state.me = {
      ...state.me,
      ...me,
    };
    Sentry.setUser({ email });
  }

  state.isTripartie = isTripartieAdmin;
  state.canSeeUsersTab = isTripartieAdmin || !!checkoutUrl || !!disputeUrl;
  state.canSeeTransactionsTab = isTripartieAdmin || !!checkoutUrl;
  state.canSeeTransactionLinksTab = isTripartieAdmin || !!checkoutUrl;
  state.canSeeDisputesTab = isTripartieAdmin || !!disputeUrl;

  state.isSuperAdmin = false;
  state.isOwner = false;
  state.isAdmin = false;
  state.isSupport = false;

  /* eslint-disable no-fallthrough */
  switch (role) {
    case ROLE_READ_ONLY: {
      state.isReadOnly = true;
      break;
    }
    case ROLE_SUPER_ADMIN:
      state.isSuperAdmin = true;
    case ROLE_OWNER:
      state.isOwner = true;
    case ROLE_ADMIN:
      state.isAdmin = true;
    case ROLE_SUPPORT:
      state.isSupport = true;
    default:
  }
  /* eslint-enable no-fallthrough */
};

export default createReducer(initialState, {
  [SYNC_LOGGED_IN]: handleLoggedIn,
  [SYNC_REFRESH_TOKEN]: handleLoggedIn,
  [SYNC_LOGGED_OUT]: handleLoggedOut,
  [success(GET_ME)]: handleGetMe,
  [success(UPDATE_ME)]: handleGetMe,
  [success(UPDATE_CLIENT)]: handleGetMe,
}, [{
  matcher: ({ type }) => shouldResetState({ type }) && type !== success(GET_TOKEN),
  reducer: () => {
    Sentry.setUser({ email: 'Not logged in' });
    return initialState;
  },
}]);
