import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = ({
  component: Component,
  ...rest
}) => {
  const loggedIn = useSelector((state) => (state.persistent.meReducer.loggedIn));

  return (
    <Route
      {...rest}
      render={(props) => (
        loggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/login', state: { referer: props.location } }} />
        )
      )}
    />
  );
};

export default PrivateRoute;
