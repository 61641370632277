import React from 'react';
import BootstrapButton from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

const Button = ({
  isLoading = false,
  disabled = false,
  className = '',
  variant = 'outline-primary',
  children,
  ...props
}) => (
  <BootstrapButton
    className={`${className} ${isLoading && 'btn-loading'}`}
    disabled={isLoading || disabled}
    variant={variant}
    {...props}
  >
    {isLoading && (
      <Spinner as="span" animation="border" className="btn-spinner" />
    )}
    <span className="btn-content">{children}</span>
  </BootstrapButton>
);

export default Button;
