/* eslint-disable import/no-unused-modules */
export const SET_DATA_LIST_LIMIT = 'app/me/SET_DATA_LIST_LIMIT';
/* eslint-enable import/no-unused-modules */

export const setDataListLimit = (dataListLimit) => ({
  type: SET_DATA_LIST_LIMIT,
  payload: { dataListLimit },
});

export const selectDataListLimit = (state) => state.persistent.settingsReducer.dataListLimit;
