import * as React from 'react';

const SvgTabAdmins = ({
  color = '#aab6be',
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 41.83 46.43"
    stroke={color}
    {...props}
  >
    <path
      d="M41 36l-1.22-.21a6.08 6.08 0 00-.48-1.18l.72-1a1.06 1.06 0 00.18-.87 1.08 1.08 0 00-.28-.5l-1.12-1.08a1.05 1.05 0 00-1.37-.16l-1 .71a7.48 7.48 0 00-1.17-.5L35.05 30a1.06 1.06 0 00-1-.9h-1.57a1.08 1.08 0 00-.76.31 1 1 0 00-.3.57l-.21 1.22a6.14 6.14 0 00-1.22.5L29 31a1.08 1.08 0 00-1.38.11l-1.08 1.07a1.05 1.05 0 00-.12 1.37l.71 1a6.72 6.72 0 00-.49 1.17l-1.21.19a1 1 0 00-.58.3 1.07 1.07 0 00-.32.75v1.52a1.08 1.08 0 00.88 1.07l1.23.21a7.16 7.16 0 00.48 1.24l-.71 1a1 1 0 00-.09.13 1.06 1.06 0 00.19 1.24l1.07 1.09a1.08 1.08 0 001.38.12l1-.71a6.82 6.82 0 001.18.49l.18 1.2a1.07 1.07 0 001.05.9h1.53a1.06 1.06 0 00.75-.31 1 1 0 00.3-.57l.22-1.23a6.67 6.67 0 001.14-.47l1 .73a1.08 1.08 0 001.38-.11l1.08-1.07a1.08 1.08 0 00.15-1.43l-.71-1a6.75 6.75 0 00.5-1.17l1.21-.19a1.08 1.08 0 00.58-.3 1 1 0 00.32-.75v-1.52A1.07 1.07 0 0041 36zm-5.2 4.43a3.74 3.74 0 01-5.28 0c-.07-.07-.13-.15-.19-.22a3.73 3.73 0 015.5-5 3.64 3.64 0 01.81 1.24 3.73 3.73 0 01-.84 3.98z"
      fill={color}
    />
    <path
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5px"
      fillRule="evenodd"
      d="M27.83 24a42 42 0 00-9.12-.82 42.12 42.12 0 00-9.12.82 10.85 10.85 0 00-8.07 8.61 73.84 73.84 0 00-.77 12.16M18.67.75c-6.65 0-8.31 1.66-8.31 8.3s1.66 8.31 8.31 8.31S27 15.7 27 9.05 25.31.75 18.67.75z"
    />
  </svg>
);

export default SvgTabAdmins;
