import React from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import Form from 'react-bootstrap/Form';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';

const LoginForm = ({
  onSubmit,
}) => {
  const { t } = useTranslation('loginForm');

  return (
    <Formik
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={yup.object({
        email: yup.string().required(t('emptyEmail')).email(t('invalidEmail')),
        password: yup.string().required(t('emptyPassword')),
      })}
      onSubmit={onSubmit}
      initialValues={{
        email: '',
        password: '',
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        isSubmitting,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Row>
            <Form.Group md="4" controlId="email">
              <Form.Label>{t('email')}</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={!!errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group md="4" controlId="password">
              <Form.Label>{t('password')}</Form.Label>
              <Form.Control
                type="password"
                name="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={!!errors.password}
              />
              <Form.Control.Feedback type="invalid">
                {errors.password}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Button
              type="submit"
              isLoading={isSubmitting}
              className="w-100 mt-3"
            >
              {t('submit')}
            </Button>
          </Form.Row>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
