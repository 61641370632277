import omitBy from 'lodash/omitBy';

/* eslint-disable import/no-unused-modules */
export const GET_TRANSACTIONS = 'app/transactions/GET_TRANSACTIONS';
export const EXPORT_TRANSACTIONS = 'app/transactions/EXPORT_TRANSACTIONS';
export const GET_TRANSACTION = 'app/transactions/GET_TRANSACTION';
export const CANCEL_TRANSACTION = 'app/transactions/CANCEL_TRANSACTION';
export const COMPLETE_TRANSACTION = 'app/transactions/COMPLETE_TRANSACTION';
export const PAUSE_TRANSACTION = 'app/transactions/PAUSE_TRANSACTION';
export const UNPAUSE_TRANSACTION = 'app/transactions/UNPAUSE_TRANSACTION';
export const GET_TRANSACTION_NEXT_STATES = 'app/transactions/GET_TRANSACTION_NEXT_STATES';
export const FORCE_TRANSACTION = 'app/transactions/FORCE_TRANSACTION';
export const SET_DELIVERY_ADDRESS = 'app/transactions/SET_DELIVERY_ADDRESS';
export const SET_RETURN_ADDRESS = 'app/transactions/SET_RETURN_ADDRESS';
export const GENERATE_SHIPPING_LABEL = 'app/transactions/GENERATE_SHIPPING_LABEL';
/* eslint-enable import/no-unused-modules */

export const getTransactions = ({
  page = 1, limit = 10, ...params
} = {}) => ({
  type: GET_TRANSACTIONS,
  payload: {
    request: {
      method: 'GET',
      url: '/api/dashboard/transactions/',
      params: {
        page,
        limit,
        ...params,
      },
    },
  },
});

export const exportTransactions = (params, columns) => ({
  type: EXPORT_TRANSACTIONS,
  payload: {
    request: {
      method: 'GET',
      url: '/api/dashboard/transactions/export/',
      params: {
        ...params,
        columns: Object.keys(omitBy(columns, (col) => (!col))),
      },
      responseType: 'blob',
      timeout: 0,
    },
  },
});

export const getTransaction = (transactionId) => ({
  type: GET_TRANSACTION,
  transactionId,
  payload: {
    request: {
      method: 'GET',
      url: `/api/dashboard/transactions/${transactionId}/`,
    },
  },
});

export const cancelTransaction = (transactionId) => ({
  type: CANCEL_TRANSACTION,
  transactionId,
  payload: {
    request: {
      method: 'PATCH',
      url: `/api/dashboard/transactions/${transactionId}/cancel/`,
    },
  },
});

export const completeTransaction = (transactionId) => ({
  type: COMPLETE_TRANSACTION,
  transactionId,
  payload: {
    request: {
      method: 'PATCH',
      url: `/api/dashboard/transactions/${transactionId}/complete/`,
    },
  },
});

export const pauseTransaction = (transactionId) => ({
  type: PAUSE_TRANSACTION,
  payload: {
    request: {
      method: 'PATCH',
      url: `/api/dashboard/transactions/${transactionId}/pause/`,
    },
  },
});

export const unpauseTransaction = (transactionId) => ({
  type: UNPAUSE_TRANSACTION,
  payload: {
    request: {
      method: 'PATCH',
      url: `/api/dashboard/transactions/${transactionId}/unpause/`,
    },
  },
});

export const getNextStates = (transactionId) => ({
  type: GET_TRANSACTION_NEXT_STATES,
  payload: {
    request: {
      method: 'GET',
      url: `/api/dashboard/transactions/${transactionId}/get-next-states/`,
    },
  },
});

export const forceTransaction = (transactionId, step, data) => ({
  type: FORCE_TRANSACTION,
  transactionId,
  payload: {
    request: {
      method: 'PATCH',
      url: `/api/dashboard/transactions/${transactionId}/force/`,
      data: {
        step,
        ...data,
      },
    },
  },
});

export const setDeliveryAddress = (transactionId, data) => ({
  type: SET_DELIVERY_ADDRESS,
  transactionId,
  payload: {
    request: {
      method: 'PATCH',
      url: `/api/dashboard/transactions/${transactionId}/delivery-address/`,
      data,
    },
  },
});

export const setReturnAddress = (transactionId, data) => ({
  type: SET_RETURN_ADDRESS,
  transactionId,
  payload: {
    request: {
      method: 'PATCH',
      url: `/api/dashboard/transactions/${transactionId}/return-address/`,
      data,
    },
  },
});

export const generateShippingLabel = (transactionId, data) => ({
  type: GENERATE_SHIPPING_LABEL,
  transactionId,
  payload: {
    request: {
      method: 'PATCH',
      url: `/api/dashboard/transactions/${transactionId}/shipping-label/`,
      data,
    },
  },
});
