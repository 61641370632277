import {
  GET_TOKEN,
  REFRESH_TOKEN,
  LOGOUT,
} from 'logic/actions/authActions';

export function success(action) { return `${action}_SUCCESS`; }
export function fail(action) { return `${action}_FAIL`; }

const shouldResetStateRegExp = new RegExp(`${GET_TOKEN}|${fail(REFRESH_TOKEN)}|${success(LOGOUT)}|${fail(LOGOUT)}`);
export function shouldResetState({ type = '' } = {}) { return type.match(shouldResetStateRegExp); }
