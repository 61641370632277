import React, {
  useEffect,
  useState,
} from 'react';
import pickBy from 'lodash/pickBy';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import { FormattedDate } from 'react-intl';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import DataList from 'components/DataList';
import Pagination from 'components/Pagination';
import DataListLimitSelector from 'components/DataListLimitSelector';
import QueryForm from 'components/QueryForm';
import {
  getUsers,
} from 'logic/actions/usersActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

const UsersList = ({
  dataListLimit,
  isTripartie,
  canSeeUsersTab,
  pages,
  nbPages,
  getUsers = null,
  location,
  history,
}) => {
  const { t } = useTranslation('usersList');

  useEffect(() => {
    if (!canSeeUsersTab) {
      history.push('/');
    }
  }, [canSeeUsersTab, history]);

  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searching, setSearching] = useState(false);

  const [query, setQuery] = useState(queryString.parse(location.search));
  const { search } = query;

  const columns = [
    {
      name: t('columns.id'),
      extractor: ({ id }) => (id),
    },
  ];

  if (!isTripartie) {
    columns.push({
      name: t('columns.externalIds'),
      extractor: ({ externalIds = [] }) => (externalIds.join(', ')),
    });
  }

  columns.push(...[
    {
      name: t('columns.name'),
      extractor: ({ userProfile: { firstName, lastName, displayName } = {} }) => (
        firstName || lastName ? `${firstName} ${lastName}`.trim() : displayName
      ),
    },
    {
      name: t('columns.email'),
      extractor: ({ email }) => (email),
    },
    {
      name: t('columns.status'),
      extractor: ({ accountStatus }) => (t(`accountStatus.${accountStatus}`)),
    },
    {
      name: t('columns.signupDate'),
      extractor: ({ signupDate }) => (signupDate && <FormattedDate value={new Date(signupDate)} />),
      className: 'align-right',
    },
    {
      name: t('columns.kycVerified'),
      extractor: ({ kycVerified }) => (t(`isVerified.${kycVerified}`)),
    },
  ]);

  useEffect(() => {
    setLoading(true);
    history.push(`?${queryString.stringify(pickBy(query))}`);
    getUsers({
      page: currentPage,
      limit: dataListLimit,
      ...query,
    })
      .finally(() => {
        setLoading(false);
        setSearching(false);
      });
  }, [
    currentPage,
    dataListLimit,
    query,
    getUsers,
    history,
  ]);

  return (
    <div className="mt-3">
      <QueryForm
        search={search}
        onSubmit={(search) => { setQuery((prev) => ({ ...prev, search })); }}
        isSubmitting={searching}
      />
      <DataList
        loading={loading}
        columns={columns}
        data={pages[currentPage]}
        to={({ id, isPreregistration }) => (!isPreregistration && `/users/${id}`)}
        emptyMessage={search ? t('noResults') : t('noItems')}
      />
      <Row>
        <Col md={{ span: 4, offset: 4 }} className="d-flex justify-content-center align-items-center">
          <Pagination
            currentPage={currentPage}
            nbPages={nbPages}
            onClick={setCurrentPage}
          />
        </Col>
        <Col className="d-flex w-100 justify-content-end align-items-center">
          <DataListLimitSelector />
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isTripartie: state.persistent.meReducer.isTripartie,
  canSeeUsersTab: state.persistent.meReducer.canSeeUsersTab,
  pages: state.persistent.usersReducer.pages,
  nbPages: state.persistent.usersReducer.nbPages,
  dataListLimit: state.persistent.settingsReducer.dataListLimit,
});

const mapDispatchToProps = (dispatch) => ({
  getUsers: bindActionCreators(getUsers, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UsersList);
