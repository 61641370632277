/* eslint-disable import/no-unused-modules */
export const GET_COUNTRIES = 'app/countries/GET_COUNTRIES';
/* eslint-enable import/no-unused-modules */

export const getCountries = () => ({
  type: GET_COUNTRIES,
  noAuth: true,
  payload: {
    request: {
      method: 'GET',
      url: '/api/dashboard/countries/',
    },
  },
});
