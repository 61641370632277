import { LEMONWAY_CHF, LEMONWAY_EUR, MANGOPAY } from './constants';
import { lemonwayChfBaseUrl, lemonwayEurBaseUrl, mangoPayBaseUrl } from './env';

export const filterAndSortArray = (array = [], translateKey = () => {}, t, i18n) => (
  array
    .filter((item) => (i18n.exists(translateKey(item))))
    .sort((itemA, itemB) => (t(translateKey(itemA)).localeCompare(t(translateKey(itemB)))))
);

export const getPspBaseUrl = (pspSlug) => {
  switch (pspSlug) {
    case MANGOPAY:
      return mangoPayBaseUrl;

    case LEMONWAY_EUR:
      return lemonwayEurBaseUrl;

    case LEMONWAY_CHF:
      return lemonwayChfBaseUrl;

    default:
      return '';
  }
};

export const getUserPspUrl = (pspSlug, userPspId) => {
  switch (pspSlug) {
    case MANGOPAY:
      return `${mangoPayBaseUrl}/User/${userPspId}/Details`;

    case LEMONWAY_EUR:
      return `${lemonwayEurBaseUrl}/accounts/${userPspId}/overview`;

    case LEMONWAY_CHF:
      return `${lemonwayChfBaseUrl}/accounts/${userPspId}/overview`;

    default:
      return '';
  }
};
