import React, {
  useState,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { Formik } from 'formik';
import * as yup from 'yup';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Col from 'react-bootstrap/Col';
import Button from 'components/Button';
import * as Roles from 'helpers/adminRolesHelpers';
import {
  ERROR_EMAIL_ALREADY_EXISTS,
} from 'helpers/constants';
import { checkEmail } from 'logic/actions/usersActions';

const EditAdminProfileModal = ({
  admin: {
    email,
    userProfile: {
      firstName,
      lastName,
    } = {},
    role = '',
  } = {},
  show,
  onHide,
  onSubmit,
}) => {
  const { t } = useTranslation([
    'editAdminModal',
    '_admins',
  ]);
  const {
    me: {
      role: currentRole,
    },
  } = useSelector(({ persistent: { meReducer } = {} }) => (meReducer));

  const dispatch = useDispatch();
  const doCheckEmail = useCallback((email) => dispatch(checkEmail(email)), [dispatch]);
  const [previousEmail, setPreviousEmail] = useState(email);
  const [previousEmailTestResult, setPreviousEmailTestResult] = useState(true);

  const allowedRoles = Roles.ALL_ROLES.filter((role) => (Roles.gte(currentRole, role)));

  return (
    <Formik
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={yup.object({
        email: yup
          .string()
          .required(t('required'))
          .email(t('invalidEmail'))
          .test({
            name: 'emailValid',
            test: (newEmail, { createError }) => {
              if (!newEmail || newEmail === previousEmail) {
                return previousEmailTestResult;
              }
              setPreviousEmail(newEmail);
              return doCheckEmail(newEmail)
                .then(() => {
                  setPreviousEmailTestResult(true);
                  return true;
                })
                .catch(
                  ({ error: { response: { data: { errors } = {} } = {} } = {} }) => {
                    let result = createError({ message: t('invalidEmail') });
                    if (errors === ERROR_EMAIL_ALREADY_EXISTS) {
                      result = createError({ message: t('alreadyUsedEmail') });
                    }
                    setPreviousEmailTestResult(result);
                    return result;
                  },
                );
            },
          }),
        firstName: yup.string().required(t('required')),
        lastName: yup.string().required(t('required')),
        role: yup.string().required(t('required')),
      })}
      onSubmit={({
        email,
        firstName,
        lastName,
        role,
      }) => onSubmit({
        email,
        userProfile: {
          firstName,
          lastName,
        },
        role,
      })}
      initialValues={{
        email,
        firstName,
        lastName,
        role,
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        isSubmitting,
        resetForm,
        setFieldValue,
      }) => {
        const resetAndHide = () => {
          resetForm();
          onHide();
        };

        return (
          <Modal show={show} onHide={resetAndHide} centered>
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>{t('modalTitle')}</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <Form.Row>
                  <Form.Group as={Col} controlId="email">
                    <Form.Label>{t('email')}</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder={t('email')}
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={!!errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="firstName">
                    <Form.Label>{t('firstName')}</Form.Label>
                    <Form.Control
                      name="firstName"
                      placeholder={t('firstName')}
                      value={values.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={!!errors.firstName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.firstName}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} controlId="lastName">
                    <Form.Label>{t('lastName')}</Form.Label>
                    <Form.Control
                      name="lastName"
                      placeholder={t('lastName')}
                      value={values.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={!!errors.lastName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.lastName}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="role">
                    <Form.Label>{t('role')}</Form.Label>
                    <DropdownButton
                      variant="outline-primary"
                      className="full-width-dropdown"
                      title={t(`_admins:role.${values.role.toLowerCase()}`)}
                    >
                      {allowedRoles.map((role) => (
                        <Dropdown.Item
                          key={role}
                          onSelect={() => {
                            setFieldValue('role', role);
                          }}
                        >
                          {t(`_admins:role.${role.toLowerCase()}`)}
                        </Dropdown.Item>
                      ))}
                    </DropdownButton>
                    <Form.Control.Feedback type="invalid" className={errors.role ? 'd-block' : ''}>
                      {errors.role}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
              </Modal.Body>

              <Modal.Footer>
                <Button variant="outline-secondary" onClick={resetAndHide}>{t('close')}</Button>
                <Button
                  type="submit"
                  isLoading={isSubmitting}
                >
                  {t('ok')}
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default EditAdminProfileModal;
