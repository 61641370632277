import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as yup from 'yup';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import { isApi } from 'helpers/env';
import Button from 'components/Button';
import ClientFieldsForSuperAdmin from 'components/ClientFieldsForSuperAdmin';
import { selectIsSuperAdmin } from 'logic/actions/meActions';

const ClientModal = ({
  show,
  onHide,
  onSubmit,
  translation,
  defaultValues,
  showExtraFields = false,
  clearOnMount = false,
  isEditModal = false,
  validationFields = {},
}) => {
  const { t } = useTranslation([
    'clientModal', translation,
  ]);
  const {
    name = '',
    description = '',
    registrationNumber = '',
    vatNumber = '',
    websiteUrl = '',
    address = '',
    webhookUrl = '',
    slug = '',
    theme = '',
    squareConfig = '',
    emailConfig = '',
    platformConfig = '',
    lang = '',
  } = defaultValues || {};

  const isSuperAdmin = useSelector(selectIsSuperAdmin);

  const extraFields = showExtraFields ? {
    slug,
    theme: {
      primary: theme ? theme.primary : '',
    },
    squareConfig,
    emailConfig,
    platformConfig,
    lang,
  } : {};

  const initialValues = {
    name,
    description,
    registrationNumber,
    vatNumber,
    websiteUrl,
    address,
    webhookUrl,
    ...extraFields,
  };

  return (
    <Formik
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={yup.object({
        name: yup.string().trim().required(t('required')),
        description: yup.string().trim().required(t('required')),
        registrationNumber: yup.string().trim().required(t('required')),
        websiteUrl: yup.string().trim().required(t('required')),
        address: yup.string().trim().required(t('required')),
        vatNumber: yup.string().trim().required(t('required')),
        ...validationFields,
      })}
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        resetForm,
        isSubmitting,
      }) => {
        const resetAndHide = () => {
          resetForm();
          onHide();
        };

        return (
          <Modal show={show} onHide={resetAndHide} centered>
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>{t(`${translation}:modalTitle`)}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Row>
                  <Form.Group as={Col} controlId="name">
                    <Form.Label>{t('name')}</Form.Label>
                    <Form.Control
                      name="name"
                      placeholder={t('name')}
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={!!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="description">
                    <Form.Label>{t('description')}</Form.Label>
                    <Form.Control
                      name="description"
                      placeholder={t('description')}
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={!!errors.description}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.description}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="registrationNumber">
                    <Form.Label>{t('registrationNumber')}</Form.Label>
                    <Form.Control
                      name="registrationNumber"
                      placeholder={t('registrationNumber')}
                      value={values.registrationNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={!!errors.registrationNumber}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.registrationNumber}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} controlId="vatNumber">
                    <Form.Label>{t('vatNumber')}</Form.Label>
                    <Form.Control
                      name="vatNumber"
                      placeholder={t('vatNumber')}
                      value={values.vatNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={!!errors.vatNumber}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.vatNumber}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="websiteUrl">
                    <Form.Label>{t('websiteUrl')}</Form.Label>
                    <Form.Control
                      name="websiteUrl"
                      placeholder={t('websiteUrl')}
                      value={values.websiteUrl}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={!!errors.websiteUrl}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.websiteUrl}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="address">
                    <Form.Label>{t('address')}</Form.Label>
                    <Form.Control
                      name="address"
                      placeholder={t('address')}
                      value={values.address}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={!!errors.address}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.address}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                {!isApi && (
                  <Form.Row>
                    <Form.Group as={Col} controlId="webhookUrl">
                      <Form.Label>{t('webhookUrl')}</Form.Label>
                      <Form.Control
                        name="webhookUrl"
                        placeholder={t('webhookUrl')}
                        value={values.webhookUrl}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.webhookUrl}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.webhookUrl}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                )}
                {isSuperAdmin && showExtraFields && <ClientFieldsForSuperAdmin clearOnMount={clearOnMount} isEditModal={isEditModal} />}
              </Modal.Body>

              <Modal.Footer>
                <Button variant="outline-secondary" onClick={resetAndHide}>{t('close')}</Button>
                <Button
                  onClick={handleSubmit}
                  isLoading={isSubmitting}
                >
                  {t('ok')}
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default ClientModal;
