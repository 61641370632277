/* eslint-disable import/no-unused-modules */
export const GET_CLIENTS = 'app/clients/GET_CLIENTS';
export const GET_CLIENT = 'app/clients/GET_CLIENT';
export const ADD_CLIENT = 'app/clients/ADD_CLIENT';
export const EDIT_CLIENT = 'app/clients/EDIT_CLIENT';
export const FETCH_CLIENT_LOGO = 'app/clients/FETCH_CLIENT_LOGO';
/* eslint-enable import/no-unused-modules */

export const fetchClientLogo = (clientId) => ({
  type: FETCH_CLIENT_LOGO,
  payload: {
    request: {
      method: 'GET',
      url: `/api/webapp/clients/${clientId}/platform-logo/`,
    },
  },
});

export const getClients = (clearClient = true) => ({
  type: GET_CLIENTS,
  clearClient, // For reducer: clear current loaded client ?
  payload: {
    request: {
      method: 'GET',
      url: '/api/dashboard/clients/',
    },
  },
});

export const getClient = (id) => (dispatch) => dispatch({
  type: GET_CLIENT,
  payload: {
    request: {
      method: 'GET',
      url: `/api/dashboard/clients/${id}/fetch/`,
    },
  },
}).then(() => dispatch(fetchClientLogo(id)));

export const addClient = (data) => ({
  type: ADD_CLIENT,
  payload: {
    request: {
      method: 'POST',
      url: '/api/dashboard/clients/create/',
      data,
    },
  },
});

export const editClient = (id, data) => ({
  type: EDIT_CLIENT,
  payload: {
    request: {
      method: 'POST',
      url: `/api/dashboard/clients/${id}/update/`,
      data,
    },
  },
});

export const selectAllClients = (state) => state.persistent.clientsReducer.clients;
export const selectClientsLoading = (state) => state.persistent.clientsReducer.loading;
export const selectClient = (state) => state.persistent.clientsReducer.client;
