// Intl import, don't move this !!
import 'intl';
import 'intl/locale-data/jsonp/fr';
import { IntlProvider } from 'react-intl';
// Intl import
import React, { useEffect } from 'react';
import {
  Route,
  Switch,
  useLocation,
} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { useTranslation } from 'react-i18next';
import PrivateRoute from 'helpers/PrivateRoute';
import LoadingScreen from 'components/LoadingScreen';
import { useLoadingScreen } from 'helpers/LoadingScreenContext';
import SideBar from 'components/SideBar';
import { getClients } from 'logic/actions/clientsActions';
import { getCountries } from 'logic/actions/countriesActions';
import { getMe } from 'logic/actions/meActions';
import {
  sentryDSN,
  sentryEnvironmentName,
  sentryReleaseVersion,
} from 'helpers/env';
import Login from 'pages/Login';
import Home from 'pages/Home';
import Profile from 'pages/Profile';
import UsersList from 'pages/users/UsersList';
import UserDetails from 'pages/users/UserDetails';
import TransactionsList from 'pages/transactions/TransactionsList';
import TransactionDetails from 'pages/transactions/TransactionDetails';
import TemplatesList from 'pages/templates/TemplatesList';
import TemplateDetails from 'pages/templates/TemplateDetails';
import AdminsList from 'pages/admins/AdminsList';
import AdminDetails from 'pages/admins/AdminDetails';
import classes from 'assets/style/app.module.scss';
import ClientsList from 'pages/clients/ClientsList';
import ClientsDetails from 'pages/clients/ClientsDetails';
import DisputesList from 'pages/disputes/DisputesList';
import DisputeDetails from 'pages/disputes/DisputeDetails';
import { getCurrencies } from 'logic/actions/currenciesActions';

if (sentryDSN) {
  Sentry.init({
    dsn: sentryDSN,
    integrations: [new BrowserTracing()],
    environment: sentryEnvironmentName,
    release: sentryReleaseVersion,
    tracesSampleRate: 1.0,
    beforeSend: (event) => {
      const { extra: { __serialized__: { error = {} } = {} } = {} } = event; /* eslint-disable-line no-underscore-dangle */

      if (
        error?.message
          && error.message.match(/request failed with status code/i)
      ) {
        return null;
      }

      if (error && typeof error.status === 'number' && [0, 400, 401, 403, 404, 500].includes(error.status)) {
        return null;
      }

      return event;
    },
  });
  Sentry.setUser({ email: 'Not logged in' });
}

const App = ({
  loggedIn,
  isTripartie,
  getCountries,
  getCurrencies,
  getClients,
  getMe,
}) => {
  const { i18n } = useTranslation();
  const setLoadingScreen = useLoadingScreen();
  const { pathname } = useLocation();

  useEffect(() => {
    setLoadingScreen(!loggedIn);
    getCountries();
    getCurrencies();

    getMe()
      .then(() => (isTripartie ? getClients(false) : Promise.resolve()))
      .finally(() => { setLoadingScreen(false); });
  }, [
    setLoadingScreen,
    getCountries,
    getCurrencies,
    getMe,
    isTripartie,
    getClients,
    loggedIn,
  ]);

  return (
    <IntlProvider locale={i18n.language}>
      <LoadingScreen />
      <div className={pathname !== '/login' ? classes.app : ''}>
        {loggedIn && (
          <div>
            <SideBar />
          </div>
        )}
        <div className={pathname !== '/login' ? classes.app__content : ''}>
          <Switch>
            <Route exact path="/login" component={Login} />

            <PrivateRoute exact path="/" component={Home} />
            <PrivateRoute exact path="/profile" component={Profile} />
            <PrivateRoute exact path="/users" component={UsersList} />
            <PrivateRoute exact path="/users/:userId" component={UserDetails} />
            <PrivateRoute exact path="/transactions" component={TransactionsList} />
            <PrivateRoute exact path="/transactions/:transactionId" component={TransactionDetails} />
            <PrivateRoute exact path="/templates" component={TemplatesList} />
            <PrivateRoute exact path="/templates/:templateId" component={TemplateDetails} />
            <PrivateRoute exact path="/admins" component={AdminsList} />
            <PrivateRoute exact path="/admins/:adminId" component={AdminDetails} />
            <PrivateRoute exact path="/clients" component={ClientsList} />
            <PrivateRoute exact path="/clients/:clientId" component={ClientsDetails} />
            <PrivateRoute exact path="/disputes" component={DisputesList} />
            <PrivateRoute exact path="/disputes/:disputeId" component={DisputeDetails} />
          </Switch>
        </div>
      </div>
    </IntlProvider>
  );
};

const mapStateToProps = (state) => ({
  loggedIn: state.persistent.meReducer.loggedIn,
  isTripartie: state.persistent.meReducer.isTripartie,
});

const mapDispatchToProps = (dispatch) => ({
  getCountries: bindActionCreators(getCountries, dispatch),
  getCurrencies: bindActionCreators(getCurrencies, dispatch),
  getClients: bindActionCreators(getClients, dispatch),
  getMe: bindActionCreators(getMe, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);
