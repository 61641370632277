import * as React from 'react';

const SvgTabDisputes = ({
  color = '#aab6be',
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 61.66 45.5"
    stroke={color}
    {...props}
  >
    <path
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5px"
      d="M26.72 34.66c1.37 7.85 5.82 10 16.82 10a27.7 27.7 0 0011.19-1.64l4.09 1.52a1.39 1.39 0 001.85-.7 1.41 1.41 0 000-1.14l-1.51-4.05a27.06 27.06 0 001.72-11.34c0-13.86-3.47-17.33-17.33-17.33a37.54 37.54 0 00-8.59.77"
    />
    <path
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5px"
      d="M2.54 29.4L1 33.45a1.38 1.38 0 00.7 1.84 1.36 1.36 0 001.15 0L7 33.77a27.63 27.63 0 0011.18 1.64c13.87 0 17.33-3.47 17.33-17.33S32 .75 18.13.75C4.27.75.8 4.22.8 18.08a27.22 27.22 0 001.72 11.34z"
    />
    <path
      fillOpacity=".88"
      fill={color}
      d="M19 21.92h-2.54c0-.45-.06-.78-.06-1A4.67 4.67 0 0118.5 17l1.19-.85a2.71 2.71 0 001.22-2.26 2.62 2.62 0 00-.73-1.85 3.14 3.14 0 00-4.22.12 3.05 3.05 0 00-.78 2.07 3.62 3.62 0 00.16 1.18l-2.93-.21a4.38 4.38 0 01-.16-1.2 5.37 5.37 0 011.55-3.77 5.67 5.67 0 014.31-1.64 5.94 5.94 0 014.32 1.53A4.9 4.9 0 0124 13.8a5.45 5.45 0 01-2.59 4.56l-1.12.78A2.88 2.88 0 0019 21.6a3.16 3.16 0 000 .32zM16.43 27a1.9 1.9 0 012.62-2.74l.06.06a1.83 1.83 0 01.55 1.35A1.89 1.89 0 0116.43 27z"
    />
    <path
      fillOpacity=".88"
      fill={color}
      d="M42.18 36.25a2 2 0 010-2.74 1.76 1.76 0 011.35-.58 1.85 1.85 0 011.38.58 1.94 1.94 0 01-1.38 3.31 1.81 1.81 0 01-1.35-.57zm3.22-18.34l-.74 13.18h-2.22l-.76-13.18z"
    />
  </svg>
);

export default SvgTabDisputes;
