import React from 'react';
import forIn from 'lodash/forIn';
import moment from 'moment';
import Spinner from 'react-bootstrap/Spinner';
import { useTranslation } from 'react-i18next';
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  ResponsiveContainer,
} from 'recharts';
import {
  DataCard,
} from 'components/DataCard';

const HEIGHT = 300;
const IS_LARGE_SCREEN = true;
const BLUE = '#004bb4';

const BAR_COLORS = [
  BLUE,
  'orange',
  'green',
  'purple',
];

const dateFormats = {
  day: 'DD MMM',
  month: 'MMM',
};

const DashboardGraph = ({
  label = '',
  loading = false,
  datesInterval: { groupBy = 'day' } = {},
  stats: {
    data = [],
    totals: {
      currentMonth = {},
      currentYear = {},
      allTime = {},
    } = {},
  },
  allowDecimals = false,
  renderNames = (key) => (key),
  renderValues = (val) => (val),
}) => {
  const isLargeScreen = IS_LARGE_SCREEN;
  const { t } = useTranslation('dashboardGraph');

  let maxValue = 0.1;
  const keys = [];

  // Find max value in data
  data.forEach(({
    date, ...props
  }) => {
    forIn(props, (value) => {
      maxValue = Math.max(maxValue, value);
    });
  });

  // Find all keys in allTime
  forIn(allTime, (value, key) => {
    if (!keys.includes(key)) {
      keys.push(key);
    }
  });

  const renderLegend = ({ payload = [] }) => (
    <div
      style={{
        height: HEIGHT,
        width: 'min-content',
        whiteSpace: 'nowrap',
      }}
    >
      {payload.map(({
        value: key, color,
      }) => (
        <div key={key}>
          <p style={{
            color, fontFamily: 'Montserrat_500Medium', fontSize: 12,
          }}
          >
            {'\u25A0'}
            {' '}
            {renderNames(key)}
          </p>
          <p style={{ fontSize: 10, fontFamily: 'Montserrat_500Medium' }}>
            {'\u00A0'}
            {' '}
            {t('thisMonth')}
            &nbsp;:
            {' '}
            {renderValues(currentMonth[key] || 0)}
          </p>
          <p style={{ fontSize: 10, fontFamily: 'Montserrat_500Medium' }}>
            {'\u00A0'}
            {' '}
            {t('thisYear')}
            &nbsp;:
            {' '}
            {renderValues(currentYear[key] || 0)}
          </p>
          <p style={{ fontSize: 10, fontFamily: 'Montserrat_500Medium' }}>
            {'\u00A0'}
            {' '}
            {t('allTime')}
            &nbsp;:
            {' '}
            {renderValues(allTime[key] || 0)}
          </p>
        </div>
      ))}
    </div>
  );

  return (
    <DataCard
      title={label}
      containerStyle={{ width: '90%' }}
      style={{
        minHeight: HEIGHT,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {loading ? (
        <Spinner animation="border" />
      ) : (
        <ResponsiveContainer
          width="100%"
          height={isLargeScreen ? HEIGHT : HEIGHT + 50}
        >
          <BarChart
            data={data}
            maxBarSize={10}
          >

            <CartesianGrid vertical={false} stroke="#fafafa" />

            <XAxis
              dataKey={({ date }) => (moment(date).format(dateFormats[groupBy]))}
            />

            <YAxis
              allowDecimals={allowDecimals}
              domain={[0, () => (maxValue)]}
              tickFormatter={renderValues}
              width={60}
              allowDataOverflow
            />

            <Tooltip
              cursor={data.length ? { fill: '#fafafa' } : false}
              formatter={(value, name) => ([renderValues(value), renderNames(name)])}
            />

            <Legend
              layout={isLargeScreen ? 'vertical' : 'horizontal'}
              verticalAlign={isLargeScreen ? 'top' : 'bottom'}
              align={isLargeScreen ? 'right' : 'center'}
              content={renderLegend}
            />

            {keys.map((key, index) => (
              <Bar
                name={key}
                key={key}
                dataKey={(item) => (item[key] || 0)}
                fill={BAR_COLORS[index % BAR_COLORS.length] || BLUE}
              />
            ))}

          </BarChart>
        </ResponsiveContainer>
      )}
    </DataCard>
  );
};

export default DashboardGraph;
