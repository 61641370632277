import { createReducer } from '@reduxjs/toolkit';
import { saveAs } from 'file-saver';
import {
  success,
  shouldResetState,
} from 'logic/reducers/helpers';
import {
  GET_DISPUTES,
  EXPORT_DISPUTES,
  GET_DISPUTE,
  START_RETURN,
  CLOSE_DISPUTE,
  CANCEL_DISPUTE,
} from 'logic/actions/disputesActions';

const initialState = {
  pages: [],
  nbPages: 1,
  disputes: [],
};

const handleGetDisputes = (
  state,
  {
    payload: {
      data: {
        page = 0,
        nbPages = 1,
        items = [],
      } = {},
    } = {},
  },
) => {
  if (page) {
    state.pages[page] = items;
  }
  state.nbPages = nbPages;
};

const handleExportDisputes = (
  state,
  { payload: { data } = {} },
) => {
  saveAs(data, 'disputes.csv');
};

const handleGetDispute = (
  { disputes },
  {
    payload: { data = {} } = {},
    meta: { previousAction: { disputeId = 0 } = {} } = {},
  },
) => {
  if (disputeId) {
    disputes[disputeId] = data;
  }
};

export default createReducer(initialState, {
  [success(GET_DISPUTES)]: handleGetDisputes,
  [success(EXPORT_DISPUTES)]: handleExportDisputes,
  [success(GET_DISPUTE)]: handleGetDispute,
  [success(START_RETURN)]: handleGetDispute,
  [success(CLOSE_DISPUTE)]: handleGetDispute,
  [success(CANCEL_DISPUTE)]: handleGetDispute,
}, [{
  matcher: (action) => (shouldResetState(action)),
  reducer: () => (initialState),
}]);
