import * as React from 'react';

const SvgTabProfile = ({
  color = '#aab6be',
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 37.42 45.52"
    stroke={color}
    {...props}
  >
    <path
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5px"
      fillRule="evenodd"
      d="M36.67 44.77a72.72 72.72 0 00-.77-12.12A10.85 10.85 0 0027.83 24a42 42 0 00-9.12-.82 42.12 42.12 0 00-9.12.82 10.85 10.85 0 00-8.07 8.61 73.84 73.84 0 00-.77 12.16M18.71.75c-6.64 0-8.3 1.66-8.3 8.3s1.66 8.31 8.3 8.31S27 15.7 27 9.05 25.36.75 18.71.75z"
    />
  </svg>
);

export default SvgTabProfile;
