import React, {
  useState,
  useEffect,
} from 'react';
import isEmpty from 'lodash/isEmpty';
import {
  FormattedDate,
  FormattedNumber,
} from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom';
import SpanWithColon from 'helpers/SpanWithColon';
import {
  STATUS_VERIFIED,
  STATUS_NOT_VERIFIED,
  MANGOPAY,
  LEMONWAY_EUR,
  LEMONWAY_CHF,
} from 'helpers/constants';
import {
  DataCard,
  DataCardLine,
  DataCardList,
} from 'components/DataCard';
import Button from 'components/Button';
import ButtonWithConfirmationModal from 'components/ButtonWithConfirmationModal';
import EditUserProfileModal from 'components/EditUserProfileModal';
import EditUserAddressModal from 'components/EditUserAddressModal';
import UploadKycModal from 'components/UploadKycModal';
import AddBankAccountModal from 'components/AddBankAccountModal';
import CashoutModal from 'components/CashoutModal';
import {
  getUser,
  updateUser,
  updateUserAddress,
  blockUser,
  unblockUser,
  tagSuspiciousUser,
  untagSuspiciousUser,
  deleteUser,
  getUserWallets,
  getUserTransactions,
  uploadKyc,
  addBankAccount,
  cashoutWallet,
  addWallet,
  deleteCard,
  deleteBankAccount,
} from 'logic/actions/usersActions';
import AddWalletModal from 'components/AddWalletModal';
import { getUserPspUrl } from 'helpers/utilities';
import { mangoPayBaseUrl } from 'helpers/env';

const UserDetails = ({
  isSupport,
  isTripartie,
  users,
  getUser,
  updateUser,
  updateUserAddress,
  blockUser,
  unblockUser,
  tagSuspiciousUser,
  untagSuspiciousUser,
  deleteUser,
  getUserWallets,
  getUserTransactions,
  uploadKyc,
  addBankAccount,
  addWallet,
  cashoutWallet,
  deleteCard,
  deleteBankAccount,
  canSeeUsersTab,
  match: { params: { userId } = {} } = {},
  history,
}) => {
  useEffect(() => {
    if (!canSeeUsersTab) {
      history.push('/');
    }
  }, [canSeeUsersTab, history]);

  const { t } = useTranslation([
    'userDetails',
    '_users',
    '_countries',
    '_transactions',
  ]);
  const user = users[userId]?.id ? users[userId] : { id: userId };
  const [isLoading, setLoading] = useState(!user.email);
  const [isLoadingWallets, setLoadingWallets] = useState(isEmpty(user.wallets));
  const [isLoadingTransactions, setLoadingTransactions] = useState(isEmpty(user.transactions));
  const [isEditUserProfileModalVisible, setEditUserProfileModalVisible] = useState(false);
  const [isEditUserAddressModalVisible, setEditUserAddressModalVisible] = useState(false);
  const [isUploadKycModalVisible, setUploadKycModalVisible] = useState(false);
  const [isAddBankAccountModalVisible, setAddBankAccountModalVisible] = useState(false);
  const [isCashoutModalVisible, setCashoutModalVisible] = useState(false);
  const [walletToCashout, setWalletToCashout] = useState(undefined);
  const [isAddWalletModalVisible, setAddWalletModalVisible] = useState(false);

  const {
    id,
    externalIds = [],
    mangoId: userMangoId,
    userProfile: {
      firstName,
      lastName,
      birthday,
      nationality,
      residency,
    } = {},
    companyProfile: {
      name: companyName,
    } = {},
    email,
    formattedPhoneNumber,
    signupDate,
    lastLoginDate,
    averageReviewScore = 0,
    nbTransactionsAsBuyer = 0,
    onGoingPurchaseCount = 0,
    nbTransactionsAsSeller = 0,
    onGoingSellCount = 0,
    successfulSellCount = 0,
    cancelledSellCount = 0,
    disputedSellCount = 0,
    successfulPurchaseCount = 0,
    cancelledPurchaseCount = 0,
    disputedPurchaseCount = 0,
    locked,
    suspicious,
    canBeDeleted,
    address: {
      recipientName,
      formattedAddress,
    } = {},
    kycVerified = STATUS_NOT_VERIFIED,
    wallets = [],
    paymentCards = [],
    bankAccounts = [],
    transactions = [],
    userPspAccounts = [],
    lang,
  } = user;

  useEffect(() => {
    getUser(id)
      .finally(() => {
        setLoading(false);
      });
    getUserWallets(id)
      .finally(() => {
        setLoadingWallets(false);
      });
    getUserTransactions(id)
      .finally(() => {
        setLoadingTransactions(false);
      });
  }, [
    id,
    getUser,
    getUserWallets,
    getUserTransactions,
  ]);

  const canEdit = isTripartie && isSupport;
  const canSeePspEntityIds = isTripartie && isSupport;
  const canCashout = !isEmpty(bankAccounts) && kycVerified === STATUS_VERIFIED && canEdit;

  return (
    <div className="d-flex flex-column align-items-center mt-3">
      {isLoading && (<Spinner as="span" animation="border" />)}

      {user.email && (
        <>
          <EditUserProfileModal
            show={isEditUserProfileModalVisible}
            onHide={() => { setEditUserProfileModalVisible(false); }}
            user={user}
            onSubmit={(values) => updateUser(id, values)
              .then(() => {
                setEditUserProfileModalVisible(false);
              })}
          />
          <EditUserAddressModal
            show={isEditUserAddressModalVisible}
            onHide={() => { setEditUserAddressModalVisible(false); }}
            user={user}
            onSubmit={(values) => updateUserAddress(id, values)
              .then(() => {
                setEditUserAddressModalVisible(false);
              })}
          />
          <UploadKycModal
            show={isUploadKycModalVisible}
            onHide={() => { setUploadKycModalVisible(false); }}
            onSubmit={(values) => uploadKyc(id, values)
              .then(() => {
                setUploadKycModalVisible(false);
              })}
          />
          <AddBankAccountModal
            show={isAddBankAccountModalVisible}
            onHide={() => { setAddBankAccountModalVisible(false); }}
            user={user}
            onSubmit={(values) => addBankAccount(id, values)
              .then(() => {
                setAddBankAccountModalVisible(false);
              })}
          />
          <CashoutModal
            show={isCashoutModalVisible}
            onHide={() => {
              setCashoutModalVisible(false);
              setWalletToCashout(undefined);
            }}
            user={user}
            wallet={walletToCashout}
            onSubmit={(values) => cashoutWallet(id, values)
              .then(() => {
                setCashoutModalVisible(false);
                setWalletToCashout(undefined);
              })}
          />
          <AddWalletModal
            show={isAddWalletModalVisible}
            onHide={() => {
              setAddWalletModalVisible(false);
            }}
            user={user}
            onSubmit={(currency) => (
              addWallet(id, currency)
                .then(() => {
                  setAddWalletModalVisible(false);
                })
            )}
          />
        </>
      )}

      {canEdit && (
        <DataCard title={t('actions.cardTitle')}>
          {locked ? (
            <ButtonWithConfirmationModal
              onConfirm={() => (unblockUser(id))}
            >
              {t('actions.unblock')}
            </ButtonWithConfirmationModal>
          ) : (
            <ButtonWithConfirmationModal
              variant="outline-danger"
              onConfirm={() => (blockUser(id))}
            >
              {t('actions.block')}
            </ButtonWithConfirmationModal>
          )}
          {suspicious ? (
            <ButtonWithConfirmationModal
              className="ml-3"
              onConfirm={() => (untagSuspiciousUser(id))}
            >
              {t('actions.untagSuspicious')}
            </ButtonWithConfirmationModal>
          ) : (
            <ButtonWithConfirmationModal
              className="ml-3"
              variant="outline-danger"
              onConfirm={() => (tagSuspiciousUser(id))}
            >
              {t('actions.tagSuspicious')}
            </ButtonWithConfirmationModal>
          )}
          <ButtonWithConfirmationModal
            className="ml-3"
            variant="outline-danger"
            disabled={!canBeDeleted}
            onConfirm={() => (deleteUser(id).then(() => { history.push('/users/'); }))}
            modalMessage={t('actions.deleteUserWarning')}
          >
            {t('actions.deleteUser')}
          </ButtonWithConfirmationModal>
        </DataCard>
      )}

      <DataCard title={t('contact.cardTitle')}>
        <DataCardLine title={t('contact.email')} noTooltip><a href={`mailto:${email}`}>{email}</a></DataCardLine>
        <DataCardLine title={t('contact.phoneNumber')} noTooltip><a href={`tel:${formattedPhoneNumber}`}>{formattedPhoneNumber}</a></DataCardLine>
      </DataCard>

      {canSeePspEntityIds && (
        <DataCard title={t('userPspAccounts.cardTitle')}>
          {userPspAccounts.map(({
            pspSlug,
            pspEntityId,
            kycStatus,
          }) => (
            <DataCardLine title={t(`userPspAccounts.${pspSlug}`)} noTooltip>
              <a
                href={getUserPspUrl(pspSlug, pspEntityId)}
                target="_blank"
                rel="noopener noreferrer"
              >
                {pspEntityId}
              </a>
              <span className="ml-1">
                (
                {t(`userPspAccounts.${kycStatus}`)}
                )
              </span>
            </DataCardLine>
          ))}
        </DataCard>
      )}

      <DataCard
        title={t('main.cardTitle')}
        headerButtons={canEdit && (
          <>
            <Button
              className="mr-3"
              onClick={() => {
                setEditUserProfileModalVisible(true);
              }}
            >
              {t('main.editUserProfile')}
            </Button>
            <Button
              onClick={() => {
                setEditUserAddressModalVisible(true);
              }}
            >
              {t('main.editUserAddress')}
            </Button>
          </>
        )}
      >
        <DataCardLine title={t('main.id')}>{id}</DataCardLine>
        {!isTripartie && <DataCardLine title={t('main.externalIds')}>{externalIds.join(', ')}</DataCardLine>}
        <DataCardLine title={t('main.firstName')}>{firstName}</DataCardLine>
        <DataCardLine title={t('main.lastName')}>{lastName}</DataCardLine>
        <DataCardLine title={t('main.birthday')}>{birthday && <FormattedDate value={new Date(birthday)} />}</DataCardLine>
        <DataCardLine title={t('main.nationality')}>{t(`_countries:nationality.${nationality}`)}</DataCardLine>
        <DataCardLine title={t('main.residency')}>{t(`_countries:country.${residency}`)}</DataCardLine>
        <DataCardLine title={t('main.address')}>{recipientName && formattedAddress && `${recipientName}, ${formattedAddress}`}</DataCardLine>
        <DataCardLine title={t('main.company')}>{companyName || t('main.noCompany')}</DataCardLine>
        <DataCardLine title={t('main.signupDate')}>{signupDate && <FormattedDate value={new Date(signupDate)} dateStyle="short" timeStyle="long" />}</DataCardLine>
        <DataCardLine title={t('main.lastLoginDate')}>{lastLoginDate && <FormattedDate value={new Date(lastLoginDate)} dateStyle="short" timeStyle="long" />}</DataCardLine>
        <DataCardLine title={t('main.averageReviewScore')}>
          <FormattedNumber value={averageReviewScore} maximumFractionDigits={2} />
          {' '}
          / 5
        </DataCardLine>
        <DataCardLine title={t('main.nbTransactionsAsBuyer')}>{nbTransactionsAsBuyer}</DataCardLine>
        <DataCardLine title={t('main.onGoingPurchaseCount')}>{onGoingPurchaseCount}</DataCardLine>
        <DataCardLine title={t('main.successfulPurchaseCount')}>{successfulPurchaseCount}</DataCardLine>
        <DataCardLine title={t('main.cancelledPurchaseCount')}>{cancelledPurchaseCount}</DataCardLine>
        <DataCardLine title={t('main.disputedPurchaseCount')}>{disputedPurchaseCount}</DataCardLine>
        <DataCardLine title={t('main.nbTransactionsAsSeller')}>{nbTransactionsAsSeller}</DataCardLine>
        <DataCardLine title={t('main.onGoingSellCount')}>{onGoingSellCount}</DataCardLine>
        <DataCardLine title={t('main.successfulSellCount')}>{successfulSellCount}</DataCardLine>
        <DataCardLine title={t('main.cancelledSellCount')}>{cancelledSellCount}</DataCardLine>
        <DataCardLine title={t('main.disputedSellCount')}>{disputedSellCount}</DataCardLine>
        <DataCardLine title={t('main.lang')}>{lang}</DataCardLine>
      </DataCard>

      <DataCard
        title={t('status.cardTitle')}
        headerButtons={canEdit && (
          <Button
            onClick={() => {
              setUploadKycModalVisible(true);
            }}
          >
            {t('status.uploadDocument')}
          </Button>
        )}
      >
        <DataCardLine title={t('status.kycVerified')}>{t(`_users:kycVerified.${kycVerified}`)}</DataCardLine>
        <DataCardLine title={t('status.locked')}>{t(`_users:locked.${locked}`)}</DataCardLine>
        <DataCardLine title={t('status.suspicious')}>{t(`_users:suspicious.${suspicious}`)}</DataCardLine>
      </DataCard>

      <DataCard title={t('paymentMethods.cardTitle')}>
        <DataCardList
          title={t('paymentMethods.wallets')}
          addItemButtonLabel={t('paymentMethods.addWallet')}
          addItemButtonOnClick={() => {
            setAddWalletModalVisible(true);
          }}
          items={wallets}
          keyExtractor={({ id }) => (id)}
          renderItem={({
            id,
            pspSlug,
            pspEntityId = '',
            currency = 'EUR',
            balance = 0,
            pendingBalance = 0,
            hasInProgessCashout,
          }) => (
            <>
              <FormattedNumber style="currency" value={balance / 100} currency={currency} />

              {pendingBalance > 0 && (
                <span className="ml-1">
                  (
                  <SpanWithColon className="mr-1">{t('paymentMethods.pendingBalance')}</SpanWithColon>
                  <FormattedNumber style="currency" value={pendingBalance / 100} currency={currency} />
                  )
                </span>
              )}

              {canSeePspEntityIds && pspSlug === MANGOPAY && (
                <span className="ml-1">
                  (
                  <SpanWithColon className="mr-1">{t('paymentMethods.mangoId')}</SpanWithColon>
                  <a href={`${mangoPayBaseUrl}/User/${userMangoId}/Wallets/${pspEntityId}`} target="_blank" rel="noopener noreferrer">{pspEntityId}</a>
                  )
                </span>
              )}

              {canSeePspEntityIds && [LEMONWAY_EUR, LEMONWAY_CHF].includes(pspSlug) && (
                <span className="ml-1">
                  (
                  {t('paymentMethods.lemonway')}
                  )
                </span>
              )}

              {hasInProgessCashout && (
                <>
                  <span className="mx-1">-</span>
                  <span>{t('paymentMethods.cashoutInProgress')}</span>
                </>
              )}

              {!!balance && !hasInProgessCashout && canCashout && (
                <span>
                  <span className="mx-1">-</span>
                  <Button
                    variant="link"
                    className="p-0 shadow-none"
                    onClick={() => {
                      setWalletToCashout({ id });
                      setCashoutModalVisible(true);
                    }}
                  >
                    {t('paymentMethods.cashout')}
                  </Button>
                </span>
              )}
            </>
          )}
          emptyValue={isLoadingWallets ? <Spinner size="sm" as="span" animation="border" /> : t('paymentMethods.noWallet')}
        />
        <DataCardList
          title={t('paymentMethods.paymentCards')}
          items={paymentCards}
          keyExtractor={({ id }) => (id)}
          renderItem={({
            id: cardId,
            pspSlug,
            pspEntityId = '',
            obfuscatedNumber = '',
            expirationDate = '',
            cardProvider = '',
          }) => (
            <span>
              {`${cardProvider} - ${obfuscatedNumber.replaceAll(/(.{4})/g, '$1 ')} - ${expirationDate.replace(/(.{2})(.{2})/g, '$1/$2')}`}

              {canSeePspEntityIds && pspSlug === MANGOPAY && (
                <span className="ml-1">
                  (
                  <SpanWithColon className="mr-1">{t('main.mangoId')}</SpanWithColon>
                  <a href={`${mangoPayBaseUrl}/User/${userMangoId}/Cards/${pspEntityId}`} target="_blank" rel="noopener noreferrer">{pspEntityId}</a>
                  )
                </span>
              )}

              <ButtonWithConfirmationModal
                variant="link"
                className="p-0 ml-1 shadow-none text-danger"
                onConfirm={() => (
                  deleteCard(id, cardId)
                )}
              >
                {t('paymentMethods.delete')}
              </ButtonWithConfirmationModal>
            </span>
          )}
          emptyValue={t('paymentMethods.noCard')}
        />
        <DataCardList
          title={t('paymentMethods.bankAccounts')}
          addItemButtonLabel={t('paymentMethods.addBankAccount')}
          addItemButtonOnClick={() => {
            setAddBankAccountModalVisible(true);
          }}
          items={bankAccounts}
          keyExtractor={({ id }) => (id)}
          renderItem={({
            id: bankAccountId,
            pspSlug,
            pspEntityId = '',
            iban = '',
          }) => (
            <span>
              {iban.replaceAll(/(.{4})/g, '$1 ')}

              {canSeePspEntityIds && pspSlug === MANGOPAY && (
                <span className="ml-1">
                  (
                  <SpanWithColon className="mr-1">{t('main.mangoId')}</SpanWithColon>
                  <a href={`${mangoPayBaseUrl}/User/${userMangoId}/BankAccounts/${pspEntityId}`} target="_blank" rel="noopener noreferrer">{pspEntityId}</a>
                  )
                </span>
              )}

              <ButtonWithConfirmationModal
                variant="link"
                className="p-0 ml-1 shadow-none text-danger"
                onConfirm={() => (
                  deleteBankAccount(id, bankAccountId)
                )}
              >
                {t('paymentMethods.delete')}
              </ButtonWithConfirmationModal>
            </span>
          )}
          emptyValue={t('paymentMethods.noBankAccount')}
        />
      </DataCard>

      <DataCard title={t('lastTransactions.cardTitle')}>
        {isLoadingTransactions ? (
          <Spinner as="span" animation="border" />
        ) : (
          <DataCardList
            items={transactions}
            keyExtractor={({ id }) => (id)}
            renderItem={({
              id,
              title = '',
              subTotal = 0,
              currency = 'EUR',
              lastEventSlug = '',
              lastEventCreatedAt,
            }) => (
              <>
                <Link className="link mr-1" to={`/transactions/${id}`}>{title}</Link>
                -
                <span className="mx-1"><FormattedNumber style="currency" value={subTotal / 100} currency={currency} /></span>
                -
                <span className="ml-1">
                  {t(`_transactions:status.${lastEventSlug}`)}
                  {lastEventCreatedAt && (
                  <span className="ml-1">
                    (
                    <FormattedDate value={new Date(lastEventCreatedAt)} dateStyle="short" timeStyle="long" />
                    )
                  </span>
                  )}
                </span>
              </>
            )}
            emptyValue={t('lastTransactions.noTransactions')}
          />
        )}
      </DataCard>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isTripartie: state.persistent.meReducer.isTripartie,
  isSuperAdmin: state.persistent.meReducer.isSuperAdmin,
  isSupport: state.persistent.meReducer.isSupport,
  canSeeUsersTab: state.persistent.meReducer.canSeeUsersTab,
  users: state.persistent.usersReducer.users,
});

const mapDispatchToProps = (dispatch) => ({
  getUser: bindActionCreators(getUser, dispatch),
  updateUser: bindActionCreators(updateUser, dispatch),
  updateUserAddress: bindActionCreators(updateUserAddress, dispatch),
  blockUser: bindActionCreators(blockUser, dispatch),
  unblockUser: bindActionCreators(unblockUser, dispatch),
  tagSuspiciousUser: bindActionCreators(tagSuspiciousUser, dispatch),
  untagSuspiciousUser: bindActionCreators(untagSuspiciousUser, dispatch),
  deleteUser: bindActionCreators(deleteUser, dispatch),
  getUserWallets: bindActionCreators(getUserWallets, dispatch),
  getUserTransactions: bindActionCreators(getUserTransactions, dispatch),
  uploadKyc: bindActionCreators(uploadKyc, dispatch),
  addBankAccount: bindActionCreators(addBankAccount, dispatch),
  addWallet: bindActionCreators(addWallet, dispatch),
  cashoutWallet: bindActionCreators(cashoutWallet, dispatch),
  deleteCard: bindActionCreators(deleteCard, dispatch),
  deleteBankAccount: bindActionCreators(deleteBankAccount, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserDetails);
