import React, { useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { LANGUAGES_FOR_CLIENTS } from 'helpers/constants';

const ClientFieldsForSuperAdmin = ({ clearOnMount, isEditModal }) => {
  const { t } = useTranslation('clientFieldsForSuperAdmin');

  const {
    values, handleChange, handleBlur, errors, resetForm,
  } = useFormikContext();

  useEffect(() => {
    if (clearOnMount) {
      resetForm();
    }
  }, [clearOnMount, resetForm]);

  return (
    <>
      {!isEditModal && (
      <Form.Row>
        <Form.Group as={Col} controlId="slug">
          <Form.Label>{t('slug')}</Form.Label>
          <Form.Control
            name="slug"
            placeholder={t('slug')}
            value={values.slug}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={!!errors.slug}
          />
          <Form.Control.Feedback type="invalid">
            {errors.slug}
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
      )}
      <Form.Row>
        <Form.Group as={Col} controlId="lang">
          <Form.Label>{t('lang')}</Form.Label>
          <Form.Control
            as="select"
            name="lang"
            placeholder={t('lang')}
            value={values.lang}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={!!errors.lang}
          >
            <option disabled value="">
              {t('languagePlaceholder')}
            </option>
            {LANGUAGES_FOR_CLIENTS.map((language) => (
              <option value={language} key={language}>
                {t(`languages.${language}`)}
              </option>
            ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors.lang}
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="primary">
          <Form.Label>{t('theme.primary')}</Form.Label>
          <Form.Control
            name="theme.primary"
            placeholder={t('theme.primary')}
            value={values.theme.primary}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={!!errors.theme?.primary}
          />
          <Form.Control.Feedback type="invalid">
            {errors.theme?.primary}
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="squareConfig">
          <Form.Label>{t('squareConfig')}</Form.Label>
          <Form.Control
            name="squareConfig"
            placeholder={t('squareConfig')}
            value={values.squareConfig}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={!!errors.squareConfig}
          />
          <Form.Control.Feedback type="invalid">
            {errors.squareConfig}
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="emailConfig">
          <Form.Label>{t('emailConfig')}</Form.Label>
          <Form.Control
            name="emailConfig"
            placeholder={t('emailConfig')}
            value={values.emailConfig}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={!!errors.emailConfig}
          />
          <Form.Control.Feedback type="invalid">
            {errors.emailConfig}
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="platformConfig">
          <Form.Label>{t('platformConfig')}</Form.Label>
          <Form.Control
            name="platformConfig"
            placeholder={t('platformConfig')}
            value={values.platformConfig}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={!!errors.platformConfig}
          />
          <Form.Control.Feedback type="invalid">
            {errors.platformConfig}
          </Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
    </>
  );
};

export default ClientFieldsForSuperAdmin;
