import React, {
  useState,
  useEffect,
} from 'react';
import { FormattedDate } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Spinner from 'react-bootstrap/Spinner';
import * as Roles from 'helpers/adminRolesHelpers';
import { isApi } from 'helpers/env';
import {
  DataCard,
  DataCardLine,
} from 'components/DataCard';
import Button from 'components/Button';
import ButtonWithConfirmationModal from 'components/ButtonWithConfirmationModal';
import EditAdminProfileModal from 'components/EditAdminProfileModal';
import {
  getAdmin,
  updateAdmin,
  blockAdmin,
  unblockAdmin,
  resetPasswordAdmin,
} from 'logic/actions/adminsActions';

const AdminDetails = ({
  meReducer: {
    me = {},
    isSuperAdmin,
    isAdmin,
    isTripartie,
  } = {},
  admins,
  getAdmin,
  updateAdmin,
  blockAdmin,
  unblockAdmin,
  resetPasswordAdmin,
  match: { params: { adminId } = {} } = {},
  history,
}) => {
  useEffect(() => {
    if (!isAdmin) {
      history.push('/');
    }
  }, [isAdmin, history]);

  const { t } = useTranslation([
    'adminDetails',
    '_admins',
  ]);
  const admin = admins[adminId]?.id ? admins[adminId] : { id: adminId };
  const [isLoading, setLoading] = useState(!admin.email);
  const [isEditAdminProfileModalVisible, setEditAdminProfileModalVisible] = useState(false);

  const {
    id,
    userProfile: {
      firstName,
      lastName,
    } = {},
    email,
    role,
    lastLoginDate,
    locked,
    client: {
      name: clientName,
    } = {},
  } = admin;

  const canEdit = me.id !== id && (isSuperAdmin || Roles.gt(me.role, role));

  useEffect(() => {
    setLoading(!email);
    getAdmin(id)
      .finally(() => {
        setLoading(false);
      });
  }, [email, getAdmin, id]);

  return (
    <div>
      {isLoading && (<Spinner as="span" animation="border" />)}

      {admin.email && (
        <EditAdminProfileModal
          show={isEditAdminProfileModalVisible}
          onHide={() => { setEditAdminProfileModalVisible(false); }}
          admin={admin}
          onSubmit={(values) => updateAdmin(id, values)
            .then(() => {
              setEditAdminProfileModalVisible(false);
            })}
        />
      )}

      {isAdmin && (
        <DataCard title={t('actions.cardTitle')}>
          {locked ? (
            <ButtonWithConfirmationModal
              disabled={!canEdit}
              onConfirm={() => (unblockAdmin(id))}
            >
              {t('actions.unblock')}
            </ButtonWithConfirmationModal>
          ) : (
            <ButtonWithConfirmationModal
              variant="outline-danger"
              disabled={!canEdit}
              onConfirm={() => (blockAdmin(id))}
            >
              {t('actions.block')}
            </ButtonWithConfirmationModal>
          )}
          <ButtonWithConfirmationModal
            className="ml-3"
            onConfirm={() => (resetPasswordAdmin(id))}
          >
            {t('actions.resetPassword')}
          </ButtonWithConfirmationModal>
        </DataCard>
      )}

      <DataCard
        title={t('main.cardTitle')}
        headerButtons={canEdit
          && (
          <Button
            onClick={() => {
              setEditAdminProfileModalVisible(true);
            }}
          >
            {t('main.edit')}
          </Button>
          )}
      >
        {isSuperAdmin && <DataCardLine title={t('main.id')}>{id}</DataCardLine>}
        {!isApi && isTripartie && <DataCardLine title={t('main.client')}>{clientName}</DataCardLine>}
        <DataCardLine title={t('main.firstName')}>{firstName}</DataCardLine>
        <DataCardLine title={t('main.lastName')}>{lastName}</DataCardLine>
        <DataCardLine title={t('main.email')}>{email}</DataCardLine>
        <DataCardLine title={t('main.role')}>{t(`_admins:role.${role?.toLowerCase()}`)}</DataCardLine>
        <DataCardLine title={t('main.locked')}>{t(`_admins:locked.${locked}`)}</DataCardLine>
        <DataCardLine title={t('main.lastLoginDate')}>{lastLoginDate && <FormattedDate value={new Date(lastLoginDate)} dateStyle="short" timeStyle="long" />}</DataCardLine>
      </DataCard>
    </div>
  );
};

const mapStateToProps = (state) => ({
  meReducer: state.persistent.meReducer,
  admins: state.persistent.adminsReducer.admins,
});

const mapDispatchToProps = (dispatch) => ({
  getAdmin: bindActionCreators(getAdmin, dispatch),
  updateAdmin: bindActionCreators(updateAdmin, dispatch),
  blockAdmin: bindActionCreators(blockAdmin, dispatch),
  unblockAdmin: bindActionCreators(unblockAdmin, dispatch),
  resetPasswordAdmin: bindActionCreators(resetPasswordAdmin, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminDetails);
