export const ERROR_EMAIL_ALREADY_EXISTS = 'email_already_exists';

export const PREREGISTRATION = 'preregistration';

export const STATUS_VERIFIED = 'status_verified';
export const STATUS_NOT_VERIFIED = 'status_not_verified';

export const MANGOPAY = 'mangopay';
export const LEMONWAY_EUR = 'lemonway_eur';
export const LEMONWAY_CHF = 'lemonway_chf';

export const COMPANY_TYPE_BUSINESS = 'company_type_business';
export const COMPANY_TYPE_ORGANIZATION = 'company_type_organization';
export const COMPANY_TYPE_SOLETRADER = 'company_type_soletrader';
export const COMPANY_TYPES = [
  COMPANY_TYPE_BUSINESS,
  COMPANY_TYPE_ORGANIZATION,
  COMPANY_TYPE_SOLETRADER,
];

export const DOCUMENT_TYPE_IDENTITY_PROOF = 'identity_proof';
export const DOCUMENT_TYPE_ADDRESS_PROOF = 'address_proof';
export const DOCUMENT_TYPE_REGISTRATION_PROOF = 'registration_proof';
export const DOCUMENT_TYPE_ARTICLES_OF_ASSOCIATION = 'articles_of_association';
export const DOCUMENT_TYPE_SHAREHOLDER_DECLARATION = 'shareholder_declaration';
export const DOCUMENT_TYPES = [
  DOCUMENT_TYPE_IDENTITY_PROOF,
  DOCUMENT_TYPE_ADDRESS_PROOF,
  DOCUMENT_TYPE_REGISTRATION_PROOF,
  DOCUMENT_TYPE_ARTICLES_OF_ASSOCIATION,
  DOCUMENT_TYPE_SHAREHOLDER_DECLARATION,
];

export const ERRORS_FROM_SERVER = {
  SLUG_ERROR: 'slug_already_exists',
  ADMIN_CAN_NOT_BE_SELLER: 'admin_user_denied',
};

export const ERRORS = {
  SLUG_ERROR: 'This slug has been already already taken',
};

export const FLOW_OBJECTS = 'objects';
export const FLOW_SERVICES = 'services';

export const CURRENCIES = [
  'EUR',
  'GBP',
  'USD',
  'CHF',
  'AUD',
  'CAD',
  'SEK',
  'NOK',
  'DKK',
  'ZAR',
  'PLN',
];

export const PRODUCT_CATEGORIES = [
  'auto_moto',
  'house_garden',
  'sport',
  'fashion',
  'telecom',
  'other',
];

export const IMAGE_MAX_SIZE_FOR_CROPPER = 600;

export const TRANSACTION_TEMPLATE_IMAGE_MAX_SIZE = 2 * 1024 * 1024;
export const TRANSACTION_TEMPLATE_IMAGE_FORMATS = ['image/png', 'image/jpeg'];

export const TRANSACTION_PAUSE_STATUSES = {
  ACTIVE: 'active',
  ON_PAUSE: 'onPause',
};

export const LANGUAGES_FOR_CLIENTS = ['fr', 'en'];

export const TRANSACTION_EVENTS = {
  CREATE: 'event_transaction_create',
  PREAUTHORIZED: 'event_transaction_preauthorize',
  SENT: 'event_transaction_sent',
  ACCEPT: 'event_transaction_accept',
  REJECT: 'event_transaction_reject',
  SHIP: 'event_transaction_ship',
  DELIVERED: 'event_transaction_delivered',
  COMPLETE: 'event_transaction_complete',
  CANCEL_REJECTED: 'event_transaction_cancel_rejected',
  EXPIRE: 'event_transaction_expire',
  CANCEL: 'event_transaction_cancel',
  PAY: 'event_transaction_pay',
  CANCEL_REQUESTED: 'event_transaction_cancel_requested',
  RETURN_DELIVERED: 'event_transaction_return_delivered',
};

export const SHIPPING_PROVIDERS = [
  {
    label: 'Colissimo',
    value: 'colissimo',
  },
  {
    label: 'TNT',
    value: 'tnt',
  },
  {
    label: 'Chronopost',
    value: 'chronopost-france',
  },
  {
    label: 'Swiss Post',
    value: 'swiss-post',
  },
  {
    label: 'DPD',
    value: 'dpd',
  },
  {
    label: 'UPS',
    value: 'ups',
  },
  {
    label: 'DHL',
    value: 'dhl',
  },
  {
    label: 'FedEx',
    value: 'fedex',
  },
  {
    label: 'Mondial Relay',
    value: 'mondial-relay',
  },
];

export const CANCEL_REJECT_REASONS = ['alreadyShipped', 'alreadyDelivered'];

export const REJECT_OFFER_REASONS_KEYS = [
  'alreadySold',
  'acceptedAnotherOffer',
  'notSatisfied',
  'other',
];

export const DOES_NOT_MATCH = 'does_not_match';
export const DAMAGED = 'damaged';
export const INCOMPLETE = 'incomplete';

export const MISSING_REFRESH_TOKEN = 'missing_refresh_token';

export const BASE_NS = 'base';
export const TYPE_TEXTAREA = 'textarea';

// New dispute constants
export const BUYER = 'buyer';
export const SELLER = 'seller';
export const RESPONSE_ACCEPT = 'accept';
export const RESPONSE_REJECT = 'reject';
export const RESPONSE_ACCEPT_REGEXP = `^((${BUYER}|${SELLER})_)?${RESPONSE_ACCEPT}$`;
export const RESPONSE_REJECT_REGEXP = `^((${BUYER}|${SELLER})_)?${RESPONSE_REJECT}$`;

export const SOLUTION_PARTIAL_REFUND = 'partial_refund';
export const SOLUTION_FULL_REFUND = 'full_refund';

export const STATUS_ARBITRATION = 'arbitration';
