import * as React from 'react';

const SvgTabTemplates = ({
  color = '#aab6be',
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 45.35 45.5"
    stroke={color}
    {...props}
  >
    <path
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5px"
      fillRule="evenodd"
      d="M34 29.8c8.38-.72 10.62-4.23 10.62-14.45C44.6 3.67 41.68.75 30 .75s-14.6 2.92-14.6 14.6c0 10.2 2.23 13.72 10.58 14.45"
    />
    <path
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5px"
      fillRule="evenodd"
      d="M11.37 15.7C3 16.42.75 19.93.75 30.15c0 11.68 2.92 14.6 14.6 14.6C27 44.75 30 41.83 30 30.15 30 20 27.72 16.43 19.37 15.7"
    />
  </svg>
);

export default SvgTabTemplates;
