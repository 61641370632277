import React, {
  useState,
  useEffect,
  useMemo,
} from 'react';
import {
  FormattedDate,
  FormattedNumber,
  useIntl,
} from 'react-intl';
import isEqual from 'lodash/isEqual';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { saveAs } from 'file-saver';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  PREREGISTRATION,
  BASE_NS,
  TYPE_TEXTAREA,
  SOLUTION_PARTIAL_REFUND,
  SOLUTION_FULL_REFUND,
  STATUS_ARBITRATION,
  TRANSACTION_EVENTS,
} from 'helpers/constants';
import { cleanResponseSlug } from 'helpers/cleanSlugs';
import classes from 'assets/style/disputeDetails.module.scss';
import {
  DataCard,
  DataCardLine,
} from 'components/DataCard';
import {
  setReturnAddress,
} from 'logic/actions/transactionsActions';
import {
  getDispute,
  startReturn,
  closeDispute,
  cancelDispute,
} from 'logic/actions/disputesActions';
import { fetchMediaFile } from 'logic/actions/mediaActions';
import DocumentPreview from 'components/DocumentPreview';
import ButtonWithConfirmationModal from 'components/ButtonWithConfirmationModal';
import CloseDisputeModal from 'components/CloseDisputeModal';
import Button from 'components/Button';
import EditAddressModal from 'components/EditAddressModal';

const DisputeDetails = ({
  isSupport,
  disputes,
  getDispute,
  fetchMediaFile,
  startReturn,
  closeDispute,
  cancelDispute,
  match: { params: { disputeId } = {} } = {},
  history,
  canSeeDisputesTab,
  canSeeTransactionsTab,
  setReturnAddress,
}) => {
  useEffect(() => {
    if (!canSeeDisputesTab) {
      history.push('/');
    }
  }, [canSeeDisputesTab, history]);

  const intl = useIntl();
  const { t } = useTranslation([
    'disputeDetails',
    '_disputes',
    '_transactions',
    '_templates',
    '_shippings',
  ]);
  const dispute = disputes[disputeId]?.id ? disputes[disputeId] : { id: disputeId };
  const [isLoading, setLoading] = useState(!dispute.title);

  const [showManualCloseDisputeModal, setShowManualCloseDisputeModal] = useState(false);
  const [showEditReturnDeliveryAddressModal, setShowEditReturnDeliveryAddressModal] = useState(false);

  useEffect(() => {
    getDispute(disputeId)
      .finally(() => {
        setLoading(false);
      });
  }, [getDispute, disputeId]);

  const {
    id,
    title,
    description,
    createdAt,
    currency = 'EUR',
    subTotal = 0,
    sellerTotal = 0,
    cancelledAt,
    events = [],
    returnDeliveryAddress = {},
    issue: {
      status,
      type,
      openedAt,
      arbitrationStartedAt,
      closedAt,
      messages: [
        rawBuyerRequest,
        rawSellerResponse,
        rawBuyerResponse,
      ] = [],
    } = {},
    shipping: {
      status: shippingStatus,
      trackingNumber,
      trackingId,
      shippingProvider,
    } = {},
    buyer: {
      id: buyerId,
      accountStatus: buyerAccountStatus,
      userProfile: {
        firstName: buyerFirstName,
        lastName: buyerLastName,
      } = {},
      email: buyerEmail,
      formattedPhoneNumber: buyerFormattedPhoneNumber,
      averageReviewScore: buyerAverageReviewScore,
      nbTransactionsAsBuyer: buyerNbTransactionsAsBuyer,
      nbTransactionsAsSeller: buyerNbTransactionsAsSeller,
      successfulSellCount: buyerSuccessfulSellCount,
      onGoingSellCount: buyerOnGoingSellCount,
      cancelledSellCount: buyerCancelledSellCount,
      disputedSellCount: buyerDisputedSellCount,
      successfulPurchaseCount: buyerSuccessfulPurchaseCount,
      cancelledPurchaseCount: buyerCancelledPurchaseCount,
      disputedPurchaseCount: buyerDisputedPurchaseCount,
      onGoingPurchaseCount: buyerOnGoingPurchaseCount,
    } = {},
    seller: {
      id: sellerId,
      accountStatus: sellerAccountStatus,
      userProfile: {
        firstName: sellerFirstName,
        lastName: sellerLastName,
      } = {},
      email: sellerEmail,
      formattedPhoneNumber: sellerFormattedPhoneNumber,
      averageReviewScore: sellerAverageReviewScore,
      nbTransactionsAsBuyer: sellerNbTransactionsAsBuyer,
      nbTransactionsAsSeller: sellerNbTransactionsAsSeller,
      successfulSellCount: sellerSuccessfulSellCount,
      onGoingSellCount: sellerOnGoingSellCount,
      cancelledSellCount: sellerCancelledSellCount,
      disputedSellCount: sellerDisputedSellCount,
      successfulPurchaseCount: sellerSuccessfulPurchaseCount,
      cancelledPurchaseCount: sellerCancelledPurchaseCount,
      disputedPurchaseCount: sellerDisputedPurchaseCount,
      onGoingPurchaseCount: sellerOnGoingPurchaseCount,
    } = {},
  } = dispute;

  const buyerRequest = useMemo(() => ({
    ...rawBuyerRequest,
    data: rawBuyerRequest?.data || [],
    medias: rawBuyerRequest?.medias || [],
  }), [rawBuyerRequest]);

  const sellerResponse = useMemo(() => ({
    ...rawSellerResponse,
    data: rawSellerResponse?.data || [],
    medias: rawSellerResponse?.medias || [],
  }), [rawSellerResponse]);

  const buyerResponse = useMemo(() => ({
    ...rawBuyerResponse,
    data: rawBuyerResponse?.data || [],
    medias: rawBuyerResponse?.medias || [],
  }), [rawBuyerResponse]);

  const [previousBuyerRequestRawMedias, setPreviousBuyerRequestRawMedias] = useState([]);
  const [buyerRequestMedias, setBuyerRequestMedias] = useState([]);

  const [previousSellerResponseRawMedias, setPreviousSellerResponseRawMedias] = useState([]);
  const [sellerResponseMedias, setSellerResponseMedias] = useState([]);

  const [previousBuyerResponseRawMedias, setPreviousBuyerResponseRawMedias] = useState([]);
  const [buyerResponseMedias, setBuyerResponseMedias] = useState([]);

  useEffect(() => {
    if (!isEqual(buyerRequest.medias, previousBuyerRequestRawMedias)) {
      setPreviousBuyerRequestRawMedias(buyerRequest.medias);
      setBuyerRequestMedias([]);
      buyerRequest.medias.forEach(({ id, comment }) => {
        fetchMediaFile(id)
          .then(({ payload: { data: media } = {} }) => {
            setBuyerRequestMedias((prev) => [...prev, { id, media, comment }]);
          })
          .catch(() => {});
      });
    }
  }, [buyerRequest.medias, fetchMediaFile, previousBuyerRequestRawMedias]);

  useEffect(() => {
    if (!isEqual(sellerResponse.medias, previousSellerResponseRawMedias)) {
      setPreviousSellerResponseRawMedias(sellerResponse.medias);
      setSellerResponseMedias([]);
      sellerResponse.medias.forEach(({ id, comment }) => {
        fetchMediaFile(id)
          .then(({ payload: { data: media } = {} }) => {
            setSellerResponseMedias((prev) => [...prev, { id, media, comment }]);
          })
          .catch(() => {});
      });
    }
  }, [sellerResponse.medias, fetchMediaFile, previousSellerResponseRawMedias]);

  useEffect(() => {
    if (!isEqual(buyerResponse.medias, previousBuyerResponseRawMedias)) {
      setPreviousBuyerResponseRawMedias(buyerResponse.medias);
      setBuyerResponseMedias([]);
      buyerResponse.medias.forEach(({ id, comment }) => {
        fetchMediaFile(id)
          .then(({ payload: { data: media } = {} }) => {
            setBuyerResponseMedias((prev) => [...prev, { id, media, comment }]);
          })
          .catch(() => {});
      });
    }
  }, [buyerResponse.medias, fetchMediaFile, previousBuyerResponseRawMedias]);

  const buyerIsRegistered = buyerAccountStatus !== PREREGISTRATION;
  const sellerIsRegistered = sellerAccountStatus !== PREREGISTRATION;

  const buttons = useMemo(() => {
    const returnWasProposed = buyerRequest.proposedSolution?.includes(SOLUTION_FULL_REFUND)
      || sellerResponse.proposedSolution?.includes(SOLUTION_FULL_REFUND);

    const packageWasReturned = !!events.find(({ slug }) => (slug === TRANSACTION_EVENTS.RETURN_DELIVERED));

    return (
      <div className="d-flex flex-column">
        <CloseDisputeModal
          show={showManualCloseDisputeModal}
          onHide={() => { setShowManualCloseDisputeModal(false); }}
          total={sellerTotal}
          onSubmit={(data) => (
            closeDispute(id, data).then(() => {
              setShowManualCloseDisputeModal(false);
            })
          )}
        />

        {packageWasReturned ? (
          <Button
            onClick={() => { setShowManualCloseDisputeModal(true); }}
          >
            {t('actions.manualClose')}
          </Button>
        ) : (
          <>
            <ButtonWithConfirmationModal
              withComment
              modalMessage={t('actions.confirmModalMessage')}
              onConfirm={(comment) => (
                buyerRequest.proposedSolution?.includes(SOLUTION_FULL_REFUND)
                  ? startReturn(id, { comment })
                  : closeDispute(id, {
                    comment,
                    refund: buyerRequest.proposedRefund,
                  })
              )}
            >
              {t('actions.acceptBuyerSolution', {
                solution: t(`_disputes:solution.${buyerRequest.proposedSolution}`, {
                  discount: intl.formatNumber(buyerRequest.proposedRefund / 100, {
                    style: 'currency',
                    currency,
                  }),
                }),
              })}
            </ButtonWithConfirmationModal>
            {sellerResponse.proposedSolution && (
              <ButtonWithConfirmationModal
                className="mt-2"
                withComment
                modalMessage={t('actions.confirmModalMessage')}
                onConfirm={(comment) => (
                  sellerResponse.proposedSolution?.includes(SOLUTION_FULL_REFUND)
                    ? startReturn(id, { comment })
                    : closeDispute(id, {
                      comment,
                      refund: sellerResponse.proposedRefund,
                    })
                )}
              >
                {t('actions.acceptSellerSolution', {
                  solution: t(`_disputes:solution.${sellerResponse.proposedSolution}`, {
                    discount: intl.formatNumber(sellerResponse.proposedRefund / 100, {
                      style: 'currency',
                      currency,
                    }),
                  }),
                })}
              </ButtonWithConfirmationModal>
            )}
            <ButtonWithConfirmationModal
              className="mt-2"
              withComment
              modalMessage={t('actions.confirmModalMessage')}
              onConfirm={(comment) => (cancelDispute(id, { comment }))}
            >
              {t('actions.cancelDispute')}
            </ButtonWithConfirmationModal>
            {!returnWasProposed && (
              <ButtonWithConfirmationModal
                className="mt-2"
                withComment
                modalMessage={t('actions.confirmModalMessage')}
                onConfirm={(comment) => (startReturn(id, { comment }))}
              >
                {t('actions.forceReturn')}
              </ButtonWithConfirmationModal>
            )}
          </>
        )}
      </div>
    );
  }, [
    events,
    sellerTotal,
    showManualCloseDisputeModal,
    buyerRequest.proposedSolution,
    buyerRequest.proposedRefund,
    sellerResponse.proposedSolution,
    sellerResponse.proposedRefund,
    id,
    startReturn,
    cancelDispute,
    closeDispute,
    currency,
    intl,
    t,
  ]);

  return (
    <div className={classes.container}>
      {isLoading && (<Spinner as="span" animation="border" />)}

      <EditAddressModal
        address={returnDeliveryAddress}
        show={showEditReturnDeliveryAddressModal}
        onHide={() => { setShowEditReturnDeliveryAddressModal(false); }}
        onSubmit={(data) => (
          setReturnAddress(id, data)
            .then(() => {
              setShowEditReturnDeliveryAddressModal(false);
            })
            .catch(() => {
              toast.error(t('errors.submitError'));
            })
        )}
      />

      {isSupport && status === STATUS_ARBITRATION && (
        <DataCard title={t('actions.cardTitle')}>
          {buttons}
        </DataCard>
      )}

      <DataCard title={t('main.cardTitle')}>
        <DataCardLine title={t('main.status')}>{t(`_disputes:status.${status}`)}</DataCardLine>
        <DataCardLine title={t('transaction.title')}>{title}</DataCardLine>
        <DataCardLine title={t('main.type')}>{t(`_disputes:base.issue_type.answers.${type}`)}</DataCardLine>
        <DataCardLine title={t('main.openedAt')}>
          {openedAt && <FormattedDate value={new Date(openedAt)} dateStyle="short" timeStyle="long" />}
        </DataCardLine>
        {arbitrationStartedAt && (
          <DataCardLine title={t('main.arbitrationStartedAt')}>
            <FormattedDate value={new Date(arbitrationStartedAt)} dateStyle="short" timeStyle="long" />
          </DataCardLine>
        )}
        {closedAt && (
          <DataCardLine title={t('main.closedAt')}>
            <FormattedDate value={new Date(closedAt)} dateStyle="short" timeStyle="long" />
          </DataCardLine>
        )}
        {cancelledAt && (
          <DataCardLine title={t('main.cancelledAt')}>
            <FormattedDate value={new Date(cancelledAt)} dateStyle="short" timeStyle="long" />
          </DataCardLine>
        )}
        {/* {buyerRequest.data.map(({
          type,
          slug,
          response,
          ns,
          parentNs,
        }) => (
          <DataCardLine key={slug} title={t(`_disputes:${ns || parentNs || BASE_NS}.${slug}.title`)}>
            {type === TYPE_TEXTAREA
              ? (response)
              : (t(`_disputes:${ns || parentNs || BASE_NS}.${slug}.answers.${cleanResponseSlug(response)}`))}
          </DataCardLine>
        ))} */}
        {buyerRequestMedias.map(({ id, media, comment } = {}) => (
          <div
            className={classes.media}
            key={id}
          >
            <DocumentPreview
              id={id}
              file={media}
              onDownload={(file) => {
                saveAs(file);
              }}
            />
            <p>{comment}</p>
          </div>
        ))}
      </DataCard>

      <DataCard
        title={t('transaction.cardTitle')}
        headerButtons={canSeeTransactionsTab ? (
          <Link
            to={`/transactions/${id}`}
          >
            {t('transaction.seeMore')}
          </Link>
        ) : <div />}
      >
        <DataCardLine title={t('transaction.title')}>{title}</DataCardLine>
        <DataCardLine title={t('transaction.subTotal')}><FormattedNumber style="currency" value={subTotal / 100} currency={currency} /></DataCardLine>
        <DataCardLine title={t('transaction.description')}>{description}</DataCardLine>
        <DataCardLine title={t('transaction.createdOn')}>
          {createdAt && <FormattedDate value={new Date(createdAt)} dateStyle="short" timeStyle="long" />}
        </DataCardLine>
      </DataCard>

      {buyerRequest.createdAt && (
        <DataCard title={t('buyerRequest.cardTitle')}>
          <DataCardLine title={t('buyerRequest.createdAt')}>
            <FormattedDate value={new Date(buyerRequest.createdAt)} dateStyle="short" timeStyle="long" />
          </DataCardLine>
          <DataCardLine title={t('buyerRequest.proposedSolution')}>{t(`_disputes:solution.${buyerRequest.proposedSolution}`)}</DataCardLine>
          {buyerRequest.proposedSolution === SOLUTION_PARTIAL_REFUND && (
            <DataCardLine title={t('buyerRequest.proposedRefund')}>
              <FormattedNumber style="currency" value={buyerRequest.proposedRefund / 100} currency={currency} />
            </DataCardLine>
          )}
        </DataCard>
      )}

      {sellerResponse.createdAt && (
        <DataCard title={t('sellerResponse.cardTitle')}>
          <DataCardLine title={t('sellerResponse.createdAt')}>
            <FormattedDate value={new Date(sellerResponse.createdAt)} dateStyle="short" timeStyle="long" />
          </DataCardLine>
          {sellerResponse.response ? (
            <DataCardLine title={t('sellerResponse.response')}>{t(`_disputes:response.${sellerResponse.response}`)}</DataCardLine>
          ) : (
            <>
              <DataCardLine title={t('sellerResponse.proposedSolution')}>{t(`_disputes:solution.${sellerResponse.proposedSolution}`)}</DataCardLine>
              {sellerResponse.proposedSolution === SOLUTION_PARTIAL_REFUND && (
                <DataCardLine title={t('sellerResponse.proposedRefund')}>
                  <FormattedNumber style="currency" value={sellerResponse.proposedRefund / 100} currency={currency} />
                </DataCardLine>
              )}
            </>
          )}

          {sellerResponse.data.map(({
            type,
            slug,
            response,
            ns,
            parentNs,
          }) => (
            <DataCardLine key={slug} title={t(`_disputes:${ns || parentNs || BASE_NS}.${slug}.title`)}>
              {type === TYPE_TEXTAREA
                ? (response)
                : (t(`_disputes:${ns || parentNs || BASE_NS}.${slug}.answers.${cleanResponseSlug(response)}`))}
            </DataCardLine>
          ))}
          {sellerResponseMedias.map(({ id, media, comment } = {}) => (
            <div
              className={classes.media}
              key={id}
            >
              <DocumentPreview
                id={id}
                file={media}
                onDownload={(file) => {
                  saveAs(file);
                }}
              />
              <p>{comment}</p>
            </div>
          ))}
        </DataCard>
      )}

      {buyerResponse.createdAt && (
        <DataCard title={t('buyerResponse.cardTitle')}>
          <DataCardLine title={t('buyerResponse.createdAt')}>
            <FormattedDate value={new Date(buyerResponse.createdAt)} dateStyle="short" timeStyle="long" />
          </DataCardLine>
          <DataCardLine title={t('buyerResponse.response')}>{t(`_disputes:response.${buyerResponse.response}`)}</DataCardLine>

          {buyerResponse.data.map(({
            type,
            slug,
            response,
            ns,
            parentNs,
          }) => (
            <DataCardLine key={slug} title={t(`_disputes:${ns || parentNs || BASE_NS}.${slug}.title`)}>
              {type === TYPE_TEXTAREA
                ? (response)
                : (t(`_disputes:${ns || parentNs || BASE_NS}.${slug}.answers.${cleanResponseSlug(response)}`))}
            </DataCardLine>
          ))}
          {buyerResponseMedias.map(({ id, media, comment } = {}) => (
            <div
              className={classes.media}
              key={id}
            >
              <DocumentPreview
                id={id}
                file={media}
                onDownload={(file) => {
                  saveAs(file);
                }}
              />
              <p>{comment}</p>
            </div>
          ))}
        </DataCard>
      )}

      {returnDeliveryAddress?.formattedAddress && (
        <DataCard title={t('shipping.cardTitle')}>
          <DataCardLine
            title={t('shipping.returnDeliveryAddress')}
            onEdit={() => {
              setShowEditReturnDeliveryAddressModal(true);
            }}
          >
            {returnDeliveryAddress?.formattedAddress}
          </DataCardLine>
          <DataCardLine title={t('shipping.status')}>{t(`_shippings:status.${shippingStatus}`)}</DataCardLine>
          <DataCardLine title={t('shipping.provider')} noTooltip>{shippingProvider}</DataCardLine>
          <DataCardLine title={t('shipping.trackingNumber')} noTooltip>
            {trackingNumber ? (
              <a
                href={`https://tripartie.aftership.com/${trackingNumber}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {trackingNumber}
              </a>
            ) : (
              <span>{t('shipping.nc')}</span>
            )}
          </DataCardLine>
          <DataCardLine title={t('shipping.trackingProvider')} noTooltip>
            {trackingId ? (
              <a
                href={`https://admin.aftership.com/shipments/${trackingId}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Aftership
              </a>
            ) : (
              <span>{t('shipping.na')}</span>
            )}
          </DataCardLine>
        </DataCard>
      )}

      <DataCard
        title={t('buyer.cardTitle')}
        headerButtons={buyerIsRegistered && (
          <Link
            to={`/users/${buyerId}`}
          >
            {t('buyer.seeProfile')}
          </Link>
        )}
      >
        <DataCardLine title={t('buyer.id')}>{buyerId}</DataCardLine>
        <DataCardLine title={t('buyer.firstName')}>{buyerFirstName}</DataCardLine>
        <DataCardLine title={t('buyer.lastName')}>{buyerLastName}</DataCardLine>
        <DataCardLine title={t('buyer.email')}>{buyerEmail}</DataCardLine>
        <DataCardLine title={t('buyer.formattedPhoneNumber')}>{buyerFormattedPhoneNumber}</DataCardLine>
        <DataCardLine title={t('buyer.averageReviewScore')}>
          <FormattedNumber value={buyerAverageReviewScore} maximumFractionDigits={2} />
          {' '}
          / 5
        </DataCardLine>
        <DataCardLine title={t('buyer.nbTransactionsAsBuyer')}>{buyerNbTransactionsAsBuyer}</DataCardLine>
        <DataCardLine title={t('buyer.buyerOnGoingPurchaseCount')}>{buyerOnGoingPurchaseCount}</DataCardLine>
        <DataCardLine title={t('buyer.successfulPurchaseCount')}>{buyerSuccessfulPurchaseCount}</DataCardLine>
        <DataCardLine title={t('buyer.cancelledPurchaseCount')}>{buyerCancelledPurchaseCount}</DataCardLine>
        <DataCardLine title={t('buyer.disputedPurchaseCount')}>{buyerDisputedPurchaseCount}</DataCardLine>
        <DataCardLine title={t('buyer.nbTransactionsAsSeller')}>{buyerNbTransactionsAsSeller}</DataCardLine>
        <DataCardLine title={t('buyer.buyerOnGoingSellCount')}>{buyerOnGoingSellCount}</DataCardLine>
        <DataCardLine title={t('buyer.successfulSellCount')}>{buyerSuccessfulSellCount}</DataCardLine>
        <DataCardLine title={t('buyer.cancelledSellCount')}>{buyerCancelledSellCount}</DataCardLine>
        <DataCardLine title={t('buyer.disputedSellCount')}>{buyerDisputedSellCount}</DataCardLine>
      </DataCard>

      <DataCard
        title={t('seller.cardTitle')}
        headerButtons={sellerIsRegistered && (
          <Link
            to={`/users/${sellerId}`}
          >
            {t('seller.seeProfile')}
          </Link>
        )}
      >
        <DataCardLine title={t('seller.id')}>{sellerId}</DataCardLine>
        <DataCardLine title={t('seller.firstName')}>{sellerFirstName}</DataCardLine>
        <DataCardLine title={t('seller.lastName')}>{sellerLastName}</DataCardLine>
        <DataCardLine title={t('seller.email')}>{sellerEmail}</DataCardLine>
        <DataCardLine title={t('seller.formattedPhoneNumber')}>{sellerFormattedPhoneNumber}</DataCardLine>
        <DataCardLine title={t('seller.averageReviewScore')}>
          <FormattedNumber value={sellerAverageReviewScore} maximumFractionDigits={2} />
          {' '}
          / 5
        </DataCardLine>
        <DataCardLine title={t('seller.nbTransactionsAsBuyer')}>{sellerNbTransactionsAsBuyer}</DataCardLine>
        <DataCardLine title={t('seller.buyerOnGoingPurchaseCount')}>{sellerOnGoingPurchaseCount}</DataCardLine>
        <DataCardLine title={t('seller.successfulPurchaseCount')}>{sellerSuccessfulPurchaseCount}</DataCardLine>
        <DataCardLine title={t('seller.cancelledPurchaseCount')}>{sellerCancelledPurchaseCount}</DataCardLine>
        <DataCardLine title={t('seller.disputedPurchaseCount')}>{sellerDisputedPurchaseCount}</DataCardLine>
        <DataCardLine title={t('seller.nbTransactionsAsSeller')}>{sellerNbTransactionsAsSeller}</DataCardLine>
        <DataCardLine title={t('seller.buyerOnGoingSellCount')}>{sellerOnGoingSellCount}</DataCardLine>
        <DataCardLine title={t('seller.successfulSellCount')}>{sellerSuccessfulSellCount}</DataCardLine>
        <DataCardLine title={t('seller.cancelledSellCount')}>{sellerCancelledSellCount}</DataCardLine>
        <DataCardLine title={t('seller.disputedSellCount')}>{sellerDisputedSellCount}</DataCardLine>
      </DataCard>

      {events && (
        <DataCard title={t('events.cardTitle')}>
          {events.filter(({ slug }) => (slug !== TRANSACTION_EVENTS.CREATE)).map(({ date, slug }) => (
            <DataCardLine key={slug} title={t(`_transactions:status.${slug}`)} className="justify-content-between">
              <FormattedDate value={new Date(date)} dateStyle="short" timeStyle="long" />
            </DataCardLine>
          ))}
        </DataCard>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isSupport: state.persistent.meReducer.isSupport,
  canSeeTransactionsTab: state.persistent.meReducer.canSeeTransactionsTab,
  canSeeDisputesTab: state.persistent.meReducer.canSeeDisputesTab,
  disputes: state.persistent.disputesReducer.disputes,
});

const mapDispatchToProps = (dispatch) => ({
  getDispute: bindActionCreators(getDispute, dispatch),
  fetchMediaFile: bindActionCreators(fetchMediaFile, dispatch),
  startReturn: bindActionCreators(startReturn, dispatch),
  closeDispute: bindActionCreators(closeDispute, dispatch),
  cancelDispute: bindActionCreators(cancelDispute, dispatch),
  setReturnAddress: bindActionCreators(setReturnAddress, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DisputeDetails);
