import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import mapValues from 'lodash/mapValues';
import invert from 'lodash/invert';
import omitBy from 'lodash/omitBy';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import { FormattedNumber, FormattedDate } from 'react-intl';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { isApi } from 'helpers/env';
import DataList from 'components/DataList';
import Pagination from 'components/Pagination';
import DataListLimitSelector from 'components/DataListLimitSelector';
import QueryForm from 'components/QueryForm';
import ExportTemplatesModal from 'components/ExportTemplatesModal';
import Button from 'components/Button';
import useModal from 'hooks/useModal';
import CreateTransactionTemplateModal from 'components/CreateTransactionTemplateModal';
import FilterClientsModal from 'components/FilterClientsModal';
import {
  getTemplates,
  exportTemplates, createTransactionTemplate,
} from 'logic/actions/templatesActions';
import { bindActionCreators } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import { selectClientId } from 'logic/actions/meActions';
import { toast } from 'react-toastify';
import { ERRORS_FROM_SERVER } from 'helpers/constants';

const TemplatesList = ({
  dataListLimit,
  isTripartie,
  pages,
  nbPages,
  getTemplates = null,
  exportTemplates = null,
  location,
  history,
  canSeeTransactionLinksTab,
}) => {
  useEffect(() => {
    if (!canSeeTransactionLinksTab) {
      history.push('/');
    }
  }, [canSeeTransactionLinksTab, history]);

  const { t } = useTranslation([
    'templatesList',
    '_templates',
  ]);

  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searching, setSearching] = useState(false);
  const [isExportTemplatesModalVisible, setExportTemplatesModalVisible] = useState(false);
  const [isFilterClientsModalVisible, setFilterClientsModalVisible] = useState(false);

  const [query, setQuery] = useState(queryString.parse(location.search));
  const {
    search,
    clientSlugs,
  } = query;

  const userExtractor = ({
    isPreregistration,
    email,
    userProfile: {
      firstName,
      lastName,
    } = {},
  }) => ((isPreregistration || !firstName) ? email : `${firstName} ${lastName}`);

  const columns = [
    {
      name: t('columns.id'),
      extractor: ({ id }) => (id),
    },
    {
      name: t('columns.externalId'),
      extractor: ({ externalId }) => (externalId),
    },
    {
      name: t('columns.title'),
      extractor: ({ title }) => (title),
      processLongWords: true,
    },
    {
      name: t('columns.seller'),
      extractor: ({ seller }) => (userExtractor(seller)),
    },
    {
      name: t('columns.subTotal'),
      extractor: ({ subTotal = 0, currency = 'EUR' }) => (<FormattedNumber value={subTotal / 100} style="currency" currency={currency} />),
    },
    {
      name: t('columns.fees'),
      extractor: ({ totalFees = 0, currency = 'EUR' }) => (<FormattedNumber value={totalFees / 100} style="currency" currency={currency} />),
    },
    {
      name: t('columns.creationDate'),
      extractor: ({ createdAt }) => (createdAt && <FormattedDate value={new Date(createdAt)} />),
      className: 'align-right',
    },
    {
      name: t('columns.active'),
      extractor: ({ active }) => (t(`_templates:active.${active}`)),
    },
  ];

  if (!isApi && isTripartie) {
    columns.push({
      name: t('columns.client'),
      extractor: ({ client }) => (client),
      canBeFiltered: true,
      isFiltered: !isEmpty(clientSlugs),
      onClickFilterButton: () => { setFilterClientsModalVisible(true); },
    });
  }

  useEffect(() => {
    setLoading(true);
    history.push(`?${queryString.stringify(pickBy(query))}`);
    getTemplates({
      page: currentPage,
      limit: dataListLimit,
      ...query,
    })
      .finally(() => {
        setLoading(false);
        setSearching(false);
      });
  }, [currentPage, dataListLimit, query, getTemplates, history]);

  const {
    modal,
    openModal,
    closeModal,
  } = useModal(false);

  const dispatch = useDispatch();

  const clientId = useSelector(selectClientId);

  const onSubmit = useCallback(({ subTotal, shippingFees, ...data }, { setSubmitting }) => {
    dispatch(createTransactionTemplate({
      subTotal: subTotal * 100,
      shippingFees: shippingFees ? shippingFees * 100 : undefined,
      ...data,
      clientId,
    }))
      .then(() => {
        getTemplates({
          currentPage: 1,
        });
        setCurrentPage(1);
        closeModal();
      })
      .catch(({ error }) => {
        if (error?.response?.data?.errors === ERRORS_FROM_SERVER.ADMIN_CAN_NOT_BE_SELLER) {
          toast.error(t('adminError'));
        } else {
          toast.error(t('errorWhileCreatingTemplate'));
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  }, [
    clientId,
    closeModal,
    dispatch,
    getTemplates,
    t,
  ]);

  return (
    <div className="mt-3">
      <QueryForm
        search={search}
        onSubmit={(search) => { setQuery((prev) => ({ ...prev, search })); }}
        isSubmitting={searching}
        extraButtonLabel={t('export')}
        extraButtonOnClick={() => { setExportTemplatesModalVisible(true); }}
      />
      <DataList
        loading={loading}
        columns={columns}
        data={pages[currentPage]}
        to={({ id }) => (`/templates/${id}`)}
        emptyMessage={search ? t('noResults') : t('noItems')}
      />
      <Row>
        <Col md={{ span: 4, offset: 4 }} className="d-flex justify-content-center align-items-center">
          <Pagination
            currentPage={currentPage}
            nbPages={nbPages}
            onClick={setCurrentPage}
          />
        </Col>
        <Col className="d-flex w-100 justify-content-end align-items-center">
          {isTripartie && (
            <>
              <CreateTransactionTemplateModal show={modal} onHide={closeModal} onSubmit={onSubmit} />
              <Button
                size="sm"
                className="mr-2"
                onClick={openModal}
              >
                {t('createTemplate')}
              </Button>
            </>
          )}
          <DataListLimitSelector />
        </Col>
      </Row>
      <ExportTemplatesModal
        show={isExportTemplatesModalVisible}
        onHide={() => { setExportTemplatesModalVisible(false); }}
        onSubmit={(columns) => exportTemplates(query, columns)
          .finally(() => {
            setExportTemplatesModalVisible(false);
          })}
      />
      <FilterClientsModal
        show={isFilterClientsModalVisible}
        onHide={() => { setFilterClientsModalVisible(false); }}
        initialValues={mapValues(invert(clientSlugs), () => (true))}
        onSubmit={(clientSlugs) => {
          setQuery((prev) => ({ ...prev, clients: Object.keys(omitBy(clientSlugs, (col) => (!col))) }));
          setFilterClientsModalVisible(false);
          return Promise.resolve();
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isTripartie: state.persistent.meReducer.isTripartie,
  canSeeTransactionLinksTab: state.persistent.meReducer.canSeeTransactionLinksTab,
  pages: state.persistent.templatesReducer.pages,
  nbPages: state.persistent.templatesReducer.nbPages,
  dataListLimit: state.persistent.settingsReducer.dataListLimit,
});

const mapDispatchToProps = (dispatch) => ({
  getTemplates: bindActionCreators(getTemplates, dispatch),
  exportTemplates: bindActionCreators(exportTemplates, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TemplatesList);
