import React from 'react';
import mapValues from 'lodash/mapValues';
import invert from 'lodash/invert';
import { Formik } from 'formik';
import * as yup from 'yup';
import Form from 'react-bootstrap/Form';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { isApi } from 'helpers/env';

const COLUMNS = [
  'id',
  'externalId',
  'title',
  'sellerId',
  'subTotal',
  'feesTotal',
  'currency',
  'createdAt',
  'active',
];

const ExportTemplatesModal = ({
  show,
  onHide,
  onSubmit,
}) => {
  const { t } = useTranslation('exportTemplatesModal');
  const { isTripartie } = useSelector(({ persistent: { meReducer } = {} }) => (meReducer));

  const columns = [...COLUMNS];
  if (!isApi && isTripartie) {
    columns.push('client');
  }

  return (
    <Formik
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={yup.object(mapValues(invert(columns), () => (yup.boolean())))}
      onSubmit={onSubmit}
      initialValues={mapValues(invert(columns), () => (true))}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        isSubmitting,
        resetForm,
      }) => {
        const resetAndHide = () => {
          resetForm();
          onHide();
        };

        return (
          <Modal show={show} onHide={resetAndHide} centered>
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>{t('modalTitle')}</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                {columns.map((key) => (
                  <Form.Group key={key}>
                    <Form.Check type="checkbox" id={key}>
                      <Form.Check.Input
                        type="checkbox"
                        name={key}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched[key] && !!errors[key]}
                        checked={values[key]}
                      />
                      <Form.Check.Label>
                        {t(key)}
                      </Form.Check.Label>
                    </Form.Check>
                  </Form.Group>
                ))}
              </Modal.Body>

              <Modal.Footer>
                <Button variant="outline-secondary" onClick={resetAndHide}>{t('close')}</Button>
                <Button
                  type="submit"
                  isLoading={isSubmitting}
                >
                  {t('ok')}
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default ExportTemplatesModal;
