import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as yup from 'yup';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Col from 'react-bootstrap/Col';
import Button from 'components/Button';
import { useSelector } from 'react-redux';

const AddWalletModal = ({
  user: {
    wallets = [],
  } = {},
  show,
  onHide,
  onSubmit,
}) => {
  const { t } = useTranslation('addWalletModal');
  const { currencies } = useSelector(({ persistent: { currenciesReducer } = {} }) => (currenciesReducer));

  const alreadyUsedCurrencies = useMemo(() => (
    wallets.map(({ currency }) => (currency))
  ), [wallets]);

  return (
    <Formik
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={yup.object({
        currency: yup.string().required(t('required')),
      })}
      onSubmit={({ currency }) => onSubmit(
        currency,
      )}
      initialValues={{
        currency: '',
      }}
    >
      {({
        handleSubmit,
        values,
        errors,
        isSubmitting,
        resetForm,
        setFieldValue,
      }) => {
        const resetAndHide = () => {
          resetForm();
          onHide();
        };

        return (
          <Modal show={show} onHide={resetAndHide} centered>
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>{t('modalTitle')}</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <Form.Row>
                  <Form.Group as={Col} controlId="bankAccount">
                    <Form.Label>{t('currency')}</Form.Label>
                    <DropdownButton
                      variant="outline-primary"
                      className="full-width-dropdown"
                      title={values.currency || t('currencyPlaceholder')}
                    >
                      {currencies.map(({ id, shortCode }) => (
                        <Dropdown.Item
                          key={id}
                          disabled={alreadyUsedCurrencies.includes(shortCode)}
                          onSelect={() => {
                            setFieldValue('currency', shortCode);
                          }}
                        >
                          {shortCode}
                        </Dropdown.Item>
                      ))}
                    </DropdownButton>
                    <Form.Control.Feedback type="invalid" className={errors.currency ? 'd-block' : ''}>
                      {errors.currency}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
              </Modal.Body>

              <Modal.Footer>
                <Button variant="outline-secondary" onClick={resetAndHide}>{t('close')}</Button>
                <Button
                  type="submit"
                  isLoading={isSubmitting}
                >
                  {t('ok')}
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default AddWalletModal;
