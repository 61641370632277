import { createReducer } from '@reduxjs/toolkit';
import { saveAs } from 'file-saver';
import {
  success,
  shouldResetState,
} from 'logic/reducers/helpers';
import {
  GET_ADMINS,
  EXPORT_ADMINS,
  GET_ADMIN,
  CREATE_ADMIN,
  UPDATE_ADMIN,
  BLOCK_ADMIN,
  UNBLOCK_ADMIN,
} from 'logic/actions/adminsActions';

const initialState = {
  pages: [],
  nbPages: 1,
  admins: [],
};

const handleGetAdmins = (
  state,
  {
    payload: {
      data: {
        page = 0,
        nbPages = 1,
        items = [],
      } = {},
    } = {},
  },
) => {
  if (page) {
    state.pages[page] = items;
  }
  state.nbPages = nbPages;
};

const handleExportAdmins = (
  state,
  { payload: { data } = {} },
) => {
  saveAs(data, 'admins.csv');
};

const handleGetAdmin = (
  { admins },
  {
    payload: { data = {} } = {},
    meta: { previousAction: { adminId = 0 } = {} } = {},
  },
) => {
  if (adminId) {
    admins[adminId] = data;
  }
};

const handleCreateAdmin = (
  { admins },
  { payload: { data: admin = {} } = {} },
) => {
  if (admin.id) {
    admins[admin.id] = admin;
  }
};

export default createReducer(initialState, {
  [success(GET_ADMINS)]: handleGetAdmins,
  [success(EXPORT_ADMINS)]: handleExportAdmins,
  [success(GET_ADMIN)]: handleGetAdmin,
  [success(CREATE_ADMIN)]: handleCreateAdmin,
  [success(UPDATE_ADMIN)]: handleGetAdmin,
  [success(BLOCK_ADMIN)]: handleGetAdmin,
  [success(UNBLOCK_ADMIN)]: handleGetAdmin,
}, [{
  matcher: (action) => (shouldResetState(action)),
  reducer: () => (initialState),
}]);
