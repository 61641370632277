import { createReducer } from '@reduxjs/toolkit';
import {
  success,
} from 'logic/reducers/helpers';
import {
  GET_COUNTRIES,
} from 'logic/actions/countriesActions';

const initialState = { countries: [] };

const handleGetCountries = (
  state,
  { payload: { data: countries = [] } = {} },
) => {
  state.countries = countries;
};

export default createReducer(initialState, { [success(GET_COUNTRIES)]: handleGetCountries });
