import { createReducer } from '@reduxjs/toolkit';
import { saveAs } from 'file-saver';
import {
  success,
  shouldResetState,
} from 'logic/reducers/helpers';
import {
  GET_TRANSACTIONS,
  EXPORT_TRANSACTIONS,
  GET_TRANSACTION,
  CANCEL_TRANSACTION,
  COMPLETE_TRANSACTION,
  FORCE_TRANSACTION,
  SET_DELIVERY_ADDRESS,
  SET_RETURN_ADDRESS,
  GENERATE_SHIPPING_LABEL,
} from 'logic/actions/transactionsActions';

const initialState = {
  pages: [],
  nbPages: 1,
  transactions: [],
};

const handleGetTransactions = (
  state,
  {
    payload: {
      data: {
        page = 0,
        nbPages = 1,
        items = [],
      } = {},
    } = {},
  },
) => {
  if (page) {
    state.pages[page] = items;
  }
  state.nbPages = nbPages;
};

const handleExportTransactions = (
  state,
  { payload: { data } = {} },
) => {
  saveAs(data, 'transactions.csv');
};

const handleGetTransaction = (
  { transactions },
  {
    payload: { data = {} } = {},
    meta: { previousAction: { transactionId = 0 } = {} } = {},
  },
) => {
  if (transactionId) {
    transactions[transactionId] = data;
  }
};

export default createReducer(initialState, {
  [success(GET_TRANSACTIONS)]: handleGetTransactions,
  [success(EXPORT_TRANSACTIONS)]: handleExportTransactions,
  [success(GET_TRANSACTION)]: handleGetTransaction,
  [success(CANCEL_TRANSACTION)]: handleGetTransaction,
  [success(COMPLETE_TRANSACTION)]: handleGetTransaction,
  [success(FORCE_TRANSACTION)]: handleGetTransaction,
  [success(CANCEL_TRANSACTION)]: handleGetTransaction,
  [success(SET_DELIVERY_ADDRESS)]: handleGetTransaction,
  [success(SET_RETURN_ADDRESS)]: handleGetTransaction,
  [success(GENERATE_SHIPPING_LABEL)]: handleGetTransaction,
}, [{
  matcher: (action) => (shouldResetState(action)),
  reducer: () => (initialState),
}]);
