import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { getNextStates } from 'logic/actions/transactionsActions';
import ForceTransactionButtons from 'components/ForceTransactionButtons';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { TRANSACTION_EVENTS } from 'helpers/constants';
import SpanWithColon from 'helpers/SpanWithColon';

const ForceTransactionByAdminComponent = ({
  transactionId,
  lastEventSlug,
  disputeStatus,
  disputeId,
}) => {
  const { t } = useTranslation([
    'transactionDetails',
    '_transactions',
  ]);

  const dispatch = useDispatch();

  const [states, setStates] = useState([]);

  const loadNextStates = useCallback(() => (
    dispatch(getNextStates(transactionId))
      .then(({ payload: { data: states } }) => {
        setStates(states);
      })
      .catch(() => toast.error(t('errors.statesError')))
  ), [dispatch, transactionId, t]);

  useEffect(() => {
    loadNextStates();
  }, [loadNextStates]);

  return (
    <>
      <div>
        <SpanWithColon className="mr-1">{t('events.message')}</SpanWithColon>
        <strong>
          {disputeStatus
            ? t(`_transactions:disputeStatus.${disputeStatus}`)
            : t(`_transactions:status.${lastEventSlug}`)}
        </strong>
      </div>
      {disputeStatus && disputeId && (
        <Link
          to={`/disputes/${disputeId}`}
        >
          {t('main.seeDispute')}
        </Link>
      )}
      {!disputeStatus && states && lastEventSlug !== TRANSACTION_EVENTS.CANCEL && (
        <ForceTransactionButtons states={states} transactionId={transactionId} loadNextStates={loadNextStates} />
      )}
    </>
  );
};

export default ForceTransactionByAdminComponent;
