import React from 'react';
import isEmpty from 'lodash/isEmpty';
import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import { ReactComponent as Filter } from 'assets/images/filter.svg';

const DataList = ({
  data = [],
  columns = [],
  to = () => ('/'),
  loading = false,
  emptyMessage,
}) => (
  <div className="table-responsive data-list">
    <Table striped bordered hover>
      <thead>
        <tr>
          {columns.map(({
            name = '',
            canBeFiltered = false,
            isFiltered = false,
            onClickFilterButton = () => {},
          }) => (
            <th key={name}>
              <div className="d-flex justify-content-between align-items-center">
                {name}
                {canBeFiltered && (
                  <Button
                    size="sm"
                    variant={isFiltered ? 'outline-primary' : 'outline-secondary'}
                    onClick={onClickFilterButton}
                  >
                    <Filter fill={isFiltered ? 'var(--primary)' : 'var(--secondary)'} width="20" height="20" />
                  </Button>
                )}
              </div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {loading && (
          <tr>
            <td colSpan={columns.length} className="bg-white text-center">
              <Spinner animation="border" className="mx-auto" />
            </td>
          </tr>
        )}
        {!loading && isEmpty(data) && <tr><td colSpan={columns.length}>{emptyMessage}</td></tr>}
        {!isEmpty(data) && data.map((item) => (
          <tr
            key={item.id}
          >
            {columns.map(({
              name,
              extractor = () => {},
              className = '',
              processLongWords,
            }) => {
              const styles = processLongWords ? { overflowWrap: 'anywhere', minWidth: '100px' } : {};

              return (
                <td key={name} style={styles}>
                  {to(item) ? (
                    <Link className={`link ${className}`} to={to(item)}>{extractor(item)}</Link>
                  ) : (
                    <span className={`link ${className}`}>{extractor(item)}</span>
                  )}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </Table>
  </div>
);

export default DataList;
