import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import {
  debug,
  hash,
} from 'helpers/env';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: debug ? false : 'en',
    debug,
    appendNamespaceToMissingKey: debug,
    parseMissingKeyHandler: (key) => (debug ? `[[${key}]]` : key),

    backend: {
      queryStringParams: { hash },
    },
  });

export default i18n;
