import React, {
  useState,
  useEffect,
} from 'react';
import { FormattedDate } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Spinner from 'react-bootstrap/Spinner';
import { isApi } from 'helpers/env';
import {
  DataCard,
  DataCardLine,
} from 'components/DataCard';
import ButtonWithConfirmationModal from 'components/ButtonWithConfirmationModal';
import Button from 'components/Button';
import EditProfileModal from 'components/EditProfileModal';
import ClientModal from 'components/ClientModal';
import {
  updateMe,
  updateClient,
} from 'logic/actions/meActions';
import { resetPasswordAdmin } from 'logic/actions/adminsActions';

const Profile = ({
  me = {},
  isSuperAdmin,
  isOwner,
  updateMe,
  updateClient,
}) => {
  const { t } = useTranslation([
    'profile',
    '_admins',
  ]);
  const [isLoading, setLoading] = useState(!me.email);
  const [isEditProfileModalVisible, setEditProfileModalVisible] = useState(false);
  const [isEditClientModalVisible, setEditClientModalVisible] = useState(false);

  const {
    id,
    userProfile: {
      firstName,
      lastName,
    } = {},
    email,
    role,
    lastLoginDate,
    client: {
      name: clientName,
      description,
      registrationNumber,
      vatNumber,
      websiteUrl,
      address,
      webhookUrl,
    } = {},
  } = me;

  useEffect(() => {
    setLoading(!email);
  }, [email]);

  return (
    <div>
      {isLoading && (<Spinner as="span" animation="border" />)}

      <EditProfileModal
        show={isEditProfileModalVisible}
        onHide={() => { setEditProfileModalVisible(false); }}
        onSubmit={(values) => updateMe(values)
          .then(() => {
            setEditProfileModalVisible(false);
          })}
      />
      <ClientModal
        defaultValues={me.client}
        translation="editProfileModal"
        show={isEditClientModalVisible}
        onHide={() => { setEditClientModalVisible(false); }}
        onSubmit={(values) => updateClient(values)
          .then(() => {
            setEditClientModalVisible(false);
          })}
      />
      <div className="d-flex flex-column align-items-center data-list mt-3">
        <DataCard title={t('actions.cardTitle')}>
          <ButtonWithConfirmationModal
            className="ml-3"
            onConfirm={() => (resetPasswordAdmin(id))}
          >
            {t('actions.resetPassword')}
          </ButtonWithConfirmationModal>
        </DataCard>

        <DataCard
          title={t('main.cardTitle')}
          headerButtons={(
            <Button
              onClick={() => {
                setEditProfileModalVisible(true);
              }}
            >
              {t('main.edit')}
            </Button>
                  )}
        >
          {isSuperAdmin && <DataCardLine title={t('main.id')}>{id}</DataCardLine>}
          <DataCardLine title={t('main.firstName')}>{firstName}</DataCardLine>
          <DataCardLine title={t('main.lastName')}>{lastName}</DataCardLine>
          <DataCardLine title={t('main.email')}>{email}</DataCardLine>
          <DataCardLine title={t('main.role')}>{t(`_admins:role.${role?.toLowerCase()}`)}</DataCardLine>
          <DataCardLine title={t('main.lastLoginDate')}>{lastLoginDate && <FormattedDate value={new Date(lastLoginDate)} dateStyle="short" timeStyle="long" />}</DataCardLine>
        </DataCard>

        <DataCard
          title={t('client.cardTitle')}
          headerButtons={isOwner && (
            <Button
              onClick={() => {
                setEditClientModalVisible(true);
              }}
            >
              {t('client.edit')}
            </Button>
          )}
        >
          <DataCardLine title={t('client.name')}>{clientName}</DataCardLine>
          <DataCardLine title={t('client.description')}>{description}</DataCardLine>
          <DataCardLine title={t('client.registrationNumber')}>{registrationNumber}</DataCardLine>
          <DataCardLine title={t('client.vatNumber')}>{vatNumber}</DataCardLine>
          <DataCardLine title={t('client.websiteUrl')}>{websiteUrl}</DataCardLine>
          <DataCardLine title={t('client.address')}>{address}</DataCardLine>
          {!isApi && <DataCardLine title={t('client.webhookUrl')}>{webhookUrl}</DataCardLine>}
        </DataCard>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  me: state.persistent.meReducer.me,
  isSuperAdmin: state.persistent.meReducer.isSuperAdmin,
  isOwner: state.persistent.meReducer.isOwner,
});

const mapDispatchToProps = (dispatch) => ({
  updateMe: bindActionCreators(updateMe, dispatch),
  updateClient: bindActionCreators(updateClient, dispatch),
  resetPasswordAdmin: bindActionCreators(resetPasswordAdmin, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Profile);
