import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as yup from 'yup';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Col from 'react-bootstrap/Col';
import { filterAndSortArray } from 'helpers/utilities';
import Button from 'components/Button';
import {
  DOCUMENT_TYPES,
} from 'helpers/constants';

const FILE_SIZE = 5 * 1024 * 1024; // 5MB
const SUPPORTED_FORMATS = ['image/png', 'image/jpeg', 'application/pdf'];

const UploadKycModal = ({
  show,
  onHide,
  onSubmit,
}) => {
  const { t, i18n } = useTranslation([
    'uploadKycModal',
    '_kycDocuments',
  ]);

  return (
    <Formik
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={yup.object({
        documentType: yup.string().required(t('required')),
        pages: yup.mixed().required(t('required'))
          .test(
            'fileSize',
            t('fileTooBig'),
            (files = []) => (files.reduce((acc, file) => (acc && file.size <= FILE_SIZE), true)),
          )
          .test(
            'fileFormat',
            t('formatNotSupported'),
            (files) => (files.reduce((acc, file) => (acc && SUPPORTED_FORMATS.includes(file.type)), true)),
          ),
      })}
      onSubmit={(values) => onSubmit(values)}
      initialValues={{
        documentType: DOCUMENT_TYPES[0],
        pages: [],
      }}
    >
      {({
        handleSubmit,
        values,
        errors,
        isSubmitting,
        resetForm,
        setFieldValue,
      }) => {
        const resetAndHide = () => {
          resetForm();
          onHide();
        };

        return (
          <Modal show={show} onHide={resetAndHide} centered>
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>{t('modalTitle')}</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <Form.Row>
                  <Form.Group as={Col} controlId="documentType">
                    <Form.Label>{t('documentType')}</Form.Label>
                    <DropdownButton
                      variant="outline-primary"
                      className="full-width-dropdown"
                      title={t(`_kycDocuments:documentTypes.${values.documentType}`)}
                    >
                      {filterAndSortArray(DOCUMENT_TYPES, (slug) => (`_kycDocuments:documentTypes.${slug}`), t, i18n)
                        .map((slug) => (
                          <Dropdown.Item
                            key={slug}
                            onSelect={() => {
                              setFieldValue('documentType', slug);
                            }}
                          >
                            {t(`_kycDocuments:documentTypes.${slug}`)}
                          </Dropdown.Item>
                        ))}
                    </DropdownButton>
                    <Form.Control.Feedback type="invalid" className={errors.documentType ? 'd-block' : ''}>
                      {errors.documentType}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="pages">
                    <Form.Label>{t('pages')}</Form.Label>
                    <Form.File id="pages" custom>
                      <Form.File.Input
                        onChange={({ target: { files = [] } }) => { setFieldValue('pages', Array.from(files)); }}
                        accept="image/png, image/jpeg, application/pdf"
                        isInvalid={!!errors.pages}
                        multiple
                      />
                      <Form.File.Label data-browse={t('browse')}>
                        {values.pages.length === 0 && t('noFile')}
                        {values.pages.length === 1 && values.pages[0].name}
                        {values.pages.length > 1 && t('nbPages', { count: values.pages.length })}
                      </Form.File.Label>
                      <Form.Control.Feedback type="invalid">
                        {errors.pages}
                      </Form.Control.Feedback>
                    </Form.File>
                    <Form.Control.Feedback type="invalid">
                      {errors.pages}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
              </Modal.Body>

              <Modal.Footer>
                <Button variant="outline-secondary" onClick={resetAndHide}>{t('close')}</Button>
                <Button
                  type="submit"
                  isLoading={isSubmitting}
                >
                  {t('ok')}
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default UploadKycModal;
