import omitBy from 'lodash/omitBy';

/* eslint-disable import/no-unused-modules */
export const GET_ADMINS = 'app/admins/GET_ADMINS';
export const EXPORT_ADMINS = 'app/admins/EXPORT_ADMINS';
export const GET_ADMIN = 'app/admins/GET_ADMIN';
export const CREATE_ADMIN = 'app/admins/CREATE_ADMIN';
export const UPDATE_ADMIN = 'app/admins/UPDATE_ADMIN';
export const BLOCK_ADMIN = 'app/admins/BLOCK_ADMIN';
export const UNBLOCK_ADMIN = 'app/admins/UNBLOCK_ADMIN';
export const RESET_PASSWORD_ADMIN = 'app/admins/RESET_PASSWORD_ADMIN';
/* eslint-enable import/no-unused-modules */

export const getAdmins = ({
  page = 1, limit = 10, ...params
} = {}) => ({
  type: GET_ADMINS,
  payload: {
    request: {
      method: 'GET',
      url: '/api/dashboard/admins/',
      params: {
        page,
        limit,
        ...params,
      },
    },
  },
});

export const exportAdmins = (params, columns) => ({
  type: EXPORT_ADMINS,
  payload: {
    request: {
      method: 'GET',
      url: '/api/dashboard/admins/export/',
      params: {
        ...params,
        columns: Object.keys(omitBy(columns, (col) => (!col))),
      },
      responseType: 'blob',
      timeout: 0,
    },
  },
});

export const getAdmin = (adminId) => ({
  type: GET_ADMIN,
  adminId,
  payload: {
    request: {
      method: 'GET',
      url: `/api/dashboard/admins/${adminId}/`,
    },
  },
});

export const createAdmin = (data) => ({
  type: CREATE_ADMIN,
  payload: {
    request: {
      method: 'POST',
      url: '/api/dashboard/admins/',
      data,
    },
  },
});

export const updateAdmin = (adminId, data) => ({
  type: UPDATE_ADMIN,
  adminId,
  payload: {
    request: {
      method: 'PATCH',
      url: `/api/dashboard/admins/${adminId}/`,
      data,
    },
  },
});

export const blockAdmin = (adminId) => ({
  type: BLOCK_ADMIN,
  adminId,
  payload: {
    request: {
      method: 'PATCH',
      url: `/api/dashboard/admins/${adminId}/block/`,
    },
  },
});

export const unblockAdmin = (adminId) => ({
  type: UNBLOCK_ADMIN,
  adminId,
  payload: {
    request: {
      method: 'PATCH',
      url: `/api/dashboard/admins/${adminId}/unblock/`,
    },
  },
});

export const resetPasswordAdmin = (adminId) => ({
  type: RESET_PASSWORD_ADMIN,
  adminId,
  payload: {
    request: {
      method: 'PATCH',
      url: `/api/dashboard/admins/${adminId}/reset-password/`,
    },
  },
});
