import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import { SHIPPING_PROVIDERS } from 'helpers/constants';

const TrackingNumberModal = ({ visible, onHide, onSubmit }) => {
  const { t } = useTranslation('trackingNumberModal');

  return (
    <Modal
      centered
      show={visible}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <h4 className="mb-0">{t('title')}</h4>
      </Modal.Header>
      <Modal.Body>
        <Formik
          enableReinitialize
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={yup.object({
            shippingProvider: yup.string(),
            trackingNumber: yup.string(),
          })}
          onSubmit={onSubmit}
          initialValues={{
            shippingProvider: '',
            trackingNumber: '',
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
            isSubmitting,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group className="mb-3 pb-1" controlId="shippingProvider">
                <Form.Label>
                  {t('carrier')}
                </Form.Label>
                <Form.Control
                  as="select"
                  name="shippingProvider"
                  value={values.shippingProvider}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={
                                    touched.shippingProvider && !!errors.shippingProvider
                                }
                  autoFocus
                >
                  <option value="" disabled>{t('carrierPlaceholder')}</option>
                  {SHIPPING_PROVIDERS.map((provider) => (
                    <option key={provider.value} value={provider.value}>
                      {provider.label}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.shippingProvider}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3 pb-1" controlId="trackingNumber">
                <Form.Label>
                  {t('trackingNumber')}
                </Form.Label>
                <FormControl
                  placeholder={t('placeholder')}
                  name="trackingNumber"
                  value={values.trackingNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.trackingNumber && !!errors.trackingNumber}
                  autoFocus
                />
                <Form.Control.Feedback type="invalid">
                  {errors.trackingNumber}
                </Form.Control.Feedback>
              </Form.Group>
              <Button
                type="submit"
                isLoading={isSubmitting}
                variant="primary"
                className="btn-block"
              >
                {t('submit')}
              </Button>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default TrackingNumberModal;
