import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';
import { getToken } from 'logic/actions/authActions';
import LoginForm from 'components/LoginForm';
import logo from 'assets/images/logo.svg';

const Login = ({
  getToken,
  loggedIn,
  location,
}) => {
  const { t } = useTranslation('login');
  const loginErrorToast = () => toast.error(t('form.loginError'));
  const referer = (location.state && location.state.referer) || '/';

  if (loggedIn) {
    return <Redirect to={referer} />;
  }

  return (
    <div className="vw-100 vh-100 d-flex justify-content-center align-items-center">
      <div className="border rounded px-5 py-4">
        <img className="mb-3 d-block mx-auto" src={logo} alt="" />
        <h3 className="text-title text-primary text-center mb-3">{t('title')}</h3>
        <LoginForm
          onSubmit={(values, { resetForm }) => {
            getToken(values).catch(() => {
              loginErrorToast();
              resetForm({
                values: {
                  ...values,
                  password: '',
                },
              });
            });
          }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loggedIn: state.persistent.meReducer.loggedIn,
});

const mapDispatchToProps = (dispatch) => ({
  getToken: bindActionCreators(getToken, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Login);
