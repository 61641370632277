import React from 'react';
import times from 'lodash/times';
import BootstrapPagination from 'react-bootstrap/Pagination';

const NB_PAGES_TO_DISPLAY_BEFORE = 2;
const NB_PAGES_TO_DISPLAY_AFTER = 2;

const Pagination = ({
  currentPage = 1,
  nbPages = 1,
  onClick,
}) => {
  const shouldBeDisplayed = (index) => {
    if (index === 1 || index === nbPages) {
      return false;
    }

    if (index < currentPage - NB_PAGES_TO_DISPLAY_BEFORE) {
      return false;
    }

    if (index > currentPage + NB_PAGES_TO_DISPLAY_AFTER) {
      return false;
    }

    if (index > nbPages) {
      return false;
    }

    return true;
  };

  return (
    <div className="d-flex justify-content-center align-items-center">
      <BootstrapPagination className="m-0">
        {/* First */}
        <BootstrapPagination.First
          disabled={currentPage === 1}
          onClick={() => { onClick(1); }}
          onKeyPress={() => { onClick(1); }}
        />

        {/* Previous */}
        <BootstrapPagination.Prev
          disabled={currentPage === 1}
          onClick={() => { onClick(currentPage - 1); }}
          onKeyPress={() => { onClick(currentPage - 1); }}
        />

        {/* First Page */}
        <BootstrapPagination.Item
          active={currentPage === 1}
          onClick={() => { onClick(1); }}
          onKeyPress={() => { onClick(1); }}
        >
          1
        </BootstrapPagination.Item>

        {/* Ellipsis */}
        {!shouldBeDisplayed(2) && nbPages > 2 && (
          <BootstrapPagination.Ellipsis disabled />
        )}

        {/* Middle pages */}
        {times(nbPages, (i) => (
          shouldBeDisplayed(i + 1) && (
            <BootstrapPagination.Item
              key={i + 1}
              active={currentPage === i + 1}
              onClick={() => { onClick(i + 1); }}
              onKeyPress={() => { onClick(i + 1); }}
            >
              {i + 1}
            </BootstrapPagination.Item>
          )
        ))}

        {/* Ellipsis */}
        {!shouldBeDisplayed(nbPages - 1) && nbPages > 2 && (
          <BootstrapPagination.Ellipsis disabled />
        )}

        {/* Last page */}
        {nbPages > 1 && (
          <BootstrapPagination.Item
            active={currentPage === nbPages}
            onClick={() => { onClick(nbPages); }}
            onKeyPress={() => { onClick(nbPages); }}
          >
            {nbPages}
          </BootstrapPagination.Item>
        )}

        {/* Next */}
        <BootstrapPagination.Next
          disabled={currentPage === nbPages}
          onClick={() => { onClick(currentPage + 1); }}
          onKeyPress={() => { onClick(currentPage + 1); }}
        />

        {/* Last */}
        <BootstrapPagination.Last
          disabled={currentPage === nbPages}
          onClick={() => { onClick(nbPages); }}
          onKeyPress={() => { onClick(1); }}
        />
      </BootstrapPagination>
    </div>
  );
};

export default Pagination;
