import React, { useCallback, useMemo, useState } from 'react';
import Button from 'components/Button';
import classes from 'assets/style/forceTransactionButtons.module.scss';
import {
  CANCEL_REJECT_REASONS,
  REJECT_OFFER_REASONS_KEYS,
  TRANSACTION_EVENTS,
} from 'helpers/constants';
import { useTranslation } from 'react-i18next';
import ButtonWithConfirmationModal from 'components/ButtonWithConfirmationModal';
import { useDispatch } from 'react-redux';
import { forceTransaction } from 'logic/actions/transactionsActions';
import { toast } from 'react-toastify';
import useModal from 'hooks/useModal';
import TrackingNumberModal from 'components/TrackingNumberModal';
import parseFormInputs from 'helpers/parseFormInputs';
import SelectReasonModal from 'components/SelectReasonModal';

const {
  SENT,
  ACCEPT,
  REJECT,
  SHIP,
  DELIVERED,
  COMPLETE,
  CANCEL_REJECTED,
  EXPIRE,
} = TRANSACTION_EVENTS;

const ForceTransactionButtons = ({ states, transactionId, loadNextStates }) => {
  const { t } = useTranslation([
    'transactionDetails',
    '_transactions',
  ]);

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const {
    modal: trackingModal,
    openModal: openTrackingModal,
    closeModal: closeTrackingModal,
  } = useModal(false);

  const {
    modal: rejectTransactionModal,
    openModal: openRejectTransactionModal,
    closeModal: closeRejectTransactionModal,
  } = useModal(false);

  const {
    modal: cancelRejectReasonModal,
    openModal: openCancelRejectReasonModal,
    closeModal: closeCancelRejectReasonModal,
  } = useModal(false);

  const forceButtonsConfig = useMemo(() => ({
    [SENT]: null,
    [ACCEPT]: null,
    [REJECT]: {
      openModalCallback: openRejectTransactionModal,
    },
    [SHIP]: {
      openModalCallback: openTrackingModal,
    },
    [DELIVERED]: null,
    [COMPLETE]: null,
    [CANCEL_REJECTED]: {
      openModalCallback: openCancelRejectReasonModal,
    },
    [EXPIRE]: null,
  }), [
    openRejectTransactionModal,
    openTrackingModal,
    openCancelRejectReasonModal,
  ]);

  const forceTransactionStatus = useCallback((nextEventKey, data) => {
    setLoading(true);
    return dispatch(forceTransaction(transactionId, nextEventKey, data))
      .then(() => {
        loadNextStates();
      })
      .catch(() => {
        toast.error(t('events.error'));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    dispatch,
    transactionId,
    loadNextStates,
    t,
  ]);

  const forceToShip = useCallback((data) => {
    forceTransactionStatus(SHIP, parseFormInputs(data))
      .finally(closeTrackingModal);
  }, [forceTransactionStatus, closeTrackingModal]);

  const forceToReject = useCallback((data) => {
    forceTransactionStatus(REJECT, parseFormInputs(data))
      .finally(closeRejectTransactionModal);
  }, [forceTransactionStatus, closeRejectTransactionModal]);

  const forceToCancelReject = useCallback((data) => {
    forceTransactionStatus(CANCEL_REJECTED, parseFormInputs(data))
      .finally(closeCancelRejectReasonModal);
  }, [forceTransactionStatus, closeCancelRejectReasonModal]);

  return (
    <>
      <TrackingNumberModal
        visible={trackingModal}
        onHide={closeTrackingModal}
        onSubmit={forceToShip}
      />
      <SelectReasonModal
        visible={rejectTransactionModal}
        onHide={closeRejectTransactionModal}
        onSubmit={forceToReject}
        reasons={REJECT_OFFER_REASONS_KEYS}
        translation="rejectReasonModal"
      />
      <SelectReasonModal
        visible={cancelRejectReasonModal}
        onHide={closeCancelRejectReasonModal}
        onSubmit={forceToCancelReject}
        reasons={CANCEL_REJECT_REASONS}
        translation="cancelRejectReasonModal"
      />
      <div className={classes.forceTransactionButtons}>
        {states.map((status) => {
          if (forceButtonsConfig[status]) {
            return (
              <Button
                key={status}
                disabled={loading}
                onClick={forceButtonsConfig[status].openModalCallback}
              >
                {t(`_transactions:actions.${status}`)}
              </Button>
            );
          }

          return (
            <ButtonWithConfirmationModal
              key={status}
              onConfirm={() => forceTransactionStatus(status)}
            >
              {t(`_transactions:actions.${status}`)}
            </ButtonWithConfirmationModal>
          );
        })}
      </div>
    </>
  );
};

export default ForceTransactionButtons;
