/* eslint-disable import/no-unused-modules */
export const GET_CURRENCIES = 'app/countries/GET_CURRENCIES';
/* eslint-enable import/no-unused-modules */

export const getCurrencies = () => ({
  type: GET_CURRENCIES,
  noAuth: true,
  payload: {
    request: {
      method: 'GET',
      url: '/api/dashboard/currencies/',
    },
  },
});
