import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import { Formik } from 'formik';
import * as yup from 'yup';
import Form from 'react-bootstrap/Form';
import Button from 'components/Button';

const SelectReasonModal = ({
  visible, onHide, onSubmit, reasons, translation,
}) => {
  const { t } = useTranslation(translation);

  return (
    <Modal
      centered
      show={visible}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <h4 className="mb-0">{t('title')}</h4>
      </Modal.Header>
      <Modal.Body>
        <Formik
          enableReinitialize
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={yup.object({
            reason: yup.string(),
          })}
          onSubmit={onSubmit}
          initialValues={{
            reason: '',
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
            isSubmitting,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group className="mb-3 pb-1" controlId="reason">
                <Form.Label>
                  {t('reasonLabel')}
                </Form.Label>
                <Form.Control
                  as="select"
                  name="reason"
                  value={values.reason}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={
                                        touched.reason && !!errors.reason
                                    }
                  autoFocus
                >
                  <option value="" disabled>{t('placeholder')}</option>
                  {reasons.map((reason) => (
                    <option key={reason} value={reason}>
                      {t(`reasons.${reason}`)}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.reason}
                </Form.Control.Feedback>
              </Form.Group>
              <Button
                type="submit"
                isLoading={isSubmitting}
                variant="primary"
                className="btn-block"
              >
                {t('submit')}
              </Button>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default SelectReasonModal;
