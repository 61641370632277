import {
  clientId,
  clientSecret,
} from 'helpers/env';
import {
  syncLoggedIn,
  syncRefreshToken,
  syncLoggedOut,
} from 'logic/actions/syncActions';
import { getMe } from 'logic/actions/meActions';

/* eslint-disable import/no-unused-modules */
export const GET_TOKEN = 'app/auth/GET_TOKEN';
export const REFRESH_TOKEN = 'app/auth/REFRESH_TOKEN';
export const LOGOUT = 'app/auth/LOGOUT';
/* eslint-enable import/no-unused-modules */

export const doGetToken = ({ email, password }) => ({
  type: GET_TOKEN,
  noAuth: true,
  payload: {
    request: {
      withCredentials: true,
      method: 'POST',
      url: '/oauth/v2/token/',
      data: {
        username: email,
        password,
        client_id: clientId,
        client_secret: clientSecret,
        grant_type: 'password',
      },
    },
  },
});

export const getToken = ({
  email,
  password,
}) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(doGetToken({
    email,
    password,
  }))
    .then(({ payload: { data: { accessToken, expiresAt } = {} } = {} }) => {
      dispatch(syncLoggedIn(accessToken, expiresAt));
      resolve(dispatch(getMe()));
    })
    .catch((err) => {
      reject(err);
    });
});

export const doRefreshToken = () => ({
  type: REFRESH_TOKEN,
  noAuth: true,
  payload: {
    request: {
      withCredentials: true,
      method: 'POST',
      url: '/oauth/v2/token/',
      data: {
        client_id: clientId,
        client_secret: clientSecret,
        grant_type: 'refresh_token',
      },
    },
  },
});

export const refreshToken = () => (dispatch) => new Promise((resolve, reject) => {
  dispatch(doRefreshToken())
    .then(({ payload: { data: { accessToken, expiresAt } = {} } = {} }) => {
      dispatch(syncRefreshToken(accessToken, expiresAt));
      resolve();
    })
    .catch((err) => {
      reject(err);
    });
});

export const logout = () => (dispatch) => dispatch({
  type: LOGOUT,
  payload: {
    request: {
      method: 'PATCH',
      url: '/api/dashboard/me/logout/',
    },
  },
}).finally(() => {
  dispatch(syncLoggedOut());
});
