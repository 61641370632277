import React from 'react';

const SvgDashboardIcon = ({
  color = '#aab6be',
  ...props
}) => (
  <svg
    viewBox="0 0 30 25"
    stroke={color}
    {...props}
  >
    <path
      d="M8.55 1.25c-3.79 0-4.74.84-4.74 4.22s.95 4.22 4.74 4.22 4.75-.85 4.75-4.22-1-4.22-4.75-4.22zM21.45 15.31c-3.8 0-4.75.85-4.75 4.22s.95 4.22 4.75 4.22 4.74-.84 4.74-4.22-1-4.22-4.74-4.22zM13.3 18.36c0-4.22-1-5.27-4.75-5.27s-4.74 1.05-4.74 5.27.95 5.27 4.74 5.27 4.75-1.05 4.75-5.27zM26.19 6.64c0-4.22-1-5.27-4.74-5.27s-4.75 1-4.75 5.27.95 5.27 4.75 5.27 4.74-1.05 4.74-5.27z"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.172}
    />
  </svg>
);

export default SvgDashboardIcon;
