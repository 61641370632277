import React, {
  isValidElement,
} from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import SpanWithColon from 'helpers/SpanWithColon';
import Button from 'components/Button';
import editIcon from 'assets/images/edit-icon.png';

export const DataCard = ({
  title,
  headerButtons,
  children,
}) => (
  <div className="data-card border rounded p-2 shadow-sm">
    <div className="d-flex align-items-center justify-content-between border-bottom pb-1 mb-2">
      <div>{title}</div>
      <div>{headerButtons}</div>
    </div>
    <div className="card-content">{children}</div>
  </div>
);

export const DataCardLine = ({
  title,
  children = '',
  popoverContent,
  className,
  noTooltip = false,
  onEdit,
  ...rest
}) => (
  <div className={`d-flex align-items-center w-100 ${className || ''}`} {...rest}>
    {title && (
      <SpanWithColon style={{ maxWidth: 'calc(100% - 5ex)' }} className="mr-1 font-weight-bold">{title}</SpanWithColon>
    )}
    {noTooltip ? (
      <div className="text-truncate">
        {children}
      </div>
    ) : (
      <OverlayTrigger
        placement="right"
        overlay={(
          <Popover id={`tooltip-${title}`}>
            <Popover.Content>
              {popoverContent || children}
            </Popover.Content>
          </Popover>
        )}
      >
        <div className="text-truncate">
          {children}
        </div>
      </OverlayTrigger>
    )}
    {onEdit && (
      <Button
        variant="light"
        className="bg-white border-0 shadow-none"
        onClick={onEdit}
      >
        <img src={editIcon} alt="Edit" className="align-baseline" height="12px" />
      </Button>
    )}
  </div>
);

export const DataCardList = ({
  title,
  items = [],
  renderItem = () => {},
  keyExtractor = () => {},
  emptyValue = '',
  addItemButtonLabel,
  addItemButtonOnClick = () => {},
}) => (
  <>
    <div>
      {title && (
      <SpanWithColon className="mr-1 font-weight-bold text-nowrap">{title}</SpanWithColon>
      )}
      {!items.length && (
        <span>{emptyValue}</span>
      )}
    </div>
    <ul>
      {items.map((item) => {
        const render = renderItem(item);
        return (
          <li key={keyExtractor(item)}>
            {isValidElement(render) ? (
              <>{render}</>
            ) : (
              <span>{render}</span>
            )}
          </li>
        );
      })}
      {addItemButtonLabel && (
        <li key="list_item_add" className="d-block">
          <Button
            variant="link"
            className="p-0 shadow-none"
            onClick={addItemButtonOnClick}
          >
            {addItemButtonLabel}
          </Button>
        </li>
      )}
    </ul>
  </>
);
