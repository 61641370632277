import React, { useCallback } from 'react';
import Button from 'components/Button';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  NavLink,
  useLocation,
} from 'react-router-dom';
import useWindowSize from 'helpers/useWindowSize';
import { useLoadingScreen } from 'helpers/LoadingScreenContext';
import { logout } from 'logic/actions/authActions';
import {
  selectIsAdmin,
  selectIsTripartie,
  selectCanSeeUsersTab,
  selectCanSeeTransactionsTab,
  selectCanSeeTransactionLinksTab,
  selectCanSeeDisputesTab,
} from 'logic/actions/meActions';
import TabAdmins from 'assets/svg/TabAdmins';
import TabClients from 'assets/svg/TabClients';
import TabDashboard from 'assets/svg/TabDashboard';
import TabDisputes from 'assets/svg/TabDisputes';
import TabLogout from 'assets/svg/TabLogout';
import TabProfile from 'assets/svg/TabProfile';
import TabTemplates from 'assets/svg/TabTemplates';
import TabTransactions from 'assets/svg/TabTransactions';
import TabUsers from 'assets/svg/TabUsers';
import LanguageSelector from 'components/LanguageSelector';
import classes from 'assets/style/sideBar.module.scss';
import classNames from 'classnames/bind';

const SideBarItem = ({
  to = '',
  onClick,
  SvgIcon,
  label,
  className = '',
}) => {
  const { pathname = '/' } = useLocation();

  let active = to.startsWith(pathname);
  if (pathname === '/') {
    active = to === '/';
  }

  const content = (
    <div className={classes.sideBar__navItem}>
      <SvgIcon width={35} />
      <div className={`ml-2 mt-1 ${active ? 'text-primary' : 'text-secondary'}`}>{label}</div>
    </div>
  );

  return (
    <div className={`my-3 ${className}`}>
      {onClick ? (
        <Button variant="link" className="p-0 shadow-none text-decoration-none" onClick={onClick}>{content}</Button>
      ) : (
        <NavLink className="text-decoration-none" to={to}>{content}</NavLink>
      )}
    </div>
  );
};

const SideBar = () => {
  const dispatch = useDispatch();
  const doLogout = useCallback(() => dispatch(logout()), [dispatch]);
  const isAdmin = useSelector(selectIsAdmin);
  const isTripartie = useSelector(selectIsTripartie);
  const canSeeUsersTab = useSelector(selectCanSeeUsersTab);
  const canSeeTransactionsTab = useSelector(selectCanSeeTransactionsTab);
  const canSeeTransactionLinksTab = useSelector(selectCanSeeTransactionLinksTab);
  const canSeeDisputesTab = useSelector(selectCanSeeDisputesTab);

  const { t } = useTranslation('sidebar');
  const setLoadingScreen = useLoadingScreen();
  const { isNarrow } = useWindowSize();

  return (
    <div className={classes.sideBar}>
      <div className={classes.sideBar__items}>
        <SideBarItem to="/" SvgIcon={TabDashboard} label={t('home')} />

        {canSeeUsersTab && <SideBarItem to="/users" SvgIcon={TabUsers} label={t('users')} />}

        {isTripartie && <SideBarItem to="/clients" SvgIcon={TabClients} label={t('clients')} />}

        {canSeeTransactionsTab && <SideBarItem to="/transactions" SvgIcon={TabTransactions} label={t('transactions')} />}

        {canSeeTransactionLinksTab && <SideBarItem to="/templates" SvgIcon={TabTemplates} label={t('templates')} />}

        {isAdmin && <SideBarItem to="/admins" SvgIcon={TabAdmins} label={t('admins')} />}

        {canSeeDisputesTab && <SideBarItem to="/disputes" SvgIcon={TabDisputes} label={t('disputes')} />}

        <SideBarItem to="/profile" SvgIcon={TabProfile} label={t('profile')} />

        <SideBarItem
          onClick={() => {
            setLoadingScreen(true);
            doLogout().finally(() => { setLoadingScreen(false); });
          }}
          SvgIcon={TabLogout}
          label={t('logout')}
          className={classes.sideBar__logoutItem}
        />
        <div className={classNames(isNarrow ? '' : 'ml-1', classes.sideBar__language)}><LanguageSelector down small /></div>
      </div>
    </div>
  );
};

export default SideBar;
