/* eslint-disable import/no-unused-modules */
export const GET_USERS_STATS = 'app/stats/GET_USERS_STATS';
export const GET_FEES_STATS = 'app/stats/GET_FEES_STATS';
export const GET_AMOUNTS_STATS = 'app/stats/GET_AMOUNTS_STATS';
export const GET_TRANSACTIONS_STATS = 'app/stats/GET_TRANSACTIONS_STATS';
/* eslint-enable import/no-unused-modules */

export const getUsersStats = ({
  dateFrom, dateTo, groupBy = 'day',
}) => ({
  type: GET_USERS_STATS,
  payload: {
    request: {
      method: 'GET',
      url: '/api/dashboard/stats/users/',
      params: {
        dateFrom,
        dateTo,
        groupBy,
      },
    },
  },
});

export const getFeesStats = ({
  dateFrom, dateTo, groupBy = 'day',
}) => ({
  type: GET_FEES_STATS,
  payload: {
    request: {
      method: 'GET',
      url: '/api/dashboard/stats/fees/',
      params: {
        dateFrom,
        dateTo,
        groupBy,
      },
    },
  },
});

export const getAmountsStats = ({
  dateFrom, dateTo, groupBy = 'day',
}) => ({
  type: GET_AMOUNTS_STATS,
  payload: {
    request: {
      method: 'GET',
      url: '/api/dashboard/stats/amounts/',
      params: {
        dateFrom,
        dateTo,
        groupBy,
      },
    },
  },
});

export const getTransactionsStats = ({
  dateFrom, dateTo, groupBy = 'day',
}) => ({
  type: GET_TRANSACTIONS_STATS,
  payload: {
    request: {
      method: 'GET',
      url: '/api/dashboard/stats/transactions/',
      params: {
        dateFrom,
        dateTo,
        groupBy,
      },
    },
  },
});
