import { createReducer } from '@reduxjs/toolkit';
import {
  success,
  shouldResetState,
} from 'logic/reducers/helpers';
import {
  GET_USERS_STATS,
  GET_FEES_STATS,
  GET_AMOUNTS_STATS,
  GET_TRANSACTIONS_STATS,
} from 'logic/actions/statsActions';

const initialState = {
  usersStats: {},
  feesStats: {},
  amountsStats: {},
  transactionsStats: {},
};

const handleGetUsersStats = (
  state,
  { payload: { data } = {} },
) => {
  state.usersStats = data;
};

const handleGetFeesStats = (
  state,
  { payload: { data } = {} },
) => {
  state.feesStats = data;
};

const handleGetAmountsStats = (
  state,
  { payload: { data } = {} },
) => {
  state.amountsStats = data;
};

const handleGetTransactionsStats = (
  state,
  { payload: { data } = {} },
) => {
  state.transactionsStats = data;
};

export default createReducer(initialState, {
  [success(GET_USERS_STATS)]: handleGetUsersStats,
  [success(GET_FEES_STATS)]: handleGetFeesStats,
  [success(GET_AMOUNTS_STATS)]: handleGetAmountsStats,
  [success(GET_TRANSACTIONS_STATS)]: handleGetTransactionsStats,
}, [{
  matcher: (action) => (shouldResetState(action)),
  reducer: () => (initialState),
}]);
