import React, {
  useState,
  useCallback,
} from 'react';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Button from 'components/Button';
import { Form } from 'react-bootstrap';

const ButtonWithConfirmationModal = ({
  modalTitle,
  modalMessage,
  withComment = false,
  variant = 'outline-primary',
  confirmVariant,
  cancelVariant = 'outline-secondary',
  confirmButtonLabel,
  cancelButtonLabel,
  onConfirm = Promise.resolve(),
  onCancel = () => {},
  errorMessage,
  children,
  ...props
}) => {
  const { t } = useTranslation('_confirmationModals');
  const errorToast = useCallback(() => toast.error(errorMessage || t('defaultError')), [errorMessage, t]);
  const [modalVisible, setModalVisible] = useState(false);
  const [isConfirming, setConfirming] = useState(false);
  const [comment, setComment] = useState();

  return (
    <>
      <Modal
        show={modalVisible}
        onHide={() => {
          setModalVisible(false);
          onCancel();
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle || t('defaultTitle')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="keep-line-breaks">{modalMessage || t('defaultMessage')}</p>
          {withComment && (
            <Form.Control
              as="textarea"
              style={{ height: '50px' }}
              onChange={({ target: { value } = {} } = {}) => {
                setComment(value);
              }}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant={cancelVariant}
            onClick={() => {
              setModalVisible(false);
              onCancel();
            }}
          >
            {cancelButtonLabel || t('defaultCancel')}
          </Button>
          <Button
            variant={confirmVariant}
            disabled={withComment && !comment}
            isLoading={isConfirming}
            onClick={() => {
              setConfirming(true);
              onConfirm(comment)
                .catch(() => {
                  errorToast();
                })
                .finally(() => {
                  setModalVisible(false);
                  setConfirming(false);
                });
            }}
          >
            {confirmButtonLabel || t('defaultConfirm')}
          </Button>
        </Modal.Footer>
      </Modal>
      <Button
        variant={variant}
        {...props}
        onClick={() => {
          setModalVisible(true);
        }}
      >
        {children}
      </Button>
    </>
  );
};

export default ButtonWithConfirmationModal;
