export const ROLE_READ_ONLY = 'ROLE_READ_ONLY';
export const ROLE_SUPPORT = 'ROLE_SUPPORT';
export const ROLE_ADMIN = 'ROLE_ADMIN';
export const ROLE_OWNER = 'ROLE_OWNER';
export const ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN';

const ROLES_SORTED_BY_RIGHTS_DESC = [
  ROLE_READ_ONLY,
  ROLE_SUPPORT,
  ROLE_ADMIN,
  ROLE_OWNER,
  ROLE_SUPER_ADMIN,
];

export const ALL_ROLES = ROLES_SORTED_BY_RIGHTS_DESC;

const index = (role) => (ROLES_SORTED_BY_RIGHTS_DESC.indexOf(role) || -1);

export const gt = (role1, role2) => (index(role1) > index(role2));
export const gte = (role1, role2) => (index(role1) >= index(role2));
export const eq = (role1, role2) => (index(role1) === index(role2));
export const lte = (role1, role2) => (index(role1) <= index(role2));
export const lt = (role1, role2) => (index(role1) < index(role2));
