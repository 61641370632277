import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'components/Button';
import {
  FLOW_OBJECTS,
  FLOW_SERVICES,
  CURRENCIES,
  PRODUCT_CATEGORIES,
  TRANSACTION_TEMPLATE_IMAGE_MAX_SIZE,
  TRANSACTION_TEMPLATE_IMAGE_FORMATS,
} from 'helpers/constants';
import TransactionImagePicker from 'components/TransactionImagePicker';
import useCurrencyHelpers from 'helpers/useCurrencyHelpers';
import CropImageModal from 'components/CropImageModal';

const FILE_SIZE = TRANSACTION_TEMPLATE_IMAGE_MAX_SIZE;
const SUPPORTED_FORMATS = TRANSACTION_TEMPLATE_IMAGE_FORMATS;

const CreateTransactionTemplateModal = ({
  show,
  onHide,
  onSubmit,
}) => {
  const { t } = useTranslation(['newTransaction', '_productCategories']);

  const currencyHelper = useCurrencyHelpers();

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>{t('title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          enableReinitialize
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={yup.object({
            flow: yup.string().required(t('form.emptyFlow')),
            title: yup.string().trim().max(150, t('form.longString')).required(t('form.emptyTitle')),
            productCategory: yup
              .string()
              .required(t('form.emptyProductCategory')),
            description: yup
              .string()
              .trim()
              .required(t('form.emptyDescription')),
            subTotal: yup.number()
              .typeError(t('form.notNumber'))
              .min(1, t('form.smallNumber', { number: '1' }))
              .max(100000, t('form.largeNumber', { number: '100 000' }))
              .required(t('form.emptySubTotal')),
            currency: yup.string().required(t('form.emptyCurrency')),
            email: yup.string().email().required(t('form.emptyEmail')),
            shippingFees: yup
              .number()
              .typeError(t('form.notNumber'))
              .max(10000, t('form.largeNumber', { number: '10 000' })),
            picture: yup
              .mixed()
              .test(
                'fileSize',
                t('form.fileTooBig'),
                (file) => !file || file.size <= FILE_SIZE,
              )
              .test(
                'fileFormat',
                t('form.formatNotSupported'),
                (file) => !file || SUPPORTED_FORMATS.includes(file.type),
              ),
          })}
          onSubmit={onSubmit}
          initialValues={{
            flow: FLOW_OBJECTS,
            title: '',
            productCategory: '',
            description: '',
            currency: 'EUR',
            subTotal: '',
            picture: '',
            shippingFees: '',
            email: '',
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            setFieldValue,
            errors,
            isSubmitting,
          }) => {
            const { symbol, before } = currencyHelper(values.currency);

            const CurrencySelector = ({ as }) => (
              <DropdownButton title={symbol} as={as} variant="secondary">
                {CURRENCIES.map((currency) => (
                  <Dropdown.Item
                    key={currency}
                    onClick={() => {
                      setFieldValue('currency', currency);
                    }}
                    onKeyPress={() => {
                      setFieldValue('currency', currency);
                    }}
                  >
                    {currencyHelper(currency).symbol}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            );

            return (
              <Form noValidate onSubmit={handleSubmit} className="w-100">
                {values.picture && <CropImageModal />}
                <div className="block">
                  <div className="mb-3">
                    <div className="d-flex">
                      <Form.Group controlId="flowObject" className="mb-0">
                        <Form.Check
                          custom
                          type="radio"
                          label={t('form.object')}
                          checked={values.flow === FLOW_OBJECTS}
                          onChange={() => {
                            setFieldValue('flow', FLOW_OBJECTS);
                          }}
                          isInvalid={!!errors.flow}
                        />
                      </Form.Group>
                      <Form.Group controlId="flowService" className="mb-0">
                        <Form.Check
                          disabled
                          className="ml-5"
                          custom
                          type="radio"
                          label={t('form.service')}
                          checked={values.flow === FLOW_SERVICES}
                          onClick={() => {
                            setFieldValue('flow', FLOW_SERVICES);
                          }}
                          isInvalid={!!errors.flow}
                        />
                      </Form.Group>
                    </div>
                    <Form.Control.Feedback
                      type="invalid"
                      className={errors.flow && 'd-block'}
                    >
                      {errors.flow}
                    </Form.Control.Feedback>
                  </div>

                  <Form.Group controlId="title">
                    <Form.Label>{t('form.title')}</Form.Label>
                    <Form.Control
                      placeholder={t('form.title')}
                      value={values.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={!!errors.title}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.title}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="productCategory">
                    <Form.Label>{t('form.productCategory')}</Form.Label>
                    <Form.Control
                      as="select"
                      custom
                      value={values.productCategory}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={!!errors.productCategory}
                    >
                      <option disabled value="">
                        {t('form.productCategory')}
                      </option>
                      {PRODUCT_CATEGORIES.map((productCategory) => (
                        <option value={productCategory} key={productCategory}>
                          {t(`_productCategories:${productCategory}`)}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.productCategory}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="description">
                    <Form.Label>{t('form.description')}</Form.Label>
                    <Form.Control
                      as="textarea"
                      maxLength={300}
                      placeholder={t('form.description')}
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={!!errors.description}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.description}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="email">
                    <Form.Label>{t('form.email')}</Form.Label>
                    <Form.Control
                      placeholder={t('form.email')}
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={!!errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="subTotal">
                    <Form.Label>{t('form.subTotal')}</Form.Label>
                    <InputGroup>
                      {before && <CurrencySelector as={InputGroup.Prepend} />}
                      <Form.Control
                        className="new-transaction__subtotal-input"
                        placeholder={t('form.subTotal')}
                        value={values.subTotal}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={!!errors.subTotal}
                      />
                      {!before && <CurrencySelector as={InputGroup.Append} />}
                    </InputGroup>
                    <Form.Control.Feedback
                      type="invalid"
                      className={errors.subTotal && 'd-block'}
                    >
                      {errors.subTotal}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="shippingFees">
                    <Form.Label>{t('form.shippingFees')}</Form.Label>
                    <Form.Control
                      placeholder={t('form.shippingFees')}
                      value={values.shippingFees}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={!!errors.shippingFees}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.shippingFees}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <TransactionImagePicker acceptFileTypes={SUPPORTED_FORMATS} />
                </div>

                <div className="d-flex">
                  <Button
                    size="sm"
                    className="ml-auto mt-3"
                    onClick={handleSubmit}
                    isLoading={isSubmitting}
                  >
                    {t('form.submit')}
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default CreateTransactionTemplateModal;
