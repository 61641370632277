/* eslint-disable import/no-unused-modules */
export const GET_ME = 'app/me/GET_ME';
export const UPDATE_ME = 'app/me/UPDATE_ME';
export const UPDATE_CLIENT = 'app/me/UPDATE_CLIENT';
/* eslint-enable import/no-unused-modules */

export const getMe = () => ({
  type: GET_ME,
  payload: {
    request: {
      method: 'GET',
      url: '/api/dashboard/me/',
    },
  },
});

export const updateMe = (data) => ({
  type: UPDATE_ME,
  payload: {
    request: {
      method: 'PATCH',
      url: '/api/dashboard/me/',
      data,
    },
  },
});

export const updateClient = (data) => ({
  type: UPDATE_CLIENT,
  payload: {
    request: {
      method: 'PATCH',
      url: '/api/dashboard/me/client/',
      data,
    },
  },
});

export const selectIsAdmin = (state) => state.persistent.meReducer.isAdmin;
export const selectIsSuperAdmin = (state) => state.persistent.meReducer.isSuperAdmin;
export const selectClientId = (state) => state.persistent.meReducer?.me?.client?.id;
export const selectIsReadOnly = (state) => state.persistent.meReducer.isReadOnly;

export const selectIsTripartie = (state) => state.persistent.meReducer.isTripartie;
export const selectCanSeeUsersTab = (state) => state.persistent.meReducer.canSeeUsersTab;
export const selectCanSeeTransactionsTab = (state) => state.persistent.meReducer.canSeeTransactionsTab;
export const selectCanSeeTransactionLinksTab = (state) => state.persistent.meReducer.canSeeTransactionLinksTab;
export const selectCanSeeDisputesTab = (state) => state.persistent.meReducer.canSeeDisputesTab;
