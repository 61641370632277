import React from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import Form from 'react-bootstrap/Form';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import classes from 'assets/style/queryForm.module.scss';

const QueryForm = ({
  search,
  onSubmit,
  isSubmitting,
  extraButtonLabel,
  extraButtonOnClick,
}) => {
  const { t } = useTranslation('queryForm');

  return (
    <Formik
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={yup.object({
        search: yup.string(),
      })}
      onSubmit={({ search }) => { onSubmit(search); }}
      initialValues={{
        search,
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
      }) => (
        <Form noValidate onSubmit={handleSubmit} className={classes.queryForm}>
          <Form.Control
            type="text"
            name="search"
            value={values.search}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={t('placeholder')}
          />
          <div>
            <Button
              type="submit"
              className="ml-2"
              isLoading={isSubmitting}
            >
              {t('submit')}
            </Button>
            {extraButtonLabel && (
              <Button
                variant="primary"
                className="ml-2"
                onClick={extraButtonOnClick}
              >
                {extraButtonLabel}
              </Button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default QueryForm;
