import React from 'react';

const SvgTransactionsIcon = ({
  color = '#aab6be',
  ...props
}) => (
  <svg
    viewBox="0 0 30 25"
    stroke={color}
    {...props}
  >
    <path
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.172}
      d="M5.68 10.81l-4.34 1.58M23.59 4.29L13.2 8.07M19.84 8.05L24.4 6.4M2.08 14.52l3.55-1.29M25 8.11V8c-.23-.65-2.05-5.48-2.26-5.8a1.91 1.91 0 00-1.9-.89c-.38.05-9 3.14-9.7 3.38s-9.3 3.39-9.62 3.6a1.92 1.92 0 00-.88 1.9c0 .38 1.76 5.25 2 5.91s2.05 5.48 2.26 5.8a1.92 1.92 0 001.1.82M8.51 19.91h6.55M29.33 11.97H5.65M5.61 14.22h23.75"
    />
    <path
      d="M27.76 23.61a1.93 1.93 0 001.48-1.49c.08-.37.14-5.53.14-6.23s-.06-5.85-.14-6.22a1.93 1.93 0 00-1.48-1.49C27.38 8.1 18.18 8 17.49 8s-9.9.06-10.27.14a1.93 1.93 0 00-1.48 1.53c-.08.37-.14 5.53-.14 6.22s.06 5.86.14 6.23a1.93 1.93 0 001.48 1.49c.37.08 9.58.14 10.27.14z"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.172}
    />
  </svg>
);

export default SvgTransactionsIcon;
