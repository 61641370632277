import React from 'react';
import { Formik } from 'formik';
import { useIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import * as yup from 'yup';

const CloseDisputeModal = ({
  show,
  onHide,
  onSubmit,
  total = 0,
  currency = 'EUR',
}) => {
  const { t } = useTranslation('closeDisputeModal');
  const intl = useIntl();

  return (
    <Formik
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={yup.object({
        refund: yup
          .number()
          .when('fullRefund', {
            is: true,
            then: (schema) => schema.notRequired(),
            otherwise: (schema) => schema.required(t('required')).min(100, t('required')),
          }),
        comment: yup.string().required(t('required')),
      })}
      onSubmit={onSubmit}
      initialValues={{
        refund: 0,
        fullRefund: false,
        comment: '',
      }}
    >
      {({
        handleChange,
        handleSubmit,
        values,
        errors,
        setFieldValue,
        isSubmitting,
      }) => (
        <Modal show={show} onHide={onHide} centered>
          <Form noValidate onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>{t('modalTitle')}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <div>
                <p>{t('message')}</p>
                <p>
                  {t('total', {
                    total: intl.formatNumber(total / 100, {
                      style: 'currency',
                      currency,
                    }),
                  })}
                </p>
                <Form.Group className="my-3" controlId="comment">
                  <Form.Check
                    custom
                    className="my-3"
                    type="checkbox"
                    id="fullRefund"
                    label={t('fullRefund')}
                    checked={values.fullRefund}
                    onChange={() => {
                      setFieldValue('fullRefund', !values.fullRefund);
                    }}
                  />
                </Form.Group>
                <Form.Group className="my-3" controlId="refund">
                  <Form.Label>{t('discount')}</Form.Label>
                  <Form.Control
                    type="number"
                    name="refund"
                    disabled={values.fullRefund}
                    min={0}
                    max={total / 100 / 2}
                    value={values.refund / 100}
                    onChange={({ target: { value } = {} } = {}) => {
                      setFieldValue('refund', value * 100);
                    }}
                  />
                  <Form.Control.Feedback type="invalid" className={errors.refund ? 'd-block' : ''}>
                    {errors.refund}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="my-3" controlId="comment">
                  <Form.Label>{t('comment')}</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="comment"
                    style={{ height: '50px' }}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type="invalid" className={errors.comment ? 'd-block' : ''}>
                    {errors.comment}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="outline-secondary" onClick={onHide}>{t('close')}</Button>
              <Button
                type="button"
                isLoading={isSubmitting}
                onClick={handleSubmit}
              >
                {t('ok')}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default CloseDisputeModal;
