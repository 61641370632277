import React, {
  useState,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { Formik } from 'formik';
import * as yup from 'yup';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import InputGroup from 'react-bootstrap/InputGroup';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Col from 'react-bootstrap/Col';
import { filterAndSortArray } from 'helpers/utilities';
import Button from 'components/Button';
import {
  ERROR_EMAIL_ALREADY_EXISTS,
  COMPANY_TYPES,
} from 'helpers/constants';
import { checkEmail } from 'logic/actions/usersActions';

const INITIAL_PHONE_NUMBER_PREFIX = '+00';

const EditUserProfileModal = ({
  user: {
    email,
    userProfile: {
      firstName,
      lastName,
      birthday,
      nationality,
      residency,
    } = {},
    phoneNumber: {
      prefix: phoneNumberPrefix = INITIAL_PHONE_NUMBER_PREFIX,
      phoneNumber,
    } = {},
    companyProfile: {
      name: companyName,
      companyType,
      registrationNumber,
      vatNumber,
    } = {},
  } = {},
  show,
  onHide,
  onSubmit,
}) => {
  const { t, i18n } = useTranslation([
    'editUserProfileModal',
    '_countries',
    '_companyTypes',
  ]);
  const dispatch = useDispatch();
  const doCheckEmail = useCallback((email) => dispatch(checkEmail(email)), [dispatch]);
  const { countries } = useSelector(({ persistent: { countriesReducer } = {} }) => (countriesReducer));
  const [previousEmail, setPreviousEmail] = useState(email);
  const [previousEmailTestResult, setPreviousEmailTestResult] = useState(true);
  const isCompany = !!companyName;

  return (
    <Formik
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={yup.object({
        email: yup
          .string()
          .required(t('required'))
          .email(t('invalidEmail'))
          .test({
            name: 'emailValid',
            test: (newEmail, { createError }) => {
              if (!newEmail || newEmail === previousEmail) {
                return previousEmailTestResult;
              }
              setPreviousEmail(newEmail);
              return doCheckEmail(newEmail)
                .then(() => {
                  setPreviousEmailTestResult(true);
                  return true;
                })
                .catch(
                  ({ error: { response: { data: { errors } = {} } = {} } = {} }) => {
                    let result = createError({ message: t('invalidEmail') });
                    if (errors === ERROR_EMAIL_ALREADY_EXISTS) {
                      result = createError({ message: t('alreadyUsedEmail') });
                    }
                    setPreviousEmailTestResult(result);
                    return result;
                  },
                );
            },
          }),
        phoneNumberPrefix: phoneNumberPrefix ? yup.string().required(t('required')) : undefined,
        phoneNumber: phoneNumber ? yup.string().required(t('required')) : undefined,
        firstName: yup.string().required(t('required')),
        lastName: yup.string().required(t('required')),
        birthday: birthday ? yup.date().required(t('required')) : undefined,
        nationality: nationality ? yup.string().required(t('required')) : undefined,
        residency: residency ? yup.string().required(t('required')) : undefined,

        companyName: isCompany ? yup.string().required(t('required')) : undefined,
        companyType: isCompany ? yup.string().required(t('required')) : undefined,
        registrationNumber: isCompany ? yup.string().required(t('required')) : undefined,
      })}
      onSubmit={({
        email,
        phoneNumberPrefix,
        phoneNumber,
        firstName,
        lastName,
        nationality,
        residency,
        birthday,
        companyName,
        companyType,
        registrationNumber,
        vatNumber,
      }) => onSubmit({
        email,
        phoneNumber: {
          prefix: phoneNumberPrefix !== INITIAL_PHONE_NUMBER_PREFIX ? phoneNumberPrefix : undefined,
          phoneNumber,
        },
        userProfile: {
          firstName,
          lastName,
          nationality,
          residency,
          birthday,
        },
        companyProfile: {
          name: companyName,
          companyType,
          registrationNumber,
          vatNumber,
        },
      })}
      initialValues={{
        email,
        phoneNumberPrefix,
        phoneNumber,
        firstName,
        lastName,
        nationality,
        residency,
        birthday,
        companyName,
        companyType,
        registrationNumber,
        vatNumber,
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        isSubmitting,
        resetForm,
        setFieldValue,
      }) => {
        const resetAndHide = () => {
          resetForm();
          onHide();
        };

        return (
          <Modal show={show} onHide={resetAndHide} centered>
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>{t('modalTitle')}</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <Form.Row>
                  <Form.Group as={Col} controlId="email">
                    <Form.Label>{t('email')}</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder={t('email')}
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={!!errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} controlId="phoneNumber">
                    <Form.Label>{t('phoneNumber')}</Form.Label>
                    <InputGroup>
                      <DropdownButton
                        as={InputGroup.Prepend}
                        variant="outline-primary"
                        title={values.phoneNumberPrefix || t('phoneNumberPrefixPlaceholder')}
                      >
                        {filterAndSortArray(countries, ({ slug }) => (`_countries:country.${slug}`), t, i18n)
                          .map(({ slug, phonePrefix }) => (
                            <Dropdown.Item
                              key={slug}
                              onSelect={() => {
                                setFieldValue('phoneNumberPrefix', `+${phonePrefix}`);
                              }}
                            >
                              {t(`_countries:country.${slug}`)}
                              {' '}
                              (+
                              {phonePrefix}
                              )
                            </Dropdown.Item>
                          ))}
                      </DropdownButton>
                      <Form.Control
                        name="phoneNumber"
                        placeholder={t('phoneNumber')}
                        value={values.phoneNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={
                          (!!errors.phoneNumber)
                          || (!!errors.phoneNumberPrefix)
                        }
                      />
                    </InputGroup>
                    <Form.Control.Feedback type="invalid">
                      {errors.phoneNumber || errors.phoneNumberPrefix}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="firstName">
                    <Form.Label>{t('firstName')}</Form.Label>
                    <Form.Control
                      name="firstName"
                      placeholder={t('firstName')}
                      value={values.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={!!errors.firstName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.firstName}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} controlId="lastName">
                    <Form.Label>{t('lastName')}</Form.Label>
                    <Form.Control
                      name="lastName"
                      placeholder={t('lastName')}
                      value={values.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={!!errors.lastName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.lastName}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="nationality">
                    <Form.Label>{t('nationality')}</Form.Label>
                    <DropdownButton
                      variant="outline-primary"
                      className="full-width-dropdown"
                      title={values.nationality ? t(`_countries:nationality.${values.nationality}`) : t('countryPlaceholder')}
                    >
                      {filterAndSortArray(countries, ({ slug }) => (`_countries:nationality.${slug}`), t, i18n)
                        .map(({ slug }) => (
                          <Dropdown.Item
                            key={slug}
                            onSelect={() => {
                              setFieldValue('nationality', slug);
                            }}
                          >
                            {t(`_countries:nationality.${slug}`)}
                          </Dropdown.Item>
                        ))}
                    </DropdownButton>
                    <Form.Control.Feedback type="invalid" className={errors.nationality ? 'd-block' : ''}>
                      {errors.nationality}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} controlId="residency">
                    <Form.Label>{t('residency')}</Form.Label>
                    <DropdownButton
                      className="full-width-dropdown"
                      variant="outline-primary"
                      title={values.residency ? t(`_countries:country.${values.residency}`) : t('countryPlaceholder')}
                    >
                      {filterAndSortArray(countries, ({ slug }) => (`_countries:country.${slug}`), t, i18n)
                        .map(({ slug }) => (
                          <Dropdown.Item
                            key={slug}
                            onSelect={() => {
                              setFieldValue('residency', slug);
                            }}
                          >
                            {t(`_countries:country.${slug}`)}
                          </Dropdown.Item>
                        ))}
                    </DropdownButton>
                    <Form.Control.Feedback type="invalid" className={errors.residency ? 'd-block' : ''}>
                      {errors.residency}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md="6" controlId="birthday">
                    <Form.Label>{t('birthday')}</Form.Label>
                    <Form.Control
                      type="date"
                      name="birthday"
                      placeholder={t('birthday')}
                      value={values.birthday}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={!!errors.birthday}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.birthday}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                {isCompany && (
                  <>
                    <Form.Row>
                      <Form.Group as={Col} controlId="companyName">
                        <Form.Label>{t('companyName')}</Form.Label>
                        <Form.Control
                          name="companyName"
                          placeholder={t('companyName')}
                          value={values.companyName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={!!errors.companyName}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.companyName}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} controlId="companyType">
                        <Form.Label>{t('companyType')}</Form.Label>
                        <DropdownButton
                          className="full-width-dropdown"
                          variant="outline-primary"
                          title={values.companyType ? t(`_companyTypes:${values.companyType}`) : t('companyTypePlaceholder')}
                        >
                          {filterAndSortArray(COMPANY_TYPES, (slug) => (`_companyTypes:${slug}`), t, i18n)
                            .map((slug) => (
                              <Dropdown.Item
                                key={slug}
                                onSelect={() => {
                                  setFieldValue('companyType', slug);
                                }}
                              >
                                {t(`_companyTypes:${slug}`)}
                              </Dropdown.Item>
                            ))}
                        </DropdownButton>
                        <Form.Control.Feedback type="invalid" className={errors.companyType ? 'd-block' : ''}>
                          {errors.companyType}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} controlId="registrationNumber">
                        <Form.Label>{t('registrationNumber')}</Form.Label>
                        <Form.Control
                          name="registrationNumber"
                          placeholder={t('registrationNumber')}
                          value={values.registrationNumber}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={!!errors.registrationNumber}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.registrationNumber}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} controlId="vatNumber">
                        <Form.Label>{t('vatNumber')}</Form.Label>
                        <Form.Control
                          name="vatNumber"
                          placeholder={t('vatNumber')}
                          value={values.vatNumber}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={!!errors.vatNumber}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.vatNumber}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>
                  </>
                )}
              </Modal.Body>

              <Modal.Footer>
                <Button variant="outline-secondary" onClick={resetAndHide}>{t('close')}</Button>
                <Button
                  type="submit"
                  isLoading={isSubmitting}
                >
                  {t('ok')}
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default EditUserProfileModal;
