import { useCallback, useState } from 'react';

const useCropper = (imageFile) => {
  const [cropper, setCropper] = useState();
  const [cropData, setCropData] = useState(null);
  const [canCrop, setCanCrop] = useState(false);

  const handleCropEnd = useCallback(() => {
    setCanCrop(true);
  }, []);

  const handleReady = useCallback(() => {
    setCanCrop(false);
  }, []);

  const initCropper = useCallback((instance) => {
    setCropper(instance);
  }, []);

  const cropImage = useCallback(async () => {
    if (typeof cropper !== 'undefined') {
      const newCanvas = cropper.getCroppedCanvas();

      if (newCanvas) {
        newCanvas.toBlob((blob) => {
          const file = new File([blob], imageFile?.name, { type: blob.type });
          setCropData(file);
          cropper.replace(newCanvas.toDataURL());
        });
      }
    }
  }, [cropper, imageFile?.name]);

  return {
    cropData,
    canCrop,
    handleCropEnd,
    handleReady,
    initCropper,
    cropImage,
  };
};

export default useCropper;
