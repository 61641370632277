import React, { useCallback, useMemo, useState } from 'react';
import classes from 'assets/style/pauseTransaction.module.scss';
import { useTranslation, Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { pauseTransaction, unpauseTransaction } from 'logic/actions/transactionsActions';
import ButtonWithConfirmationModal from 'components/ButtonWithConfirmationModal';
import { ReactComponent as PauseIcon } from 'assets/images/pause-fill.svg';
import { ReactComponent as UnpauseIcon } from 'assets/images/play-fill.svg';
import { TRANSACTION_PAUSE_STATUSES } from 'helpers/constants';
import { toast } from 'react-toastify';

const { ACTIVE, ON_PAUSE } = TRANSACTION_PAUSE_STATUSES;

const currentTransactionState = {
  [ACTIVE]: {
    nextAction: pauseTransaction,
    icon: <PauseIcon />,
    buttonText: 'actions.pause',
    modalMessage: 'pause.pauseMessage',
  },
  [ON_PAUSE]: {
    nextAction: unpauseTransaction,
    icon: <UnpauseIcon />,
    buttonText: 'actions.unpause',
    modalMessage: 'pause.unpauseMessage',
  },
};

const PauseTransactionComponent = ({
  transactionId, isPaused, title, refetchTransaction,
}) => {
  const { t } = useTranslation('transactionDetails');

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const transactionState = useMemo(() => (isPaused ? ON_PAUSE : ACTIVE), [isPaused]);

  const handleTransaction = useCallback(() => {
    setLoading(true);
    return dispatch(currentTransactionState[transactionState].nextAction(transactionId))
      .then(() => {
        refetchTransaction();
      })
      .catch(() => {
        toast.error(t('pause.error'));
      })
      .finally(() => setLoading(false));
  }, [
    setLoading,
    dispatch,
    transactionState,
    transactionId,
    refetchTransaction,
    t,
  ]);

  const modalMessage = useMemo(() => (
    <Trans
      t={t}
      i18nKey={currentTransactionState[transactionState].modalMessage}
      values={{ title }}
      components={{ bold: <strong /> }}
    />
  ),
  [transactionState, title, t]);

  return (
    <ButtonWithConfirmationModal
      className={classes.pauseTransaction}
      variant="outline-info"
      disabled={loading}
      modalMessage={modalMessage}
      onConfirm={handleTransaction}
    >
      <div className={classes.bntContent}>
        {currentTransactionState[transactionState].icon}
        <span>{t(currentTransactionState[transactionState].buttonText)}</span>
      </div>
    </ButtonWithConfirmationModal>
  );
};

export default PauseTransactionComponent;
