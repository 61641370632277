import React from 'react';

const SvgLogoutIcon = ({
  color = '#aab6be',
  ...props
}) => (
  <svg
    viewBox="0 0 30 25"
    stroke={color}
    {...props}
  >
    <path
      d="M5.57 17.61c1 4.79 3.89 6.14 10.85 6.14 9 0 11.25-2.25 11.25-11.25S25.42 1.25 16.42 1.25h0c-7 0-9.89 1.35-10.85 6.14M2.33 12.5h16.68M14.26 7.75l4.75 4.75M14.26 17.25l4.75-4.75"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.172}
    />
  </svg>
);

export default SvgLogoutIcon;
