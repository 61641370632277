import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import classes from 'assets/style/clientsDetails.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import {
  editClient, getClient, selectClient, selectClientsLoading,
} from 'logic/actions/clientsActions';
import { DataCard, DataCardLine } from 'components/DataCard';
import { useTranslation } from 'react-i18next';
import ClientModal from 'components/ClientModal';
import Button from 'components/Button';
import { ERRORS, ERRORS_FROM_SERVER } from 'helpers/constants';
import { selectIsSuperAdmin } from 'logic/actions/meActions';
import { toast } from 'react-toastify';

const ClientsDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { clientId } = useParams();

  const { t } = useTranslation('clientDetails');

  const [refetch, setRefetch] = useState(true);
  const [addClientModal, setAddClientModal] = useState(false);

  const openModal = useCallback(() => setAddClientModal(true), [setAddClientModal]);
  const closeModal = useCallback(() => setAddClientModal(false), [setAddClientModal]);

  const client = useSelector(selectClient);
  const isLoading = useSelector(selectClientsLoading);
  const isSuperAdmin = useSelector(selectIsSuperAdmin);

  useEffect(() => {
    if (!isSuperAdmin) {
      history.push('/');
    }
  }, [isSuperAdmin, history]);

  useEffect(() => {
    if (refetch) {
      dispatch(getClient(clientId))
        .finally(() => {
          setRefetch(false);
          closeModal();
        });
    }
  }, [dispatch, refetch, closeModal, clientId]);

  const getObjectKeys = (object, exceptionsArray = []) => Object.keys(object).filter((key) => !exceptionsArray.includes(key));

  const cardFields = useMemo(() => {
    if (client) {
      return getObjectKeys(client, ['theme', 'slug']);
    }
    return [];
  }, [client]);

  const themeFields = useMemo(() => {
    if (client?.theme) {
      return getObjectKeys(client.theme);
    }
    return [];
  }, [client]);

  const handleSubmit = useCallback((data, { setFieldError, setSubmitting }) => {
    dispatch(editClient(clientId, data))
      .then(() => {
        setRefetch(true);
        closeModal();
      })
      .catch(({ error }) => {
        if (error.response.data.errors === ERRORS_FROM_SERVER.SLUG_ERROR) {
          setFieldError('slug', ERRORS.SLUG_ERROR);
        } else {
          toast.error(t('editError'));
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  }, [clientId, closeModal, dispatch, t]);

  return (
    <div className={classes.clientsDetails}>
      {isLoading && (<Spinner as="span" animation="border" />)}

      <DataCard title={t('actions.cardTitle')}>
        <Button variant="primary" onClick={openModal}>{t('actions.edit')}</Button>
      </DataCard>

      {client && (
        <ClientModal
          isEditModal
          defaultValues={{ ...client }}
          showExtraFields
          translation="editClientModal"
          show={addClientModal}
          onHide={closeModal}
          onSubmit={handleSubmit}
        />
      )}

      <DataCard title={t('clientInfo.title')}>
        {cardFields.map((field) => (
          <DataCardLine key={field} title={t(`clientInfo.${field}`)}>{client[field]}</DataCardLine>
        ))}
        {!!themeFields.length && themeFields.map((themeField) => (
          <DataCardLine key={themeField} title={t(`clientInfo.theme.${themeField}`)}>{client.theme[themeField]}</DataCardLine>
        ))}
      </DataCard>
    </div>
  );
};

export default ClientsDetails;
