const {
  REACT_APP_ENV_TYPE,
  REACT_APP_ENV_NAME,
  REACT_APP_API_URL,
  REACT_APP_CLIENT_ID,
  REACT_APP_CLIENT_SECRET,
  REACT_APP_SENTRY_DSN,
  REACT_APP_MANGOPAY_ESCROW_USER_ID,
  REACT_APP_IS_API,
  REACT_APP_DEBUG,
  REACT_APP_HASH,
  REACT_APP_TRANSACTIONS_STATES,
  REACT_APP_SENTRY_ENVIRONMENT_NAME,
  REACT_APP_SENTRY_RELEASE_VERSION,
} = process.env;

const envType = REACT_APP_ENV_TYPE || 'dev';
const isProd = envType === 'prod';
const isPreprod = envType === 'preprod';
const isDev = envType === 'dev';

const envName = REACT_APP_ENV_NAME;
const apiUrl = REACT_APP_API_URL;
const clientId = REACT_APP_CLIENT_ID;
const clientSecret = REACT_APP_CLIENT_SECRET;
const sentryDSN = REACT_APP_SENTRY_DSN;
const mangoPayBaseUrl = isProd ? 'https://dashboard.mangopay.com' : 'https://dashboard.sandbox.mangopay.com';
const lemonwayEurBaseUrl = isProd ? 'https://dashboard.lemonway.com/tripartieeur' : 'https://sb-bo.lemonway.com/tripartieeur';
const lemonwayChfBaseUrl = isProd ? 'https://dashboard.lemonway.com/tripartie' : 'https://sb-bo.lemonway.com/tripartie';
const mangoPayEscrowUserId = REACT_APP_MANGOPAY_ESCROW_USER_ID;
const isApi = REACT_APP_IS_API === 'true';
const debug = REACT_APP_DEBUG === 'true';
const hash = REACT_APP_HASH || '';
const sentryEnvironmentName = REACT_APP_SENTRY_ENVIRONMENT_NAME || 'develop';
const sentryReleaseVersion = REACT_APP_SENTRY_RELEASE_VERSION || 'preview';

const transactionsStates = REACT_APP_TRANSACTIONS_STATES
  ? REACT_APP_TRANSACTIONS_STATES.split(',')
  : [
    'event_transaction_create',
    'event_transaction_sent',
    'event_transaction_accept',
    'event_transaction_preauthorize',
    'event_transaction_pay',
    'event_transaction_pay_failed',
    'event_transaction_ship',
    'event_transaction_delivered',
    'event_transaction_open_issue',
    'event_transaction_return',
    'event_transaction_return_ship',
    'event_transaction_return_delivered',
    'event_transaction_return_open_issue',
    'event_transaction_close_issue',
    'event_transaction_complete',
    'event_transaction_return_complete',
    'event_transaction_cancel',
    'event_transaction_review_sent',
    'event_transaction_reject',
    'event_transaction_expire',
  ];

const disputesStates = [
  'opened',
  'arbitration',
  'closed',
];

export {
  isProd,
  isPreprod,
  isDev,
  envType,
  envName,
  apiUrl,
  clientId,
  clientSecret,
  sentryDSN,
  mangoPayBaseUrl,
  lemonwayEurBaseUrl,
  lemonwayChfBaseUrl,
  mangoPayEscrowUserId,
  isApi,
  debug,
  hash,
  transactionsStates,
  disputesStates,
  sentryEnvironmentName,
  sentryReleaseVersion,
};
