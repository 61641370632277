import { useState, useCallback } from 'react';

const useModal = (state) => {
  const [modal, setModal] = useState(state);

  const openModal = useCallback(() => {
    setModal(true);
  }, [setModal]);

  const closeModal = useCallback(() => {
    setModal(false);
  }, [setModal]);

  return {
    modal,
    openModal,
    closeModal,
  };
};

export default useModal;
