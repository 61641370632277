import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as yup from 'yup';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Col from 'react-bootstrap/Col';
import Button from 'components/Button';

const CashoutModal = ({
  user: {
    bankAccounts = [],
  } = {},
  wallet: {
    id: walletId,
  } = {},
  show,
  onHide,
  onSubmit,
}) => {
  const { t } = useTranslation('cashoutModal');

  return (
    <Formik
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={yup.object({
        bankAccount: yup.number().required(t('required')),
      })}
      onSubmit={({ bankAccount }) => onSubmit({
        wallet: walletId,
        bankAccount,
      })}
      initialValues={{
        bankAccount: bankAccounts[0]?.id || {},
      }}
    >
      {({
        handleSubmit,
        values,
        errors,
        isSubmitting,
        resetForm,
        setFieldValue,
      }) => {
        const resetAndHide = () => {
          resetForm();
          onHide();
        };

        const selectedBankAccount = bankAccounts.find(({ id }) => (id === values.bankAccount)) || {};

        return (
          <Modal show={show} onHide={resetAndHide} centered>
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>{t('modalTitle')}</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <Form.Row>
                  <Form.Group as={Col} controlId="bankAccount">
                    <Form.Label>{t('bankAccount')}</Form.Label>
                    <DropdownButton
                      variant="outline-primary"
                      className="full-width-dropdown"
                      title={selectedBankAccount.iban
                        ? selectedBankAccount.iban.replaceAll(/(.{4})/g, '$1 ')
                        : t('bankAccountPlaceholder')}
                    >
                      {bankAccounts.map(({ id, iban }) => (
                        <Dropdown.Item
                          key={id}
                          onSelect={() => {
                            setFieldValue('bankAccount', id);
                          }}
                        >
                          {iban.replaceAll(/(.{4})/g, '$1 ')}
                        </Dropdown.Item>
                      ))}
                    </DropdownButton>
                    <Form.Control.Feedback type="invalid" className={errors.bankAccount ? 'd-block' : ''}>
                      {errors.bankAccount}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
              </Modal.Body>

              <Modal.Footer>
                <Button variant="outline-secondary" onClick={resetAndHide}>{t('close')}</Button>
                <Button
                  type="submit"
                  isLoading={isSubmitting}
                >
                  {t('ok')}
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default CashoutModal;
