import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as yup from 'yup';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Col from 'react-bootstrap/Col';
import { filterAndSortArray } from 'helpers/utilities';
import Button from 'components/Button';

const EditUserAddressModal = ({
  user: {
    address: {
      recipientName,
      addressLine1,
      addressLine2,
      postcode,
      city,
      state,
      country: {
        slug: country,
      } = {},
    } = {},
  } = {},
  show,
  onHide,
  onSubmit,
}) => {
  const { t, i18n } = useTranslation([
    'editUserAddressModal',
    '_countries',
  ]);
  const { countries } = useSelector(({ persistent: { countriesReducer } = {} }) => (countriesReducer));

  return (
    <Formik
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={yup.object({
        recipientName: yup.string().required(t('required')),
        addressLine1: yup.string().required(t('required')),
        city: yup.string().required(t('required')),
        country: yup.string().required(t('required')),
      })}
      onSubmit={({
        recipientName,
        addressLine1,
        addressLine2,
        postcode,
        city,
        state,
        country,
      }) => onSubmit({
        recipientName,
        addressLine1,
        addressLine2,
        postcode,
        city,
        state,
        country,
      })}
      initialValues={{
        recipientName,
        addressLine1,
        addressLine2,
        postcode,
        city,
        state,
        country,
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        isSubmitting,
        resetForm,
        setFieldValue,
      }) => {
        const resetAndHide = () => {
          resetForm();
          onHide();
        };

        return (
          <Modal show={show} onHide={resetAndHide} centered>
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>{t('modalTitle')}</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <Form.Row>
                  <Form.Group as={Col} controlId="recipientName">
                    <Form.Label>{t('recipientName')}</Form.Label>
                    <Form.Control
                      name="recipientName"
                      placeholder={t('recipientName')}
                      value={values.recipientName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={!!errors.recipientName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.recipientName}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="addressLine1">
                    <Form.Label>{t('addressLine1')}</Form.Label>
                    <Form.Control
                      name="addressLine1"
                      placeholder={t('addressLine1')}
                      value={values.addressLine1}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={!!errors.addressLine1}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.addressLine1}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="addressLine2">
                    <Form.Label>{t('addressLine2')}</Form.Label>
                    <Form.Control
                      name="addressLine2"
                      placeholder={t('addressLine2')}
                      value={values.addressLine2}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={!!errors.addressLine2}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.addressLine2}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="postcode">
                    <Form.Label>{t('postcode')}</Form.Label>
                    <Form.Control
                      name="postcode"
                      placeholder={t('postcode')}
                      value={values.postcode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={!!errors.postcode}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.postcode}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} controlId="city">
                    <Form.Label>{t('city')}</Form.Label>
                    <Form.Control
                      name="city"
                      placeholder={t('city')}
                      value={values.city}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={!!errors.city}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.city}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="state">
                    <Form.Label>{t('state')}</Form.Label>
                    <Form.Control
                      name="state"
                      placeholder={t('state')}
                      value={values.state}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={!!errors.state}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.state}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} controlId="country">
                    <Form.Label>{t('country')}</Form.Label>
                    <DropdownButton
                      variant="outline-primary"
                      className="full-width-dropdown"
                      title={values.country ? t(`_countries:country.${values.country}`) : t('countryPlaceholder')}
                    >
                      {filterAndSortArray(countries, ({ slug }) => (`_countries:country.${slug}`), t, i18n)
                        .map(({ slug }) => (
                          <Dropdown.Item
                            key={slug}
                            onSelect={() => {
                              setFieldValue('country', slug);
                            }}
                          >
                            {t(`_countries:country.${slug}`)}
                          </Dropdown.Item>
                        ))}
                    </DropdownButton>
                    <Form.Control.Feedback type="invalid" className={errors.country ? 'd-block' : ''}>
                      {errors.country}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
              </Modal.Body>

              <Modal.Footer>
                <Button variant="outline-secondary" onClick={resetAndHide}>{t('close')}</Button>
                <Button
                  type="submit"
                  isLoading={isSubmitting}
                >
                  {t('ok')}
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default EditUserAddressModal;
