import React, { useCallback } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import Button from 'components/Button';
import { toast } from 'react-toastify';

const TransactionImagePicker = ({ acceptFileTypes }) => {
  const { t } = useTranslation(['newTransaction']);

  const {
    values, errors, setFieldValue, setFieldError,
  } = useFormikContext();

  const selectImage = useCallback((e) => {
    const {
      target: { files: [picture = null] = [] },
    } = e;
    if (acceptFileTypes.includes(picture.type)) {
      setFieldValue('picture', picture);
    } else {
      toast.error(t('form.formatNotSupported'));
    }
    e.target.value = '';
  }, [acceptFileTypes, setFieldValue, t]);

  const resetImage = useCallback(() => {
    setFieldValue('picture', null);
    setFieldError('picture', '');
  }, [setFieldValue, setFieldError]);

  return (
    <Form.Group controlId="picture">
      <Form.Label>{t('form.picture.label')}</Form.Label>
      <Form.File custom>
        <Form.File.Input
          onChange={selectImage}
          accept={acceptFileTypes.toString()}
          isInvalid={!!errors.picture}
          disabled={!!values.picture}
        />
        <Form.File.Label data-browse={t('form.picture.browse')}>
          {values.picture ? values.picture.name : t('form.picture.noFile')}
        </Form.File.Label>
        <Form.Control.Feedback type="invalid">
          {errors.picture}
        </Form.Control.Feedback>
      </Form.File>
      {values.picture && (
        <Button variant="light" className="reset-button mt-2" onClick={resetImage}>
          {t('form.reset')}
        </Button>
      )}
    </Form.Group>
  );
};

TransactionImagePicker.defaultProps = {
  acceptFileTypes: ['image/png', 'image/jpeg'],
};

export default TransactionImagePicker;
