import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAllClients, getClients, selectClientsLoading, addClient,
} from 'logic/actions/clientsActions';
import { useTranslation } from 'react-i18next';
import DataList from 'components/DataList';
import Button from 'components/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ClientModal from 'components/ClientModal';
import parseFormInputs from 'helpers/parseFormInputs';
import { ERRORS, ERRORS_FROM_SERVER } from 'helpers/constants';
import { selectIsSuperAdmin } from 'logic/actions/meActions';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';

const ClientsList = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const [addClientModal, setAddClientModal] = useState(false);

  const [refetch, setRefetch] = useState(false);

  const openModal = useCallback(() => setAddClientModal(true), [setAddClientModal]);
  const closeModal = useCallback(() => setAddClientModal(false), [setAddClientModal]);

  const { t } = useTranslation('clientsList');

  const clients = useSelector(selectAllClients);
  const loading = useSelector(selectClientsLoading);
  const isSuperAdmin = useSelector(selectIsSuperAdmin);

  const validationForExtraFields = {
    slug: yup.string().trim().required(t('required')),
    lang: yup.string().required(t('required')),
  };

  useEffect(() => {
    if (!isSuperAdmin) {
      history.push('/');
    }
  }, [isSuperAdmin, history]);

  const columns = [
    {
      name: t('columns.id'),
      extractor: ({ id }) => (id),
    },
    {
      name: t('columns.name'),
      extractor: ({ name }) => (name),
    },
    {
      name: t('columns.registrationNumber'),
      extractor: ({ registrationNumber }) => (registrationNumber),
    },
    {
      name: t('columns.address'),
      extractor: ({ address }) => (address),
    },
  ];

  useEffect(() => {
    dispatch(getClients())
      .then(() => {
        setRefetch(false);
      })
      .catch(() => {
        toast.error(t('loadError'));
      });
  }, [refetch, dispatch, t]);

  const handleSubmit = useCallback((values, { setFieldError, setSubmitting }) => {
    dispatch(addClient({
      ...parseFormInputs(values),
      theme: Object.keys(parseFormInputs(values.theme)).length ? parseFormInputs(values.theme) : undefined,
    }))
      .then(() => {
        setRefetch(true);
        closeModal();
      })
      .catch(({ error }) => {
        if (error.response.data.errors === ERRORS_FROM_SERVER.SLUG_ERROR) {
          setFieldError('slug', ERRORS.SLUG_ERROR);
        } else {
          toast.error(t('addError'));
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  }, [closeModal, dispatch, t]);

  return (
    <div>
      <ClientModal
        clearOnMount
        showExtraFields
        translation="addClientModal"
        show={addClientModal}
        onHide={closeModal}
        onSubmit={handleSubmit}
        validationFields={validationForExtraFields}
      />
      <DataList
        loading={loading}
        columns={columns}
        data={clients}
        to={({ id }) => (`/clients/${id}`)}
        emptyMessage={t('noResults')}
      />
      <Row>
        <Col className="d-flex w-100 justify-content-end align-items-center">
          <Button
            size="sm"
            className="mr-2"
            onClick={openModal}
          >
            {t('addClient')}
          </Button>
        </Col>
      </Row>

    </div>
  );
};

export default ClientsList;
