import omitBy from 'lodash/omitBy';

/* eslint-disable import/no-unused-modules */
export const GET_DISPUTES = 'disputes/GET_DISPUTES';
export const EXPORT_DISPUTES = 'disputes/EXPORT_DISPUTES';
export const GET_DISPUTE = 'disputes/GET_DISPUTE';
export const START_RETURN = 'disputes/START_RETURN';
export const CLOSE_DISPUTE = 'disputes/CLOSE_DISPUTE';
export const CANCEL_DISPUTE = 'disputes/CANCEL_DISPUTE';
/* eslint-enable import/no-unused-modules */

export const getDisputes = ({
  page = 1, limit = 10, ...params
} = {}) => ({
  type: GET_DISPUTES,
  payload: {
    request: {
      method: 'GET',
      url: '/api/dashboard/disputes/',
      params: {
        page,
        limit,
        ...params,
      },
    },
  },
});

export const exportDisputes = (params, columns) => ({
  type: EXPORT_DISPUTES,
  payload: {
    request: {
      method: 'GET',
      url: '/api/dashboard/disputes/export/',
      params: {
        ...params,
        columns: Object.keys(omitBy(columns, (col) => (!col))),
      },
      responseType: 'blob',
      timeout: 0,
    },
  },
});

export const getDispute = (disputeId) => ({
  type: GET_DISPUTE,
  disputeId,
  payload: {
    request: {
      method: 'GET',
      url: `/api/dashboard/disputes/${disputeId}/`,
    },
  },
});

export const startReturn = (disputeId, data) => ({
  disputeId,
  type: START_RETURN,
  payload: {
    request: {
      method: 'PATCH',
      url: `/api/dashboard/disputes/${disputeId}/start-return/`,
      data,
    },
  },
});

export const closeDispute = (disputeId, data) => ({
  disputeId,
  type: CLOSE_DISPUTE,
  payload: {
    request: {
      method: 'PATCH',
      url: `/api/dashboard/disputes/${disputeId}/close/`,
      data,
    },
  },
});

export const cancelDispute = (disputeId, data) => ({
  disputeId,
  type: CANCEL_DISPUTE,
  payload: {
    request: {
      method: 'PATCH',
      url: `/api/dashboard/disputes/${disputeId}/cancel/`,
      data,
    },
  },
});
