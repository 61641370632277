import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import 'assets/theme.scss';
import 'assets/style/index.css';
import 'react-toastify/dist/ReactToastify.min.css';
import App from 'App';
import reportWebVitals from 'reportWebVitals';
import 'helpers/i18n';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer, toast } from 'react-toastify';
import { BrowserRouter } from 'react-router-dom';
import LoadingScreenContextProvider from 'helpers/LoadingScreenContext';
import {
  persistor,
  store,
} from 'logic';

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback="">
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <BrowserRouter>
            <LoadingScreenContextProvider>
              <App />
            </LoadingScreenContextProvider>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </Suspense>
    <ToastContainer position={toast.POSITION.TOP_CENTER} />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log)) abcdfgh
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
