import omitBy from 'lodash/omitBy';

/* eslint-disable import/no-unused-modules */
export const GET_TEMPLATES = 'app/templates/GET_TEMPLATES';
export const EXPORT_TEMPLATES = 'app/templates/EXPORT_TEMPLATES';
export const GET_TEMPLATE = 'app/templates/GET_TEMPLATE';
export const DISABLE_TEMPLATE = 'app/templates/DISABLE_TEMPLATE';
export const GET_TEMPLATE_TRANSACTIONS = 'app/templates/GET_TEMPLATE_TRANSACTIONS';
export const CREATE_TRANSACTION_TEMPLATE = 'app/templates/CREATE_TRANSACTION_TEMPLATE';
/* eslint-enable import/no-unused-modules */

export const getTemplates = ({
  page = 1, limit = 10, ...params
} = {}) => ({
  type: GET_TEMPLATES,
  payload: {
    request: {
      method: 'GET',
      url: '/api/dashboard/templates/',
      params: {
        page,
        limit,
        ...params,
      },
    },
  },
});

export const exportTemplates = (params, columns) => ({
  type: EXPORT_TEMPLATES,
  payload: {
    request: {
      method: 'GET',
      url: '/api/dashboard/templates/export/',
      params: {
        ...params,
        columns: Object.keys(omitBy(columns, (col) => (!col))),
      },
      responseType: 'blob',
      timeout: 0,
    },
  },
});

export const getTemplate = (templateId) => ({
  type: GET_TEMPLATE,
  templateId,
  payload: {
    request: {
      method: 'GET',
      url: `/api/dashboard/templates/${templateId}/`,
    },
  },
});

export const getTemplateTransactions = (templateId) => ({
  type: GET_TEMPLATE_TRANSACTIONS,
  templateId,
  payload: {
    request: {
      method: 'GET',
      url: `/api/dashboard/templates/${templateId}/transactions/`,
    },
  },
});

export const disableTemplate = (templateId) => ({
  type: DISABLE_TEMPLATE,
  templateId,
  payload: {
    request: {
      method: 'PATCH',
      url: `/api/dashboard/templates/${templateId}/disable/`,
    },
  },
});

export const createTransactionTemplate = (data) => (dispatch) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    if (data[key] !== null && data[key] !== undefined) {
      if (typeof data[key] === 'boolean') {
        formData.append(key, data[key] ? 1 : 0);
      } else {
        formData.append(key, data[key]);
      }
    }
  });

  return dispatch({
    type: CREATE_TRANSACTION_TEMPLATE,
    payload: {
      request: {
        method: 'POST',
        url: '/api/dashboard/templates/',
        data: formData,
      },
    },
  });
};
