import { createReducer } from '@reduxjs/toolkit';
import {
  SET_DATA_LIST_LIMIT,
} from 'logic/actions/settingsActions';

const initialState = { dataListLimit: 10 };

const handleSetDataListLimit = (
  state,
  { payload: { dataListLimit } = {} },
) => {
  state.dataListLimit = dataListLimit;
};

export default createReducer(initialState, { [SET_DATA_LIST_LIMIT]: handleSetDataListLimit });
