import React, {
  useState,
  useEffect,
} from 'react';
import isEmpty from 'lodash/isEmpty';
import {
  FormattedDate,
  FormattedNumber,
} from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom';
import { PREREGISTRATION } from 'helpers/constants';
import { isApi } from 'helpers/env';
import {
  DataCard,
  DataCardLine,
  DataCardList,
} from 'components/DataCard';
import ButtonWithConfirmationModal from 'components/ButtonWithConfirmationModal';
import {
  getTemplate,
  disableTemplate,
  getTemplateTransactions,
} from 'logic/actions/templatesActions';

const TemplateDetails = ({
  isTripartie,
  isSupport,
  templates,
  getTemplate,
  disableTemplate,
  getTemplateTransactions,
  match: { params: { templateId } = {} } = {},
  history,
  canSeeTransactionLinksTab,
}) => {
  useEffect(() => {
    if (!canSeeTransactionLinksTab) {
      history.push('/');
    }
  }, [canSeeTransactionLinksTab, history]);

  const { t } = useTranslation([
    'templateDetails',
    '_templates',
    '_transactions',
    '_users',
  ]);
  const template = templates[templateId]?.id ? templates[templateId] : { id: templateId };
  const [isLoading, setLoading] = useState(!template.title);
  const [isLoadingTransactions, setLoadingTransactions] = useState(isEmpty(template.transactions));

  const {
    id,
    externalId,
    client,
    active,
    title,
    description,
    flow,
    adUrl,
    permalink,
    allowPriceChange,
    allowInPerson,
    allowDelivery,
    hasPicture,
    createdAt,
    currency = 'EUR',
    subTotal = 0,
    totalFees = 0,
    buyerFees = 0,
    sellerFees = 0,
    buyerTotal = 0,
    sellerTotal = 0,
    shippingFees = 0,
    visitCount = 0,
    lastVisitedAt,
    seller: {
      accountStatus: sellerAccountStatus,
      id: sellerId,
      externalIds: sellerExternalIds = [],
      userProfile: {
        firstName: sellerFirstName,
        lastName: sellerLastName,
      } = {},
      email: sellerEmail,
      formattedPhoneNumber: sellerFormattedPhoneNumber,
      averageReviewScore: sellerAverageReviewScore,
      nbTransactionsAsBuyer: sellerNbTransactionsAsBuyer,
      nbTransactionsAsSeller: sellerNbTransactionsAsSeller,
    } = {},
    transactions = [],
  } = template;

  useEffect(() => {
    setLoading(!title);
    getTemplate(id)
      .finally(() => {
        setLoading(false);
      });
    getTemplateTransactions(id)
      .finally(() => {
        setLoadingTransactions(false);
      });
  }, [getTemplate, getTemplateTransactions, title, id]);

  const sellerIsRegistered = sellerAccountStatus !== PREREGISTRATION;

  return (
    <div>
      {isLoading && (<Spinner as="span" animation="border" />)}

      {active && isSupport && (
        <DataCard title={t('actions.cardTitle')}>
          <ButtonWithConfirmationModal
            variant="outline-danger"
            modalMessage={t('actions.disableConfirmationMessage')}
            onConfirm={() => (disableTemplate(id))}
          >
            {t('actions.disable')}
          </ButtonWithConfirmationModal>
        </DataCard>
      )}

      <DataCard title={t('main.cardTitle')}>
        <DataCardLine title={t('main.active')}>{t(`_templates:active.${active}`)}</DataCardLine>
        <DataCardLine title={t('main.id')}>{id}</DataCardLine>
        <DataCardLine title={t('main.externalId')}>{externalId}</DataCardLine>
        {!isApi && isTripartie && <DataCardLine title={t('main.client')}>{client}</DataCardLine>}
        <DataCardLine title={t('main.title')}>{title}</DataCardLine>
        <DataCardLine title={t('main.description')}>{description}</DataCardLine>
        <DataCardLine title={t('main.flow')}>{t(`_templates:flow.${flow}`)}</DataCardLine>
        <DataCardLine title={t('main.adUrl')} noTooltip><a href={adUrl} target="_blank" rel="noopener noreferrer">{adUrl}</a></DataCardLine>
        <DataCardLine title={t('main.permalink')} noTooltip><a href={permalink} target="_blank" rel="noopener noreferrer">{permalink}</a></DataCardLine>
        <DataCardLine title={t('main.allowPriceChange')}>{t(`_templates:allowPriceChange.${allowPriceChange}`)}</DataCardLine>
        <DataCardLine title={t('main.allowInPerson')}>{t(`_templates:allowInPerson.${allowInPerson}`)}</DataCardLine>
        <DataCardLine title={t('main.allowDelivery')}>{t(`_templates:allowDelivery.${allowDelivery}`)}</DataCardLine>
        <DataCardLine title={t('main.hasPicture')}>{t(`_templates:hasPicture.${hasPicture}`)}</DataCardLine>
        <DataCardLine title={t('main.createdOn')}>{createdAt && <FormattedDate value={new Date(createdAt)} dateStyle="short" timeStyle="long" />}</DataCardLine>
        <DataCardLine title={t('main.subTotal')}><FormattedNumber style="currency" value={subTotal / 100} currency={currency} /></DataCardLine>
        <DataCardLine title={t('main.totalFees')}><FormattedNumber style="currency" value={totalFees / 100} currency={currency} /></DataCardLine>
        <DataCardLine title={t('main.buyerFees')}><FormattedNumber style="currency" value={buyerFees / 100} currency={currency} /></DataCardLine>
        <DataCardLine title={t('main.sellerFees')}><FormattedNumber style="currency" value={sellerFees / 100} currency={currency} /></DataCardLine>
        <DataCardLine title={t('main.shippingFees')}><FormattedNumber style="currency" value={shippingFees / 100} currency={currency} /></DataCardLine>
        <DataCardLine title={t('main.buyerTotal')}><FormattedNumber style="currency" value={buyerTotal / 100} currency={currency} /></DataCardLine>
        <DataCardLine title={t('main.sellerTotal')}><FormattedNumber style="currency" value={sellerTotal / 100} currency={currency} /></DataCardLine>
        <DataCardLine title={t('main.visitCount')}>{visitCount}</DataCardLine>
        <DataCardLine title={t('main.lastVisitedAt')}>{lastVisitedAt && <FormattedDate value={new Date(lastVisitedAt)} dateStyle="short" timeStyle="long" />}</DataCardLine>
      </DataCard>

      <DataCard
        title={t('seller.cardTitle')}
        headerButtons={sellerIsRegistered && (
          <Link
            to={`/users/${sellerId}`}
          >
            {t('seller.seeProfile')}
          </Link>
        )}
      >
        <DataCardLine title={t('seller.registered')}>{t(`_users:registered.${sellerIsRegistered}`)}</DataCardLine>
        <DataCardLine title={t('seller.id')}>{sellerId}</DataCardLine>
        <DataCardLine title={t('seller.externalIds')}>{sellerExternalIds.join(', ')}</DataCardLine>
        <DataCardLine title={t('seller.email')}>{sellerEmail}</DataCardLine>
        {sellerIsRegistered && (
          <>
            <DataCardLine title={t('seller.firstName')}>{sellerFirstName}</DataCardLine>
            <DataCardLine title={t('seller.lastName')}>{sellerLastName}</DataCardLine>
            <DataCardLine title={t('seller.formattedPhoneNumber')}>{sellerFormattedPhoneNumber}</DataCardLine>
            <DataCardLine title={t('seller.averageReviewScore')}>
              <FormattedNumber value={sellerAverageReviewScore} maximumFractionDigits={2} />
              {' '}
              / 5
            </DataCardLine>
            <DataCardLine title={t('seller.nbTransactionsAsBuyer')}>{sellerNbTransactionsAsBuyer}</DataCardLine>
            <DataCardLine title={t('seller.nbTransactionsAsSeller')}>{sellerNbTransactionsAsSeller}</DataCardLine>
          </>
        )}
      </DataCard>

      <DataCard title={t('transactions.cardTitle')}>
        {isLoadingTransactions ? (
          <Spinner as="span" animation="border" />
        ) : (
          <DataCardList
            items={transactions}
            keyExtractor={({ id }) => (id)}
            renderItem={({
              id,
              buyer: { safeDisplayName } = {},
              subTotal = 0,
              currency = 'EUR',
              lastEventSlug = '',
              lastEventCreatedAt,
            }) => (
              <>
                <Link className="link mr-1" to={`/transactions/${id}`}><FormattedNumber style="currency" value={subTotal / 100} currency={currency} /></Link>
                {safeDisplayName && (
                  <span className="ml-1">
                    -
                    <Link className="link ml-1" to={`/transactions/${id}`}>{t('transactions.fromBuyer', { buyer: safeDisplayName })}</Link>
                  </span>
                )}
                -
                <span className="ml-1">
                  {t(`_transactions:status.${lastEventSlug}`)}
                  {lastEventCreatedAt && (
                  <span className="ml-1">
                    (
                    <FormattedDate value={new Date(lastEventCreatedAt)} dateStyle="short" timeStyle="long" />
                    )
                  </span>
                  )}
                </span>
              </>
            )}
            emptyValue={t('transactions.noTransactions')}
          />
        )}
      </DataCard>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isTripartie: state.persistent.meReducer.isTripartie,
  canSeeTransactionLinksTab: state.persistent.meReducer.canSeeTransactionLinksTab,
  isSupport: state.persistent.meReducer.isSupport,
  templates: state.persistent.templatesReducer.templates,
});

const mapDispatchToProps = (dispatch) => ({
  getTemplate: bindActionCreators(getTemplate, dispatch),
  disableTemplate: bindActionCreators(disableTemplate, dispatch),
  getTemplateTransactions: bindActionCreators(getTemplateTransactions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TemplateDetails);
