import { createReducer } from '@reduxjs/toolkit';
import {
  success,
  shouldResetState,
} from 'logic/reducers/helpers';
import {
  GET_TOKEN,
  REFRESH_TOKEN,
} from 'logic/actions/authActions';
import {
  SYNC_LOGGED_IN,
  SYNC_REFRESH_TOKEN,
  SYNC_LOGGED_OUT,
} from 'logic/actions/syncActions';

const initialState = {
  accessToken: '',
  expiresAt: 0,
};

const handleAccessToken = (
  state,
  { payload: { data: { accessToken, expiresAt = 0 } = {} } = {} },
) => ({
  accessToken,
  expiresAt,
});

const handleSyncAccessToken = (state, { accessToken, expiresAt = 0 }) => ({
  ...state,
  accessToken,
  expiresAt,
});

const handleSyncLoggedOut = () => initialState;

export default createReducer(initialState, {
  [success(GET_TOKEN)]: handleAccessToken,
  [success(REFRESH_TOKEN)]: handleAccessToken,
  [SYNC_LOGGED_IN]: handleSyncAccessToken,
  [SYNC_REFRESH_TOKEN]: handleSyncAccessToken,
  [SYNC_LOGGED_OUT]: handleSyncLoggedOut,
}, [{
  matcher: ({ type }) => (shouldResetState({ type }) && type !== success(GET_TOKEN)),
  reducer: () => (initialState),
}]);
