import React from 'react';

const SvgUsersIcon = ({
  color = '#aab6be',
  ...props
}) => (
  <svg
    viewBox="0 0 30 25"
    stroke={color}
    {...props}
  >
    <path
      d="M17.76 23.75a32.82 32.82 0 00-.35-5.46 4.89 4.89 0 00-3.63-3.88A19.39 19.39 0 009.67 14a19.25 19.25 0 00-4.11.37 4.89 4.89 0 00-3.64 3.88 32.82 32.82 0 00-.35 5.46M28.43 21.08a32.82 32.82 0 00-.35-5.46 4.89 4.89 0 00-3.64-3.88 19.25 19.25 0 00-4.11-.37 19.39 19.39 0 00-4.11.37M9.65 3.92c-3 0-3.75.75-3.75 3.74s.75 3.74 3.75 3.74 3.74-.75 3.74-3.74-.75-3.74-3.74-3.74zM20.31 1.25c-3 0-3.74.75-3.74 3.74s.75 3.74 3.74 3.74S24.05 8 24.05 5s-.74-3.74-3.74-3.74z"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.172}
    />
  </svg>
);

export default SvgUsersIcon;
