import React from 'react';
import mapValues from 'lodash/mapValues';
import invertBy from 'lodash/invertBy';
import isEmpty from 'lodash/isEmpty';
import { Formik } from 'formik';
import * as yup from 'yup';
import Form from 'react-bootstrap/Form';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
import { selectAllClients } from 'logic/actions/clientsActions';

const FilterClientsModal = ({
  show,
  onHide,
  onSubmit,
  initialValues,
}) => {
  const { t } = useTranslation('filterClientsModal');

  const clients = useSelector(selectAllClients);

  return (
    <Formik
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={yup.object(mapValues(invertBy(clients, ({ slug }) => (slug)), () => (yup.boolean())))}
      onSubmit={onSubmit}
      initialValues={isEmpty(initialValues)
        ? mapValues(invertBy(clients, ({ slug }) => (slug)), () => (true))
        : {
          ...mapValues(invertBy(clients, ({ slug }) => (slug)), () => (false)),
          ...initialValues,
        }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        isSubmitting,
        resetForm,
      }) => (
        <Modal show={show} onHide={onHide} centered>
          <Form noValidate onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>{t('modalTitle')}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <div className="d-flex flex-row justify-content-around">
                <div>
                  {clients.slice(0, Math.ceil(clients.length / 2)).map(({ slug, name }) => (
                    <Form.Group key={slug}>
                      <Form.Check type="checkbox" id={slug}>
                        <Form.Check.Input
                          type="checkbox"
                          name={slug}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched[slug] && !!errors[slug]}
                          checked={values[slug]}
                        />
                        <Form.Check.Label>
                          {name}
                        </Form.Check.Label>
                      </Form.Check>
                    </Form.Group>
                  ))}
                </div>
                <div>
                  {clients.slice(Math.ceil(clients.length / 2)).map(({ slug, name }) => (
                    <Form.Group key={slug}>
                      <Form.Check type="checkbox" id={slug}>
                        <Form.Check.Input
                          type="checkbox"
                          name={slug}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched[slug] && !!errors[slug]}
                          checked={values[slug]}
                        />
                        <Form.Check.Label>
                          {name}
                        </Form.Check.Label>
                      </Form.Check>
                    </Form.Group>
                  ))}
                </div>
              </div>
              <div className="d-flex flex-row justify-content-center">
                <Button
                  variant="link"
                  className="shadow-none"
                  onClick={() => {
                    resetForm({ values: mapValues(invertBy(clients, ({ slug }) => (slug)), () => (true)) });
                  }}
                >
                  {t('checkAll')}
                </Button>
                <Button
                  variant="link"
                  className="shadow-none"
                  onClick={() => {
                    resetForm({ values: mapValues(invertBy(clients, ({ slug }) => (slug)), () => (false)) });
                  }}
                >
                  {t('uncheckAll')}
                </Button>
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="outline-secondary" onClick={onHide}>{t('close')}</Button>
              <Button
                type="submit"
                isLoading={isSubmitting}
              >
                {t('ok')}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default FilterClientsModal;
