import { createReducer } from '@reduxjs/toolkit';
import { saveAs } from 'file-saver';
import {
  success,
  shouldResetState,
} from 'logic/reducers/helpers';
import {
  GET_USERS,
  EXPORT_USERS,
  GET_USER,
  UPDATE_USER,
  UPDATE_USER_ADDRESS,
  BLOCK_USER,
  UNBLOCK_USER,
  TAG_SUSPICIOUS_USER,
  UNTAG_SUSPICIOUS_USER,
  GET_USER_WALLETS,
  GET_USER_TRANSACTIONS,
  UPLOAD_KYC,
  ADD_BANK_ACCOUNT,
  CASHOUT_WALLET,
  ADD_WALLET,
  DELETE_CARD,
  DELETE_BANK_ACCOUNT,
} from 'logic/actions/usersActions';

const initialState = {
  pages: [],
  nbPages: 1,
  users: [],
};

const handleGetUsers = (
  state,
  {
    payload: {
      data: {
        page = 0,
        nbPages = 1,
        items = [],
      } = {},
    } = {},
  },
) => {
  if (page) {
    state.pages[page] = items;
  }
  state.nbPages = nbPages;
};

const handleExportUsers = (
  state,
  { payload: { data } = {} },
) => {
  saveAs(data, 'users.csv');
};

const handleGetUser = (
  { users },
  {
    payload: { data = {} } = {},
    meta: { previousAction: { userId = 0 } = {} } = {},
  },
) => {
  if (userId) {
    users[userId] = {
      ...users[userId], ...data,
    };
  }
};

const handleGetUserWallets = (
  { users },
  {
    payload: { data: wallets = {} } = {},
    meta: { previousAction: { userId = 0 } = {} } = {},
  },
) => {
  if (userId) {
    if (!users[userId]) {
      users[userId] = {};
    }
    users[userId].wallets = wallets;
  }
};

const handleGetUserTransactions = (
  { users },
  {
    payload: { data: transactions = {} } = {},
    meta: { previousAction: { userId = 0 } = {} } = {},
  },
) => {
  if (userId) {
    if (!users[userId]) {
      users[userId] = {};
    }
    users[userId].transactions = transactions;
  }
};

export default createReducer(initialState, {
  [success(GET_USERS)]: handleGetUsers,
  [success(EXPORT_USERS)]: handleExportUsers,
  [success(GET_USER)]: handleGetUser,
  [success(UPDATE_USER)]: handleGetUser,
  [success(UPDATE_USER_ADDRESS)]: handleGetUser,
  [success(BLOCK_USER)]: handleGetUser,
  [success(UNBLOCK_USER)]: handleGetUser,
  [success(TAG_SUSPICIOUS_USER)]: handleGetUser,
  [success(UNTAG_SUSPICIOUS_USER)]: handleGetUser,
  [success(GET_USER_WALLETS)]: handleGetUserWallets,
  [success(GET_USER_TRANSACTIONS)]: handleGetUserTransactions,
  [success(UPLOAD_KYC)]: handleGetUser,
  [success(ADD_BANK_ACCOUNT)]: handleGetUser,
  [success(ADD_WALLET)]: handleGetUserWallets,
  [success(CASHOUT_WALLET)]: handleGetUserWallets,
  [success(DELETE_CARD)]: handleGetUser,
  [success(DELETE_BANK_ACCOUNT)]: handleGetUser,
}, [{
  matcher: (action) => (shouldResetState(action)),
  reducer: () => (initialState),
}]);
