import { createReducer } from '@reduxjs/toolkit';
import {
  success,
  fail,
} from 'logic/reducers/helpers';
import {
  GET_CLIENT,
  GET_CLIENTS,
} from 'logic/actions/clientsActions';

const initialState = {
  clients: [],
  loading: false,
  client: null,
};

const handleLoading = (state) => ({
  ...state,
  loading: true,
});

const handleGetClients = (
  state,
  {
    payload: { data: clients = {} } = {},
    meta: { previousAction: { clearClient = true } = {} } = {},
  },
) => {
  state.clients = clients;
  state.loading = false;
  if (clearClient) {
    state.client = null;
  }
};

const handleGetClient = (
  state,
  { payload: { data: client = [] } = {} },
) => {
  state.client = client;
  state.loading = false;
};

const handleReset = (state) => {
  state.clients = [];
  state.client = null;
  state.loading = false;
};

export default createReducer(initialState, {
  [GET_CLIENTS]: handleLoading,
  [GET_CLIENT]: handleLoading,
  [success(GET_CLIENTS)]: handleGetClients,
  [success(GET_CLIENT)]: handleGetClient,
  [fail(GET_CLIENTS)]: handleReset,
  [fail(GET_CLIENT)]: handleReset,
});
