import React, { forwardRef } from 'react';
import find from 'lodash/find';
import capitalize from 'lodash/capitalize';
import { useTranslation } from 'react-i18next';
import Dropdown from 'react-bootstrap/Dropdown';
import { debug } from 'helpers/env';
import gearIcon from 'assets/images/gear_icon.svg';
import frFlag from 'assets/images/fr.svg';
import enFlag from 'assets/images/gb.svg';
import upArrow from 'assets/images/up-arrow.svg';
import downArrow from 'assets/images/down-arrow.svg';
import 'assets/style/language-selector.scss';

// Fetched flags from https://github.com/ekwonye-richard/react-flags-select/tree/master/flags

const DEV_COUNTRIES = [
  {
    lang: 'dev',
    icon: gearIcon,
  },
];

const COUNTRIES = [
  {
    lang: 'en',
    icon: enFlag,
  },
  {
    lang: 'fr',
    icon: frFlag,
  },
];

const CustomToggle = forwardRef(
  ({
    children, onClick, onKeyPress, up,
  }, ref) => (
    <div
      ref={ref}
      onClick={onClick}
      onKeyPress={onKeyPress}
      role="button"
      tabIndex={0}
      className="d-flex align-items-center"
    >
      {children}
      {up ? (
        <img
          className="language-selector__arrow"
          src={upArrow}
          alt="expand language selection"
        />
      ) : (
        <img
          className="language-selector__arrow"
          src={downArrow}
          alt="collapse language selection"
        />
      )}
    </div>
  ),
);

const LanguageSelector = ({ up = false, small = false, align = 'left' }) => {
  const { i18n } = useTranslation();
  const currentLang = i18n.language?.replace(/^([a-z]+)-?.*/, '$1') || 'en';

  const countriesToDisplay = [...COUNTRIES];

  if (debug) {
    countriesToDisplay.push(...DEV_COUNTRIES);
  }

  const selected = find(countriesToDisplay, ({ lang }) => lang === currentLang)
    || COUNTRIES[0];

  return (
    <Dropdown
      className={`language-selector ${small && 'small'}`}
      drop={up ? 'up' : 'down'}
    >
      <Dropdown.Toggle id="language-selector-toggle" as={CustomToggle} up={up}>
        <img src={selected.icon} alt="" className="language-selector__flag" />
        <span className="language-selector__label">
          {capitalize(selected.lang)}
        </span>
      </Dropdown.Toggle>

      <Dropdown.Menu align={align}>
        {countriesToDisplay.map(({ lang, icon }) => (
          <Dropdown.Item
            key={lang}
            className="d-flex align-items-center"
            onClick={() => {
              i18n.changeLanguage(lang);
            }}
            onKeyPress={() => {
              i18n.changeLanguage(lang);
            }}
          >
            <img src={icon} alt="" className="language-selector__flag" />
            <span className="language-selector__label">
              {capitalize(lang)}
            </span>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LanguageSelector;
