import React, {
  useEffect,
  useState,
} from 'react';
import pickBy from 'lodash/pickBy';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { isApi } from 'helpers/env';
import DataList from 'components/DataList';
import Button from 'components/Button';
import Pagination from 'components/Pagination';
import DataListLimitSelector from 'components/DataListLimitSelector';
import QueryForm from 'components/QueryForm';
import ExportAdminsModal from 'components/ExportAdminsModal';
import CreateAdminModal from 'components/CreateAdminModal';
import {
  getAdmins,
  createAdmin,
  exportAdmins,
} from 'logic/actions/adminsActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

const AdminsList = ({
  dataListLimit,
  isTripartie,
  isAdmin,
  pages,
  nbPages,
  getAdmins = null,
  createAdmin = null,
  exportAdmins = null,
  location,
  history,
}) => {
  useEffect(() => {
    if (!isAdmin) {
      history.push('/');
    }
  }, [isAdmin, history]);

  const { t } = useTranslation([
    'adminsList',
    '_admins',
  ]);

  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searching, setSearching] = useState(false);
  const [isExportAdminsModalVisible, setExportAdminsModalVisible] = useState(false);
  const [isCreateAdminModalVisible, setCreateAdminModalVisible] = useState(false);

  const [query, setQuery] = useState(queryString.parse(location.search));
  const { search } = query;

  const columns = [
    {
      name: t('columns.firstName'),
      extractor: ({ userProfile: { firstName } = {} }) => (firstName),
    },
    {
      name: t('columns.lastName'),
      extractor: ({ userProfile: { lastName } = {} }) => (lastName),
    },
    {
      name: t('columns.email'),
      extractor: ({ email }) => (email),
    },
    {
      name: t('columns.role'),
      extractor: ({ role }) => (t(`_admins:role.${role.toLowerCase()}`)),
    },
    {
      name: t('columns.locked'),
      extractor: ({ locked }) => (t(`_admins:locked.${locked}`)),
    },
  ];

  if (!isApi && isTripartie) {
    columns.push({
      name: t('columns.client'),
      extractor: ({ client: { name } = {} }) => (name),
    });
  }

  useEffect(() => {
    setLoading(true);
    history.push(`?${queryString.stringify(pickBy(query))}`);
    getAdmins({
      page: currentPage,
      limit: dataListLimit,
      ...query,
    })
      .finally(() => {
        setLoading(false);
        setSearching(false);
      });
  }, [
    currentPage,
    dataListLimit,
    query,
    getAdmins,
    history,
  ]);

  return (
    <div>
      <QueryForm
        search={search}
        onSubmit={(search) => { setQuery((prev) => ({ ...prev, search })); }}
        isSubmitting={searching}
        extraButtonLabel={t('export')}
        extraButtonOnClick={() => { setExportAdminsModalVisible(true); }}
      />

      <DataList
        loading={loading}
        columns={columns}
        data={pages[currentPage]}
        to={({ id }) => (`/admins/${id}`)}
        emptyMessage={search ? t('noResults') : t('noItems')}
      />
      <Row>
        <Col md={{ span: 4, offset: 4 }} className="d-flex justify-content-center align-items-center">
          <Pagination
            currentPage={currentPage}
            nbPages={nbPages}
            onClick={setCurrentPage}
          />
        </Col>
        <Col className="d-flex w-100 justify-content-end align-items-center">
          {isAdmin && (
            <Button
              size="sm"
              className="mr-2"
              onClick={() => { setCreateAdminModalVisible(true); }}
            >
              {t('createAdmin')}
            </Button>
          )}
          <DataListLimitSelector />
        </Col>
      </Row>
      <ExportAdminsModal
        show={isExportAdminsModalVisible}
        onHide={() => { setExportAdminsModalVisible(false); }}
        onSubmit={(columns) => exportAdmins(query, columns)
          .then(() => {
            setExportAdminsModalVisible(false);
          })}
      />
      <CreateAdminModal
        show={isCreateAdminModalVisible}
        onHide={() => { setCreateAdminModalVisible(false); }}
        onSubmit={(values) => createAdmin(values)
          .then(() => {
            getAdmins({
              page: currentPage,
              limit: dataListLimit,
              ...query,
            })
              .finally(() => {
                setCreateAdminModalVisible(false);
              });
          })}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isTripartie: state.persistent.meReducer.isTripartie,
  isAdmin: state.persistent.meReducer.isAdmin,
  pages: state.persistent.adminsReducer.pages,
  nbPages: state.persistent.adminsReducer.nbPages,
  dataListLimit: state.persistent.settingsReducer.dataListLimit,
});

const mapDispatchToProps = (dispatch) => ({
  getAdmins: bindActionCreators(getAdmins, dispatch),
  createAdmin: bindActionCreators(createAdmin, dispatch),
  exportAdmins: bindActionCreators(exportAdmins, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminsList);
