import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { useLoadingScreenContext } from 'helpers/LoadingScreenContext';

const LoadingScreen = () => {
  const isLoadingScreenOn = useLoadingScreenContext();
  return isLoadingScreenOn ? (
    <div className="loading-overlay text-primary">
      <Spinner className="" as="span" animation="border" />
    </div>
  ) : null;
};

export default LoadingScreen;
