import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import {
  tomorrow,
  oneWeekAgo,
  currentMonth,
  oneMonthAgo,
  currentQuarter,
  currentYear,
} from 'helpers/dates';

const CHOICES = {
  oneWeekAgo: {
    dateFrom: oneWeekAgo, dateTo: tomorrow,
  },
  currentMonth: {
    dateFrom: currentMonth, dateTo: tomorrow,
  },
  oneMonthAgo: {
    dateFrom: oneMonthAgo, dateTo: tomorrow,
  },
  currentQuarter: {
    dateFrom: currentQuarter, dateTo: tomorrow,
  },
  currentYear: {
    dateFrom: currentYear, dateTo: tomorrow, groupBy: 'month',
  },
};
const DEFAULT = 'oneWeekAgo';

const DateIntervalSelector = ({
  onSelect = () => {},
  ...props
}) => {
  const [selectedKey, setSelectedKey] = useState(DEFAULT);
  const { t } = useTranslation('dateIntervalSelector');

  return (
    <DropdownButton
      onSelect={(key) => {
        setSelectedKey(key);
        onSelect(CHOICES[key]);
      }}
      title={t(selectedKey)}
      style={{ margin: '10px 0' }}
      {...props}
    >
      {Object.keys(CHOICES).map((key) => (
        <Dropdown.Item eventKey={key} key={key}>{t(key)}</Dropdown.Item>
      ))}
    </DropdownButton>
  );
};

export default DateIntervalSelector;
