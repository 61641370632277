import authReducer from 'logic/reducers/authReducer';
import countriesReducer from 'logic/reducers/countriesReducer';
import currenciesReducer from 'logic/reducers/currenciesReducer';
import clientsReducer from 'logic/reducers/clientsReducer';
import meReducer from 'logic/reducers/meReducer';
import statsReducer from 'logic/reducers/statsReducer';
import usersReducer from 'logic/reducers/usersReducer';
import transactionsReducer from 'logic/reducers/transactionsReducer';
import templatesReducer from 'logic/reducers/templatesReducer';
import adminsReducer from 'logic/reducers/adminsReducer';
import settingsReducer from 'logic/reducers/settingsReducer';
import disputesReducer from 'logic/reducers/disputesReducer';

export const persistentReducers = {
  countriesReducer,
  currenciesReducer,
  clientsReducer,
  meReducer,
  statsReducer,
  usersReducer,
  transactionsReducer,
  templatesReducer,
  adminsReducer,
  settingsReducer,
  disputesReducer,
};

export const volatileReducers = {
  authReducer,
};
