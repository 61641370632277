import * as React from 'react';

const SvgTabClients = ({
  color = '#aab6be',
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 52.24 45.5"
    stroke={color}
    {...props}
  >
    <path
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5px"
      fillRule="evenodd"
      d="M45.18 23.55a6.32 6.32 0 01-6.31-6.11 6.34 6.34 0 01-6.33 6.11 6.34 6.34 0 01-6.33-6.33v-.32.54a6.36 6.36 0 01-6.34 6.11 6.34 6.34 0 01-6.33-6A6.2 6.2 0 0112 21.33a6.39 6.39 0 01-4.86 2.22h-.65a6.46 6.46 0 01-2.24-.65v18.63a3.23 3.23 0 003.22 3.22h37.47a3.23 3.23 0 003.22-3.22V22.79a6 6 0 01-.57.28 6.28 6.28 0 01-2.41.48z"
    />
    <path
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5px"
      fillRule="evenodd"
      d="M13.54 16.9L15.63.75H7.48a3.23 3.23 0 00-3.12 2.42l-2.82 11-.78 2.73v.32a6.23 6.23 0 00.77 3 6.42 6.42 0 005 3.29h.65A6.39 6.39 0 0012 21.33a6.21 6.21 0 001.54-3.89 1.7 1.7 0 010-.22 1.77 1.77 0 010-.23zM38.87 17v-.1l-.17-1.27L36.73.75H26.21v16.47a6.34 6.34 0 006.33 6.33 6.34 6.34 0 006.33-6.11 1.7 1.7 0 010-.22 1.77 1.77 0 010-.22z"
    />
    <path
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5px"
      fillRule="evenodd"
      d="M15.63.75L13.52 16.9v.32a6.34 6.34 0 006.34 6.33 6.36 6.36 0 006.34-6.11V.75zM51.49 17v-.1l-.67-2.74L48 3.17A3.23 3.23 0 0044.88.75h-8.15l2.11 16.15v.32a6.32 6.32 0 0012.64.22V17z"
    />
  </svg>
);

export default SvgTabClients;
