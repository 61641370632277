import React from 'react';
import mapValues from 'lodash/mapValues';
import invert from 'lodash/invert';
import isEmpty from 'lodash/isEmpty';
import { Formik } from 'formik';
import * as yup from 'yup';
import Form from 'react-bootstrap/Form';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import { transactionsStates } from 'helpers/env';

const FilterTransactionsStatesModal = ({
  show,
  onHide,
  onSubmit,
  initialValues,
}) => {
  const { t } = useTranslation([
    'filterTransactionsStatesModal',
    '_transactions',
  ]);

  return (
    <Formik
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={yup.object(mapValues(invert(transactionsStates), () => (yup.boolean())))}
      onSubmit={onSubmit}
      initialValues={isEmpty(initialValues)
        ? mapValues(invert(transactionsStates), () => (true))
        : {
          ...mapValues(invert(transactionsStates), () => (false)),
          ...initialValues,
        }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        isSubmitting,
        resetForm,
      }) => (
        <Modal show={show} onHide={onHide} centered>
          <Form noValidate onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>{t('modalTitle')}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <div className="d-flex flex-row justify-content-around">
                <div>
                  {transactionsStates.slice(0, Math.ceil(transactionsStates.length / 2)).map((key) => (
                    <Form.Group key={key}>
                      <Form.Check type="checkbox" id={key}>
                        <Form.Check.Input
                          type="checkbox"
                          name={key}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched[key] && !!errors[key]}
                          checked={values[key]}
                        />
                        <Form.Check.Label>
                          {t(`_transactions:status.${key}`)}
                        </Form.Check.Label>
                      </Form.Check>
                    </Form.Group>
                  ))}
                </div>
                <div>
                  {transactionsStates.slice(Math.ceil(transactionsStates.length / 2)).map((key) => (
                    <Form.Group key={key}>
                      <Form.Check type="checkbox" id={key}>
                        <Form.Check.Input
                          type="checkbox"
                          name={key}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched[key] && !!errors[key]}
                          checked={values[key]}
                        />
                        <Form.Check.Label>
                          {t(`_transactions:status.${key}`)}
                        </Form.Check.Label>
                      </Form.Check>
                    </Form.Group>
                  ))}
                </div>
              </div>
              <div className="d-flex flex-row justify-content-center">
                <Button
                  variant="link"
                  className="shadow-none"
                  onClick={() => {
                    resetForm({ values: mapValues(invert(transactionsStates), () => (true)) });
                  }}
                >
                  {t('checkAll')}
                </Button>
                <Button
                  variant="link"
                  className="shadow-none"
                  onClick={() => {
                    resetForm({ values: mapValues(invert(transactionsStates), () => (false)) });
                  }}
                >
                  {t('uncheckAll')}
                </Button>
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="outline-secondary" onClick={onHide}>{t('close')}</Button>
              <Button
                type="submit"
                isLoading={isSubmitting}
              >
                {t('ok')}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default FilterTransactionsStatesModal;
