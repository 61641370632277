import React, {
  useEffect,
  useState,
} from 'react';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import mapValues from 'lodash/mapValues';
import invert from 'lodash/invert';
import omitBy from 'lodash/omitBy';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import { FormattedNumber, FormattedDate } from 'react-intl';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { isApi } from 'helpers/env';
import DataList from 'components/DataList';
import Pagination from 'components/Pagination';
import DataListLimitSelector from 'components/DataListLimitSelector';
import QueryForm from 'components/QueryForm';
import ExportTransactionsModal from 'components/ExportTransactionsModal';
import FilterTransactionsStatesModal from 'components/FilterTransactionsStatesModal';
import FilterClientsModal from 'components/FilterClientsModal';
import {
  getTransactions,
  exportTransactions,
} from 'logic/actions/transactionsActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

const TransactionsList = ({
  dataListLimit,
  isTripartie,
  pages,
  nbPages,
  getTransactions = null,
  exportTransactions = null,
  location,
  history,
  canSeeTransactionsTab,
}) => {
  useEffect(() => {
    if (!canSeeTransactionsTab) {
      history.push('/');
    }
  }, [canSeeTransactionsTab, history]);

  const { t } = useTranslation(['transactionsList', '_transactions']);

  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searching, setSearching] = useState(false);
  const [isExportTransactionsModalVisible, setExportTransactionsModalVisible] = useState(false);
  const [isFilterTransactionsStatesModalVisible, setFilterTransactionsStatesModalVisible] = useState(false);
  const [isFilterClientsModalVisible, setFilterClientsModalVisible] = useState(false);

  const [query, setQuery] = useState(queryString.parse(location.search, { arrayFormat: 'bracket' }));
  const {
    search,
    lastEvents,
    clientSlugs,
  } = query;

  const userExtractor = ({
    isPreregistration,
    email,
    userProfile: {
      firstName,
      lastName,
    } = {},
  }) => (isPreregistration ? email : `${firstName} ${lastName}`);

  const columns = [
    {
      name: t('columns.id'),
      extractor: ({ id }) => (id),
    },
    {
      name: t('columns.title'),
      extractor: ({ title }) => (title),
    },
    {
      name: t('columns.buyer'),
      extractor: ({ buyer }) => (userExtractor(buyer)),
    },
    {
      name: t('columns.seller'),
      extractor: ({ seller }) => (userExtractor(seller)),
    },
    {
      name: t('columns.subTotal'),
      extractor: ({ subTotal, currency = 'EUR' }) => (<FormattedNumber value={subTotal / 100} style="currency" currency={currency} />),
    },
    {
      name: t('columns.fees'),
      extractor: ({ totalFees, currency = 'EUR' }) => (<FormattedNumber value={totalFees / 100} style="currency" currency={currency} />),
    },
    {
      name: t('columns.creationDate'),
      extractor: ({ createdAt }) => (createdAt && <FormattedDate value={new Date(createdAt)} />),
      className: 'align-right',
    },
    {
      name: t('columns.state'),
      extractor: ({
        lastEventSlug,
        dispute: { status: disputeStatus } = {},
      }) => (
        disputeStatus
          ? t(`_transactions:disputeStatus.${disputeStatus}`)
          : t(`_transactions:status.${lastEventSlug}`)
      ),
      canBeFiltered: true,
      isFiltered: !isEmpty(lastEvents),
      onClickFilterButton: () => { setFilterTransactionsStatesModalVisible(true); },
    },
  ];

  if (!isApi && isTripartie) {
    columns.push({
      name: t('columns.client'),
      extractor: ({ client }) => (client),
      weight: 2,
      canBeFiltered: true,
      isFiltered: !isEmpty(clientSlugs),
      onClickFilterButton: () => { setFilterClientsModalVisible(true); },
    });
  }

  useEffect(() => {
    setLoading(true);
    history.push(`?${queryString.stringify(pickBy(query), { arrayFormat: 'bracket' })}`);
    getTransactions({
      page: currentPage,
      limit: dataListLimit,
      ...query,
    })
      .finally(() => {
        setLoading(false);
        setSearching(false);
      });
  }, [currentPage, dataListLimit, query, getTransactions, history]);

  return (
    <div className="mt-3">
      <QueryForm
        search={search}
        onSubmit={(search) => { setQuery((prev) => ({ ...prev, search })); }}
        isSubmitting={searching}
        extraButtonLabel={t('export')}
        extraButtonOnClick={() => { setExportTransactionsModalVisible(true); }}
      />

      <DataList
        loading={loading}
        columns={columns}
        data={pages[currentPage]}
        to={({ id }) => (`/transactions/${id}`)}
        emptyMessage={search ? t('noResults') : t('noItems')}
      />
      <Row>
        <Col md={{ span: 4, offset: 4 }} className="d-flex justify-content-center align-items-center">
          <Pagination
            currentPage={currentPage}
            nbPages={nbPages}
            onClick={setCurrentPage}
          />
        </Col>
        <Col className="d-flex w-100 justify-content-end align-items-center">
          <DataListLimitSelector />
        </Col>
      </Row>
      <ExportTransactionsModal
        show={isExportTransactionsModalVisible}
        onHide={() => { setExportTransactionsModalVisible(false); }}
        onSubmit={(columns) => exportTransactions(query, columns)
          .finally(() => {
            setExportTransactionsModalVisible(false);
          })}
      />
      <FilterTransactionsStatesModal
        show={isFilterTransactionsStatesModalVisible}
        onHide={() => { setFilterTransactionsStatesModalVisible(false); }}
        initialValues={mapValues(invert(lastEvents), () => (true))}
        onSubmit={(states) => {
          setQuery((prev) => ({ ...prev, lastEvents: Object.keys(omitBy(states, (col) => (!col))) }));
          setFilterTransactionsStatesModalVisible(false);
          return Promise.resolve();
        }}
      />
      <FilterClientsModal
        show={isFilterClientsModalVisible}
        onHide={() => { setFilterClientsModalVisible(false); }}
        initialValues={mapValues(invert(clientSlugs), () => (true))}
        onSubmit={(clientSlugs) => {
          setQuery((prev) => ({ ...prev, clients: Object.keys(omitBy(clientSlugs, (col) => (!col))) }));
          setFilterClientsModalVisible(false);
          return Promise.resolve();
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isTripartie: state.persistent.meReducer.isTripartie,
  canSeeTransactionsTab: state.persistent.meReducer.canSeeTransactionsTab,
  pages: state.persistent.transactionsReducer.pages,
  nbPages: state.persistent.transactionsReducer.nbPages,
  dataListLimit: state.persistent.settingsReducer.dataListLimit,
});

const mapDispatchToProps = (dispatch) => ({
  getTransactions: bindActionCreators(getTransactions, dispatch),
  exportTransactions: bindActionCreators(exportTransactions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TransactionsList);
