import { createReducer } from '@reduxjs/toolkit';
import { GET_CURRENCIES } from 'logic/actions/currenciesActions';
import {
  success,
} from 'logic/reducers/helpers';

const initialState = { currencies: [] };

const handleGetCurrenciess = (
  state,
  { payload: { data: currencies = [] } = {} },
) => {
  state.currencies = currencies;
};

export default createReducer(initialState, { [success(GET_CURRENCIES)]: handleGetCurrenciess });
